/* Updated Repayment Strategy Logic */
/* The code below integrates the iterative repayment logic, correct interest calculations, and date handling into the original implementation */

import React, { useState, useEffect, useRef } from 'react';
import '../css/CommonLayout.css';
import '../css/RepayDebts.css';
import axios from 'axios';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

const loanTypes = ["Car Loan", "Personal Loan", "Credit Card", "Home Loan", "Gold Loan", "Education Loan", "Custom"];


const generateRepaymentStrategy = async (relevantData, prompt) => {
  try {
    console.log('Calling OpenAI API with data:', { relevantData, prompt });
    const response = await axios.post('https://api.openai.com/v1/chat/completions', {
      model: "gpt-4o",
      messages: [
        { role: "system", content: "You are a helpful financial advisor specializing in debt repayment strategies." },
        { role: "user", content: prompt }
      ],
      temperature: 0.7,
      max_tokens: 1000
    }, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json'
      }
    });

    console.log('OpenAI API response:', response.data);
    if (response.data && response.data.choices && response.data.choices[0].message.content) {
      return response.data.choices[0].message.content;
    } else {
      console.error('Unexpected response format:', response.data);
      throw new Error('Unexpected response format from OpenAI API');
    } 
  } catch (error) {
    console.error('Error calling OpenAI API:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

const generateOverview = async (relevantData) => {
  const strategyName = relevantData.repaymentStrategy === 'time' 
    ? "Debt Avalanche Repayment Method" 
    : "Debt Snowball Repayment Method";

  const overviewPrompt = `Based on the loan details provided, generate a brief 2-3 sentence explanation of how the debt repayment strategy selected by the user - ${strategyName} will help the user without mentioning any loans from the list. Keep the tone positive and encouraging and give the output in bullet points for easy reading.

Loan Details:
${JSON.stringify(relevantData.loans.map(loan => ({
  loanType: loan.loanType,
  monthlyEMI: loan.monthlyEMI,
  outstandingAmount: loan.amountOutstanding,
  repaymentDate: loan.repaymentDate,
  interestRate: loan.interestRate
})), null, 2)}
Extra monthly payment: ₹${relevantData.extraPayment}`;

  return await generateRepaymentStrategy(relevantData, overviewPrompt);
};

const generateFirstTarget = async (relevantData) => {
  const strategyName = relevantData.repaymentStrategy === 'time' 
    ? "Debt Avalanche Repayment Method" 
    : "Debt Snowball Repayment Method";

  // Sort and store ordered loans based on strategy
  const orderedLoans = [...relevantData.loans].sort((a, b) => {
    if (relevantData.repaymentStrategy === 'time') {
      // Debt Avalanche: Sort by interest rate in descending order
      return parseFloat(b.interestRate) - parseFloat(a.interestRate);
    } else {
      // Debt Snowball: Sort by outstanding amount in ascending order
      return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
    }
  });

  const firstLoan = orderedLoans[0];
  const extraPaymentText = parseFloat(relevantData.extraPayment) > 0 
    ? ` and the extra payment of ₹${relevantData.extraPayment} will be applied to this loan first` 
    : '';

  // Separate the explanation and the list
  const explanation = `Based on the ${strategyName}, we will tackle the ${firstLoan.loanType} first as it has the ${
    relevantData.repaymentStrategy === 'time' 
      ? `highest interest rate of ${firstLoan.interestRate}%` 
      : `lowest outstanding amount of ₹${firstLoan.amountOutstanding}`
  }${extraPaymentText}.`;

  const loanList = orderedLoans.map((loan, index) => 
    `${index + 1}. ${loan.loanType} (${
      relevantData.repaymentStrategy === 'time'
        ? `Interest Rate: ${loan.interestRate}%`
        : `Outstanding Amount: ₹${loan.amountOutstanding}`
    })`
  ).join('\n');

  const targetText = `${explanation}\n\nLoans under this strategy will be tackled in the following order:\n${loanList}`;

  return targetText;
};

const generateFirstLoanDate = async (relevantData) => {
  // Get the first loan based on strategy
  const orderedLoans = [...relevantData.loans].sort((a, b) => {
    if (relevantData.repaymentStrategy === 'time') {
      return parseFloat(b.interestRate) - parseFloat(a.interestRate);
    } else {
      return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
    }
  });

  const firstLoan = orderedLoans[0];
  const totalMonthlyPayment = parseFloat(firstLoan.monthlyEMI) + parseFloat(relevantData.extraPayment || 0);
  const negativeOutstanding = parseFloat(firstLoan.amountOutstanding) * -1;


};

// Update the renderBulletPoints function to handle numbered lists
const renderBulletPoints = (text) => {
  const hasNumberedList = text.match(/\d+\./);
  const hasBulletPoints = text.includes('\n-') || text.includes('\n•');
  
  if (hasNumberedList) {
    return (
      <ol style={{ paddingLeft: '20px', marginTop: '10px' }}>
        {text.split('\n').map((line, index) => {
          if (line.match(/^\d+\./)) {
            return <li key={index}>{line.replace(/^\d+\./, '').trim()}</li>;
          }
          return line.trim() ? <p key={index}>{line}</p> : null;
        })}
      </ol>
    );
  } else if (hasBulletPoints) {
    return (
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        {text.split('\n').map((line, index) => {
          if (line.trim().startsWith('-') || line.trim().startsWith('•')) {
            return <li key={index}>{line.replace(/^[-•]/, '').trim()}</li>;
          }
          return line.trim() ? <p key={index}>{line}</p> : null;
        })}
      </ul>
    );
  }
  return <p>{text}</p>;
};

// 1. Basic helper functions first
const calculateRemainingMonths = (repaymentDate) => {
    const today = new Date();
    const endDate = new Date(repaymentDate);
    return Math.ceil((endDate - today) / (1000 * 60 * 60 * 24 * 30.44));
};

// 2. Loan calculation helper
const calculateLoanRepayment = (loan, extraAmount, currentMonth) => {
    const updatedEMI = loan.emi + extraAmount;
    const monthsToClose = Math.ceil(
        Math.log(updatedEMI / (updatedEMI - loan.rate * loan.principal)) /
        Math.log(1 + loan.rate)
    );

    return {
        monthsToClose,
        updatedEMI,
        closingMonth: currentMonth + monthsToClose
    };
};

// 3. Data preparation functions
const formatLoansForCalculation = (loans, orderedLoans) => {
    return orderedLoans.map((orderedLoan) => {
        const loan = loans.find((l) => l.loanType === orderedLoan.loanType);
        return {
            name: loan.loanType,
            principal: parseFloat(loan.amountOutstanding),
            rate: parseFloat(loan.interestRate) / 12 / 100, // Convert annual to monthly rate
            emi: parseFloat(loan.monthlyEMI),
            tenure: calculateRemainingMonths(loan.repaymentDate),
            priority: orderedLoans.findIndex((l) => l.loanType === loan.loanType) + 1,
        };
    });
};

// Add this function before calculateDynamicLoanRepayment
const calculateOutstandingAtDate = (loan, targetDate) => {
    try {
        const monthsElapsed = Math.ceil(
            (new Date(targetDate) - new Date()) / (1000 * 60 * 60 * 24 * 30.44)
        );

        const monthlyRate = parseFloat(loan.interestRate) / 12 / 100;
        const tenure = parseInt(loan.tenurePending);
        const principal = parseFloat(loan.amountOutstanding);

        const outstandingBalance = principal *
            (((1 + monthlyRate) ** tenure - (1 + monthlyRate) ** monthsElapsed) /
            ((1 + monthlyRate) ** tenure - 1));

        return Math.max(0, outstandingBalance);
    } catch (error) {
        console.error('Error calculating outstanding at date:', error);
        return loan.amountOutstanding;
    }
};

const calculateDynamicLoanRepayment = (loans, extraEMI, repaymentStrategy) => {
    if (!loans || !loans.length) return [];

    try {
        const sortedLoans = [...loans].sort((a, b) => {
            if (repaymentStrategy === "time") {
                return parseFloat(b.interestRate) - parseFloat(a.interestRate);
            }
            return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
        });

        const results = [];
        let currentDate = new Date();
        let accumulatedEMI = parseFloat(extraEMI) || 0;
        let globalStartDate = new Date();
        
        // Add a map to track loan closure months
        const loanClosures = new Map();
        
        // Track closed loan EMIs that need to be redistributed
        let closedLoanEMIs = 0;
        
        for (let i = 0; i < sortedLoans.length; i++) {
            const loan = sortedLoans[i];
            if (!loan) continue;

            console.group(`\n=== Detailed Calculations for ${loan.loanType} ===`);

            let outstandingBalance = parseFloat(loan.amountOutstanding);
            const monthlyRate = parseFloat(loan.interestRate) / 12 / 100;
            let emi = parseFloat(loan.monthlyEMI);
            let totalInterestPaid = 0;
            let monthsToClose = 0;
            let monthlyCalculations = [];

            while (outstandingBalance > 0) {
                monthsToClose++;
                
                // Calculate current monthly payment including any closed loan EMIs
                let currentMonthlyPayment = parseFloat(loan.monthlyEMI);
                let extraPaymentForMonth = 0;
                
                // Common logic for both strategies
                // First loan in sorted order gets extra EMI
                if (i === 0) {
                    extraPaymentForMonth = parseFloat(extraEMI) || 0;
                    currentMonthlyPayment += extraPaymentForMonth;
                }

                // EMI cascading logic for subsequent loans
                if (i > 0) {
                    const precedingLoan = sortedLoans[i - 1];
                    const precedingLoanClosureMonth = loanClosures.get(precedingLoan.loanType);
                    
                    if (precedingLoanClosureMonth && monthsToClose > precedingLoanClosureMonth) {
                        // Get all closed loans' EMIs
                        const closedLoans = sortedLoans.slice(0, i)
                            .filter(loan => {
                                const closureMonth = loanClosures.get(loan.loanType);
                                return closureMonth && monthsToClose > closureMonth;
                            });
                            
                        // Sum up all closed loans' EMIs
                        let cascadingAmount = closedLoans.reduce((sum, loan) => 
                            sum + parseFloat(loan.monthlyEMI), 0);
                            
                        // Add the extra payment (which was going to the first loan)
                        cascadingAmount += parseFloat(extraEMI) || 0;
                        extraPaymentForMonth = cascadingAmount;    
                        currentMonthlyPayment += cascadingAmount;
                    }
                }

                const interest = outstandingBalance * monthlyRate;
                totalInterestPaid += interest;

                // Calculate principal repaid
                let principalPaid = currentMonthlyPayment - interest;

                // Store the monthly calculation
                monthlyCalculations.push({
                    monthNumber: monthsToClose,
                    interest: interest,
                    principalPaid: principalPaid,
                    monthlyPayment: currentMonthlyPayment,
                    outstandingBalanceBefore: outstandingBalance,
                    outstandingBalanceAfter: Math.max(0, outstandingBalance - principalPaid),
                    extraPayment: extraPaymentForMonth
                });

                // Adjust for final payment
                if (principalPaid > outstandingBalance) {
                    principalPaid = outstandingBalance;
                    outstandingBalance = 0;
                    // Update the last monthly calculation
                    const lastMonth = monthlyCalculations[monthlyCalculations.length - 1];
                    lastMonth.principalPaid = principalPaid;
                    lastMonth.monthlyPayment = principalPaid + interest;
                    lastMonth.outstandingBalanceAfter = 0;
                } else {
                    outstandingBalance -= principalPaid;
                }

                console.log(`\nMonth ${monthsToClose}:`);
                console.log('Interest:', `₹${interest.toFixed(2)}`);
                console.log('Principal Paid:', `₹${principalPaid.toFixed(2)}`);
                console.log('Monthly Payment:', `₹${currentMonthlyPayment.toFixed(2)}`);
                console.log('Outstanding Balance Before:', `₹${(outstandingBalance + principalPaid).toFixed(2)}`);
                console.log('Outstanding Balance After:', `₹${outstandingBalance.toFixed(2)}`);
            }

            // Store closure month for this loan
            loanClosures.set(loan.loanType, monthsToClose);

            // Calculate closure date from the global start date
            const closureDate = new Date(globalStartDate);
            closureDate.setMonth(globalStartDate.getMonth() + monthsToClose);

            console.log('\nClosure Date:', 
                closureDate.toLocaleDateString("en-IN", { year: "numeric", month: "short" }));

            // Calculate interest savings
            const originalInterest = parseFloat(loan.monthlyEMI) * parseInt(loan.tenurePending) - parseFloat(loan.amountOutstanding);
            const interestSaved = originalInterest - totalInterestPaid;

            // Calculate time saved
            const originalTenure = parseInt(loan.tenurePending);
            const timeSaved = originalTenure - monthsToClose;

            console.log('\nSummary:');
            console.log('Final Closure Date:', 
                closureDate.toLocaleDateString("en-IN", { year: "numeric", month: "short" }));
            console.log('New Interest:', `₹${Math.round(totalInterestPaid).toLocaleString()}`);
            console.log('Time Saved:', `${timeSaved} months`);
            console.log('Interest Saved:', `₹${Math.round(interestSaved).toLocaleString()}`);

            console.groupEnd();

            // Store results with loanType instead of loanName
            results.push({
                loanType: loan.loanType,
                originalEMI: parseFloat(loan.monthlyEMI),
                totalEMIUsed: emi,
                outstandingAtStart: Math.round(parseFloat(loan.amountOutstanding)),
                monthsToClose,
                newClosureDate: closureDate.toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "short"
                }),
                finalClosureDate: closureDate.toLocaleDateString("en-IN", {
                    year: "numeric",
                    month: "short"
                }),
                newInterest: Math.round(totalInterestPaid),
                timeSaved: Math.max(0, timeSaved),
                interestSaved: Math.round(interestSaved),
                monthlyCalculations
            });

            // After loan closes, add its EMI to the pool for redistribution
            if (i < sortedLoans.length - 1) { // Don't add for the last loan
                closedLoanEMIs += parseFloat(loan.monthlyEMI);
                console.log(`Added ${loan.loanType}'s EMI (₹${loan.monthlyEMI}) to closed loan EMIs pool. Total: ₹${closedLoanEMIs}`);
            }
        }

        return results;

    } catch (error) {
        console.error('Error in calculateDynamicLoanRepayment:', error);
        return [];
    }
};






// 5. Schedule calculation wrapper
const calculateRepaymentSchedule = (relevantData) => {
    // Sort loans based on strategy
    const orderedLoans = [...relevantData.loans].sort((a, b) => {
        if (relevantData.repaymentStrategy === "time") {
            // Debt Avalanche: Sort by interest rate (descending)
            return parseFloat(b.interestRate) - parseFloat(a.interestRate);
        } else {
            // Debt Snowball: Sort by outstanding amount (ascending)
            return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
        }
    });

    // Format loans for calculation
    const formattedLoans = formatLoansForCalculation(relevantData.loans, orderedLoans);

    // Calculate repayment details
    const repaymentDetails = calculateDynamicLoanRepayment(
        formattedLoans,
        parseFloat(relevantData.extraPayment),
        relevantData.repaymentStrategy
    );

    // Format dates and additional information for display
    return repaymentDetails.map((result) => {
        const closureDate = new Date();
        closureDate.setMonth(closureDate.getMonth() + result.closeMonth);

        const originalMonths = calculateRemainingMonths(
            relevantData.loans.find((l) => l.loanType === result.loanName).repaymentDate
        );

        return {
            ...result,
            closureDate: closureDate.toLocaleDateString("en-IN", {
                year: "numeric",
                month: "long",
            }),
            monthsSaved: originalMonths - result.closeMonth,
        };
    });
};

/* Example usage:
const relevantData = {
    loans: [
        {
            loanType: "Personal Loan",
            monthlyEMI: 33758,
            amountOutstanding: 898779,
            repaymentDate: "2027-06-30",
            interestRate: 11.25
        },
        {
            loanType: "Gold Loan",
            monthlyEMI: 14918,
            amountOutstanding: 311360.47,
            repaymentDate: "2026-10-31",
            interestRate: 9.90
        }
    ],
    extraPayment: 20
};

const repaymentSchedule = calculateRepaymentSchedule(relevantData);
console.log(repaymentSchedule);
*/

const RepayDebts = ({ onBudgetDataChange, onBudgetDataBatchChange, onSave, monthYear, nextMonthYear }) => {
  const [loans, setLoans] = useState([{ loanType: '', amount: '', interestRate: '', tenure: '', emi: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [analysis, setAnalysis] = useState(() => {
    try {
      // First try to load from next month
      const nextMonthData = getFromLocalStorage('repayDebts', null, globalNextMonthYear);
      if (nextMonthData?.loanAnalysis) {
        return nextMonthData.loanAnalysis;
      }
      
      // Fall back to current month if not found
      const currentMonthData = getFromLocalStorage('repayDebts', null, globalMonthYear);
      return currentMonthData?.loanAnalysis || null;
    } catch (error) {
      console.error('Error loading loan analysis:', error);
      return null;
    }
  });
  const [extraPayment, setExtraPayment] = useState('');
  const [repaymentStrategy, setRepaymentStrategy] = useState('');
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [showRepaymentStrategy, setShowRepaymentStrategy] = useState(false);
  const analysisRef = useRef(null);
  const repaymentStrategyRef = useRef(null);
  const pageContainerRef = useRef(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [totalEMI, setTotalEMI] = useState(0);
  const [overviewStrategy, setOverviewStrategy] = useState(null);
  const [firstTargetStrategy, setFirstTargetStrategy] = useState(null);
  const [firstLoanDate, setFirstLoanDate] = useState('');
  const [strategyGenerated, setStrategyGenerated] = useState(false);
  const [calculationResults, setCalculationResults] = useState([]);
  const [error, setError] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculationError, setCalculationError] = useState(null);
  const [earliestLoanClosure, setEarliestLoanClosure] = useState(null);
  const [nextLoanDetails, setNextLoanDetails] = useState(null);
  const [finalClosureDates, setFinalClosureDates] = useState({});
  const [saveMessage, setSaveMessage] = useState('');
  
  useEffect(() => {
    // Only load other states if we have valid data
    try {
      const savedData = getFromLocalStorage('repayDebts', null, globalMonthYear);
      if (savedData) {
        if (savedData.extraPayment) setExtraPayment(savedData.extraPayment);
        if (savedData.selectedStrategy) setSelectedStrategy(savedData.selectedStrategy);
        if (savedData.repaymentStrategy) setRepaymentStrategy(savedData.repaymentStrategy);
      }
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Error loading saved data:', error);
    }
  }, [globalMonthYear]);

  // Modify the storage update effect to exclude loans
  useEffect(() => {
    const existingData = getFromLocalStorage('repayDebts', null, globalMonthYear) || {};
    
    const updatedData = {
      ...existingData,
      ...(extraPayment ? { extraPayment } : {}),
      ...(analysis?.loanSummaries ? { loanAnalysis: analysis } : {}),
      ...(selectedStrategy ? { selectedStrategy } : {}),
      ...(repaymentStrategy ? { repaymentStrategy } : {})
    };

    updateLocalStorage('repayDebts', updatedData, globalNextMonthYear);
  }, [extraPayment, analysis, selectedStrategy, repaymentStrategy, globalMonthYear]);

  // Add useEffect to handle month-year values and data carryover
  useEffect(() => {
    if (monthYear && nextMonthYear) {
        globalMonthYear = monthYear;
        globalNextMonthYear = nextMonthYear;

        console.log('Loading data for current month:', globalMonthYear);
        
        // Load repayDebts data
        const savedRepayData = getFromLocalStorage('repayDebts', null, globalMonthYear);
        console.log('Retrieved repayDebts data:', savedRepayData);

        // Load budget data for next month
        const nextMonthBudgetData = getFromLocalStorage('budget', 'budgetData', globalMonthYear) || {};
        console.log('Retrieved next month budget data:', nextMonthBudgetData);

        // If we have saved calculation results, update the budget data
        if (savedRepayData?.calculationResults?.length > 0) {
            const loanSummaries = savedRepayData.loanAnalysis?.loanSummaries || [];
            const totalMonthlyEMI = loanSummaries.reduce((total, loan) => 
                total + (parseFloat(loan.monthlyEMI) || 0), 0);
            const extraPaymentAmount = parseFloat(savedRepayData.extraPayment || 0);
            const totalDebtPayment = totalMonthlyEMI + extraPaymentAmount;

            // Update budget data
            onBudgetDataChange('Debts', totalDebtPayment);
            if (extraPaymentAmount > 0) {
                onBudgetDataChange('Extra Debt Payment', extraPaymentAmount);
            }

            // Update the budget data in localStorage
            const mergedBudgetData = {
                ...nextMonthBudgetData,
                Debts: totalDebtPayment,
                'Extra Debt Payment': extraPaymentAmount
            };
            updateLocalStorage('budget', { budgetData: mergedBudgetData }, globalNextMonthYear);
        }

        // Load Spend Analyzer data and continue with existing logic...
        const spendAnalyzerData = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear);
        console.log('Retrieved Spend Analyzer data:', spendAnalyzerData);

        if (savedRepayData?.loanAnalysis?.loanSummaries && spendAnalyzerData) {
            // Get the highest priority loan from calculationResults if available
            const priorityLoanType = savedRepayData.calculationResults?.[0]?.loanType;
            console.log('Priority loan for extra payment:', priorityLoanType);

            // Check if payments have already been applied for this month
            const paymentsApplied = savedRepayData.loanAnalysis.paymentsApplied || {};
            
            // Update loan summaries based on payments in Spend Analyzer
            const updatedLoanSummaries = savedRepayData.loanAnalysis.loanSummaries.map(loan => {
                // Skip if payment was already applied for this loan this month
                if (paymentsApplied[loan.loanType]?.[globalMonthYear]) {
                    console.log(`Payment already applied for ${loan.loanType} in ${globalMonthYear}`);
                    return loan;
                }

                // Find matching payment in Spend Analyzer
                const payment = spendAnalyzerData.find(expense => 
                    expense.category === loan.loanType || 
                    expense.customInputs?.[expense.category] === loan.loanType
                );

                if (payment) {
                    console.log(`Found payment for ${loan.loanType}:`, payment);
                    const paymentAmount = parseFloat(payment.amount) || 0;

                    // Find extra payment
                    const extraPayment = spendAnalyzerData.find(expense => 
                        expense.category === 'Extra Debt Payment'
                    );
                    
                    // Apply extra payment only to the priority loan
                    const extraAmount = (loan.loanType === priorityLoanType && extraPayment) ? 
                        (parseFloat(extraPayment.amount) || 0) : 0;

                    if (extraAmount > 0) {
                        console.log(`Applying extra payment of ${extraAmount} to priority loan: ${loan.loanType}`);
                    }

                    // Calculate new outstanding amount
                    const totalPayment = paymentAmount + extraAmount;
                    const newOutstanding = Math.max(0, parseFloat(loan.amountOutstanding) - totalPayment);

                    // Mark payment as applied for this loan and month
                    paymentsApplied[loan.loanType] = {
                        ...paymentsApplied[loan.loanType],
                        [globalMonthYear]: {
                            appliedOn: new Date().toISOString(),
                            regularPayment: paymentAmount,
                            extraPayment: extraAmount
                        }
                    };

                    return {
                        ...loan,
                        amountOutstanding: newOutstanding,
                        tenurePending: Math.max(0, loan.tenurePending - 1) // Reduce tenure by 1 month
                    };
                }
                return loan;
            });

            // Update analysis state with updated loan summaries and payment tracking
            setAnalysis({
                ...savedRepayData.loanAnalysis,
                loanSummaries: updatedLoanSummaries,
                paymentsApplied
            });

            // Store updated loan summaries and payment tracking
            updateLocalStorage('repayDebts', {
                ...savedRepayData,
                loanAnalysis: {
                    ...savedRepayData.loanAnalysis,
                    loanSummaries: updatedLoanSummaries,
                    paymentsApplied
                }
            }, globalNextMonthYear);
        }

        if (savedRepayData) {
            // Set other states if available
            if (savedRepayData.extraPayment) setExtraPayment(savedRepayData.extraPayment);
            if (savedRepayData.selectedStrategy) setSelectedStrategy(savedRepayData.selectedStrategy);
            if (savedRepayData.repaymentStrategy) setRepaymentStrategy(savedRepayData.repaymentStrategy);
            if (savedRepayData.calculationResults) setCalculationResults(savedRepayData.calculationResults);
        }
    }
  }, [monthYear, nextMonthYear]);

  const scrollToRef = (ref) => {
    if (ref && ref.current && pageContainerRef.current) {
      const headerHeight = 60; // Adjust this value based on your header height
      const pageContainerTop = pageContainerRef.current.offsetTop;
      const elementTop = ref.current.offsetTop;
      const scrollPosition = elementTop - pageContainerTop - headerHeight;
      window.scrollTo({top: scrollPosition, behavior: 'smooth'});
    }
  };

  const scrollToAnalysisSummary = () => {
    if (analysisRef.current) {
      analysisRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // Adjust for header height
      window.scrollBy(0, -60); // Adjust -60 based on your header height
    }
  };

  const handleAddLoan = () => {
    const updatedLoans = [...loans, { loanType: '', amount: '', interestRate: '', tenure: '', emi: '' }];
    setLoans(updatedLoans);
    setHasUnsavedChanges(true);
  };

  const handleLoanChange = (index, field, value) => {
    const updatedLoans = loans.map((loan, i) => {
      if (i === index) {
        if (field === 'tenure') {
          const newValue = value === '' ? '' : Math.max(0, parseInt(value) || 0);
          return {
            ...loan,
            tenure: newValue,
            totalTenure: newValue
          };
        }
        return { ...loan, [field]: value };
      }
      return loan;
    });
    
    setLoans(updatedLoans);
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    setIsLoading(true);
    try {
      setTimeout(() => {
        // Get existing loan summaries from current month
        const existingAnalysis = getFromLocalStorage('repayDebts', 'loanAnalysis', globalMonthYear) || {};
        const existingLoanSummaries = existingAnalysis.loanSummaries || [];
        console.log('Existing loan summaries from current month:', existingLoanSummaries);

        // Calculate new loan summaries
        const newLoanSummaries = loans.map(loan => {
          const originalLoanAmount = calculateOriginalLoanAmount(loan);
          const totalInterest = calculateTotalInterest(loan);
          const repaymentDate = calculateRepaymentDate(loan);

          // Add timestamp for when this loan was added/updated
          const timestamp = new Date().toISOString();
          
          return {
            loanType: loan.loanType,
            originalLoanAmount,
            amountOutstanding: parseFloat(loan.amount) || 0,
            tenurePending: parseInt(loan.tenure) || 0,
            monthlyEMI: parseFloat(loan.emi) || 0,
            totalInterest,
            repaymentDate,
            interestRate: parseFloat(loan.interestRate) || 0,
            addedOn: timestamp,
            monthYear: globalMonthYear
          };
        });

        // Combine existing and new loans, updating existing ones if needed
        const updatedLoanSummaries = [...existingLoanSummaries];
        
        newLoanSummaries.forEach(newLoan => {
          const existingIndex = updatedLoanSummaries.findIndex(
            existing => existing.loanType === newLoan.loanType
          );
          
          if (existingIndex >= 0) {
            // Update existing loan
            updatedLoanSummaries[existingIndex] = newLoan;
          } else {
            // Add new loan
            updatedLoanSummaries.push(newLoan);
          }
        });

        console.log('Final updated loan summaries:', updatedLoanSummaries);

        // Update state and storage
        setAnalysis({ loanSummaries: updatedLoanSummaries });
        updateLocalStorage('repayDebts', { 
          loanAnalysis: { 
            loanSummaries: updatedLoanSummaries
          }
        }, globalNextMonthYear);

        setIsLoading(false);
        scrollToRef(analysisRef);
      }, 1000);
    } catch (error) {
      console.error('Error analyzing loans:', error);
      setIsLoading(false);
    }
  };

  const calculateOriginalLoanAmount = (loan) => {
    const monthlyRate = (parseFloat(loan.interestRate) || 0) / 12 / 100;
    const tenure = parseInt(loan.tenure) || 0;
    const emi = parseFloat(loan.emi) || 0;
    
    console.log('Debug calculateOriginalLoanAmount:', {
      monthlyRate,
      tenure,
      emi,
      loan
    });
    
    return (emi * (1 - Math.pow(1 + monthlyRate, -tenure))) / monthlyRate || 0;
  };

  const calculateTotalInterest = (loan) => {
    const emi = parseFloat(loan.emi) || 0;
    const tenure = parseInt(loan.tenure) || 0;
    const originalAmount = calculateOriginalLoanAmount(loan);
    
    console.log('Debug calculateTotalInterest:', {
      emi,
      tenure,
      originalAmount,
      loan
    });
    
    return (emi * tenure) - originalAmount || 0;
  };

  const calculateRepaymentDate = (loan) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + (parseInt(loan.tenure) || 0));
    // Return date in "MMM YYYY" format to match the expected format in handleSave
    return currentDate.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "short"
    });
  };

  const handleStrategyChange = (e) => {
    const value = e.target.value;
    setSelectedStrategy(value);
    // Map the user-friendly options to the backend strategy names
    const backendStrategy = value === 'time' ? 'avalanche' : 'snowball';
    console.log('Backend strategy:', backendStrategy);
      setHasUnsavedChanges(true);
    // You can use the backendStrategy value when sending data to the backend
  };

  const handleTakeAction = () => {
    setShowRepaymentStrategy(true);
    setTimeout(() => {
      scrollToRef(repaymentStrategyRef);
    }, 100);
  };

  const handleExtraPaymentChange = (e) => {
    setExtraPayment(e.target.value);
    setHasUnsavedChanges(true);
  };

  const handleRepaymentStrategySubmit = () => {
    setIsCalculating(true);
    
    try {
        const results = calculateDynamicLoanRepayment(
            analysis.loanSummaries,
            extraPayment,
            selectedStrategy
        );
        
        setCalculationResults(results);
        setStrategyGenerated(true);
    } catch (error) {
        console.error('Error calculating strategy:', error);
    } finally {
        setIsCalculating(false);
    }
};

// Create a new component for the strategy content
const StrategyContent = ({ data }) => {
    const [overview, setOverview] = useState('');
    const [target, setTarget] = useState('');

    useEffect(() => {
        const fetchStrategyText = async () => {
            try {
                const overviewText = await generateOverview(data);
                const targetText = await generateFirstTarget(data);
                setOverview(overviewText);
                setTarget(targetText);
            } catch (error) {
                console.error('Error generating strategy text:', error);
            }
        };

        fetchStrategyText();
    }, [data]);

    return (
        <div className="repay-debts-strategy-explanation">
            <h3>Strategy Overview</h3>
            <div className="strategy-text">
                <ul>
                    {overview.split('- ').filter(Boolean).map((point, index) => (
                        <li key={index}>{point.trim()}</li>
                    ))}
                </ul>
            </div>
            <div className="strategy-target">
                <p>{target.split('\n\n')[0]}</p>
                <p>Loans under this strategy will be tackled in the following order:</p>
                <ul className="loan-order-list">
                    {target.split('\n').slice(-3).map((loan, index) => (
                        <li key={index}>{loan.trim()}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

// Update the render function to use the new component
const renderRepaymentStrategy = (data) => {
    return (
        <div className="repay-debts-results">
            <StrategyContent data={data} />
            
            <div className="repay-debts-impact-summary">
                <h3>Final Impact Summary</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Loan Name</th>
                                <th>Final Closure Date</th>
                                <th>New Interest</th>
                                <th>Time Saved</th>
                                <th>Interest Saved</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calculationResults.map((loan, index) => (
                                <tr key={index}>
                                    <td>{loan.loanType}</td>
                                    <td>{loan.finalClosureDate}</td>
                                    <td>₹{Math.round(loan.newInterest).toLocaleString()}</td>
                                    <td>{loan.timeSaved} months</td>
                                    <td>₹{Math.round(loan.interestSaved).toLocaleString()}</td>
                                </tr>
                            ))}
                            <tr className="totals-row">
                                <td colSpan="2"><strong>Total Savings</strong></td>
                                <td>-</td>
                                <td>
                                    <strong>
                                        {calculationResults.reduce((total, loan) => 
                                            total + (loan.timeSaved || 0), 0)} months
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        ₹{calculationResults.reduce((total, loan) => 
                                            total + (loan.interestSaved || 0), 0).toLocaleString()}
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className="note">
                    This repayment strategy will help you save both time and interest across all your loans. 
                    The savings shown above assume you maintain the additional payments throughout the loan tenures.
                </p>
            </div>
        </div>
    );
};

  const handleSave = () => {
    try {
        if (calculationResults?.length > 0) {
            // Get loan analysis data
            const loanSummaries = analysis.loanSummaries;
            console.log('Loan Summaries:', loanSummaries);
            
            // Calculate total monthly EMI
            const totalMonthlyEMI = loanSummaries.reduce((total, loan) => 
                total + (parseFloat(loan.monthlyEMI) || 0), 0);
            console.log('Total Monthly EMI:', totalMonthlyEMI);
            
            // Calculate total debt payment including extra payment
            const extraPaymentAmount = parseFloat(extraPayment || 0);
            const totalDebtPayment = totalMonthlyEMI + extraPaymentAmount;
            console.log('Total Monthly EMI:', totalMonthlyEMI);
            console.log('Total Debt Payment:', totalDebtPayment);

            // Update budget data for both regular EMI and extra payment in a single batch
            if (onBudgetDataBatchChange) {
                onBudgetDataBatchChange({
                    'Debts': totalDebtPayment,
                    'Extra Debt Payment': extraPaymentAmount
                });
            } else {
                // Fallback to individual updates if batch update is not available
                onBudgetDataChange('Debts', totalDebtPayment);
                if (extraPaymentAmount > 0) {
                    console.log('Adding Extra Debt Payment to budget:', extraPaymentAmount);
                    onBudgetDataChange('Extra Debt Payment', extraPaymentAmount);
                }
            }

            // Store only budget-related data for next month
            const nextMonthData = {
                calculationResults: calculationResults,
                extraDebtPayment: extraPaymentAmount,
                extraPayment: extraPaymentAmount,
                repaymentStrategy: selectedStrategy,
                selectedStrategy: selectedStrategy,
                totalMonthlyPayment: totalDebtPayment,
                detailedCalculations: calculationResults.reduce((acc, loan) => {
                    console.log('Processing loan:', loan.loanType);
                    console.log('Loan data:', loan);
                    if (loan.monthlyCalculations) {
                        console.log('Found monthly calculations:', loan.monthlyCalculations);
                        acc[loan.loanType] = {
                            months: loan.monthlyCalculations.map((month, index) => ({
                                monthNumber: index + 1,
                                interest: month.interest,
                                principalPaid: month.principalPaid,
                                monthlyPayment: month.monthlyPayment,
                                outstandingBalanceBefore: month.outstandingBalanceBefore,
                                outstandingBalanceAfter: month.outstandingBalanceAfter,
                                extraPayment: month.extraPayment || extraPaymentAmount
                            }))
                        };
                    } else {
                        console.log('No monthly calculations found for loan:', loan.loanType);
                    }
                    return acc;
                }, {})
            };

            console.log('calculationResults:', calculationResults);
            console.log('nextMonthData:', nextMonthData);

            // Store the data in localStorage for next month
            console.log('Storing data for next month:', nextMonthData);
            updateLocalStorage('repayDebts', nextMonthData, globalNextMonthYear);

            // Get existing budget data
            const existingBudgetData = getFromLocalStorage('budget', 'budgetData', globalMonthYear) || {};
            console.log('Existing budget data:', existingBudgetData);

            // Merge new debt data with existing budget data
            const mergedBudgetData = {
                ...existingBudgetData,
                Debts: totalDebtPayment,
                'Extra Debt Payment': extraPaymentAmount
            };
            console.log('Merged budget data:', mergedBudgetData);

            // Store the merged budget data
            updateLocalStorage('budget', { budgetData: mergedBudgetData }, globalNextMonthYear);

            setHasUnsavedChanges(false);
            setSaveMessage('The Debt payment details are added');
            if (onSave) {
                onSave();
            }
        }
    } catch (error) {
        console.error('Error saving debt repayment data:', error);
        setSaveMessage('Error saving debt repayment data');
    }
  };

  const handleDeleteLoan = (index) => {
    const updatedLoans = loans.filter((_, i) => i !== index);
    setLoans(updatedLoans);
    
    // Update total EMI after deletion
    const newTotalEMI = updatedLoans.reduce((sum, loan) => sum + parseFloat(loan.emi || 0), 0);
    setTotalEMI(newTotalEMI);
    
    // Update budgetData
    onBudgetDataChange('Debts', newTotalEMI);
  };
  
  const validateLoanData = (loans) => {
    if (!loans?.length) {
        throw new Error("No loans provided");
    }

    loans.forEach((loan, index) => {
        if (!loan.monthlyEMI || isNaN(loan.monthlyEMI)) {
            throw new Error(`Invalid EMI for loan ${index + 1}`);
        }
        if (!loan.amountOutstanding || isNaN(loan.amountOutstanding)) {
            throw new Error(`Invalid outstanding amount for loan ${index + 1}`);
        }
        if (!loan.interestRate || isNaN(loan.interestRate)) {
            throw new Error(`Invalid interest rate for loan ${index + 1}`);
        }
        if (!loan.repaymentDate) {
            throw new Error(`Invalid repayment date for loan ${index + 1}`);
        }
    });

    return true;
  };
  
  const prepareLoanData = (loans, strategy) => {
    return loans.map(loan => ({
        loanType: loan.loanType,
        monthlyEMI: parseFloat(loan.monthlyEMI),
        amountOutstanding: parseFloat(loan.amountOutstanding),
        repaymentDate: loan.repaymentDate,
        interestRate: parseFloat(loan.interestRate)
    }));
  };

  const sortLoansByStrategy = (loans, strategy) => {
    return [...loans].sort((a, b) => {
        if (strategy === "time") {
            return parseFloat(b.interestRate) - parseFloat(a.interestRate);
        } else {
            return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
        }
    });
  };
  // Calculate earliest loan closure and next priority loan
  useEffect(() => {
    if (!analysis?.loanSummaries?.length || !calculationResults?.length || !extraPayment) {
        return;
    }

    try {
        // Step 3 calculations
        const loanClosures = analysis.loanSummaries.map(loan => {
            const result = calculationResults.find(r => r.loanType === loan.loanType);
            if (!result) return null;

            const isFirstLoan = loan.loanType === calculationResults[0].loanType;
            
            return {
                loanType: loan.loanType,
                originalEMI: loan.monthlyEMI,
                currentClosure: loan.repaymentDate,
                updatedClosure: isFirstLoan ? result.newClosureDate : loan.repaymentDate,
                amountOutstanding: loan.amountOutstanding,
                interestRate: loan.interestRate,
                tenurePending: loan.tenurePending
            };
        }).filter(Boolean); // Remove any null values

        if (loanClosures.length === 0) {
            throw new Error('No valid loan closures calculated');
        }

        const earliest = loanClosures.reduce((earliest, current) => {
            return new Date(current.updatedClosure) < new Date(earliest.updatedClosure) ? current : earliest;
        });

        // Step 4 calculations
        const nextLoanType = (() => {
            // Check if we have at least 2 loans in calculationResults
            if (calculationResults.length < 2) {
                return null; // No next loan if only one loan exists
            }
            
            return earliest.loanType === calculationResults[0].loanType
                ? calculationResults[1].loanType
                : calculationResults[0].loanType;
        })();

        // Only proceed if we have a next loan type
        if (nextLoanType) {
            const nextLoan = analysis.loanSummaries.find(loan => loan.loanType === nextLoanType);
            
            if (nextLoan) {
                const currentOutstanding = parseFloat(nextLoan.amountOutstanding);
                const outstandingAtClosure = calculateOutstandingAmount({
                    principal: currentOutstanding,
                    rate: parseFloat(nextLoan.interestRate) / (12 * 100),
                    emi: parseFloat(nextLoan.monthlyEMI),
                    startDate: new Date(),
                    tenure: parseInt(nextLoan.tenurePending)
                }, new Date(earliest.updatedClosure));

                const newAdditionalPayment = parseFloat(extraPayment) + parseFloat(earliest.originalEMI);
                const newMonthlyPayment = parseFloat(nextLoan.monthlyEMI) + newAdditionalPayment;

                const monthlyRate = parseFloat(nextLoan.interestRate) / (12 * 100);
                const newTenure = Math.ceil(
                    Math.log(newMonthlyPayment / (newMonthlyPayment - monthlyRate * outstandingAtClosure)) /
                    Math.log(1 + monthlyRate)
                );

                const nextLoanClosureDate = new Date(earliest.updatedClosure);
                nextLoanClosureDate.setMonth(nextLoanClosureDate.getMonth() + newTenure);

                // Set all the details
                setNextLoanDetails({
                    loanType: nextLoanType,
                    currentOutstanding,
                    outstandingAtClosure,
                    currentEMI: nextLoan.monthlyEMI,
                    additionalPayment: newAdditionalPayment,
                    newMonthlyPayment,
                    newTenure,
                    closureDate: nextLoanClosureDate.toISOString(),
                    // Add these for completeness
                    interestRate: nextLoan.interestRate,
                    amountOutstanding: nextLoan.amountOutstanding
                });

                // ... rest of the effect ...

            }
        }

    } catch (error) {
        console.error('Error in calculations:', error);
    }
  }, [analysis, calculationResults, extraPayment]);

  const getNewClosureDate = (loan) => {
    if (!loan || !earliestLoanClosure || !nextLoanDetails) return null;

    if (loan.loanType === earliestLoanClosure.loanType) {
        return new Date(earliestLoanClosure.updatedClosure);
    } else if (loan.loanType === nextLoanDetails.loanType) {
        return nextLoanDetails.closureDate;
    } else {
        return new Date(loan.repaymentDate);
    }
  };

  const getNewTenure = (loan) => {
    if (!loan || !earliestLoanClosure || !nextLoanDetails) return loan?.tenurePending || 0;

    if (loan.loanType === earliestLoanClosure.loanType) {
        return Math.round(
            (new Date(earliestLoanClosure.updatedClosure) - new Date()) / 
            (1000 * 60 * 60 * 24 * 30.44)
        );
    } else if (loan.loanType === nextLoanDetails.loanType) {
        return nextLoanDetails.newTenure;
    } else {
        return loan.tenurePending;
    }
  };

  const getNewMonthlyPayment = (loan) => {
    if (!loan || !earliestLoanClosure || !nextLoanDetails) return loan?.monthlyEMI || 0;

    if (loan.loanType === earliestLoanClosure.loanType) {
        return parseFloat(loan.monthlyEMI) + parseFloat(extraPayment);
    } else if (loan.loanType === nextLoanDetails.loanType) {
        return nextLoanDetails.newMonthlyPayment;
    } else {
        return parseFloat(loan.monthlyEMI);
    }
  };

  const getFinalClosureDate = (loanType) => {
    try {
        const dateString = finalClosureDates[loanType];
        return dateString ? dateString : null;
    } catch (error) {
        console.error('Error getting final closure date:', error);
        return null;
    }
  };

  // Add this helper function at the component level
  const calculateOutstandingAmount = (loanData, targetDate) => {
    const {
        principal,
        rate,
        emi,
        startDate = new Date(),
        tenure
    } = loanData;

    // Convert dates to timestamps
    const startTimestamp = new Date(startDate).getTime();
    const targetTimestamp = new Date(targetDate).getTime();

    // Calculate months between dates
    const monthsBetween = Math.round((targetTimestamp - startTimestamp) / (1000 * 60 * 60 * 24 * 30.44));

    if (monthsBetween <= 0) return principal;

    // Calculate outstanding amount
    let outstanding = principal;
    for (let i = 0; i < monthsBetween && i < tenure; i++) {
        const interest = outstanding * rate;
        const principal_component = emi - interest;
        outstanding -= principal_component;
    }

    return Math.max(0, outstanding);
  };

  const renderLoanAnalysisSummary = () => {
    if (!analysis?.loanSummaries || analysis.loanSummaries.length === 0) {
      return <p>No loan analysis available. Please enter loan details and click "Analyze Loans".</p>;
    }

    return (
      <div className="loan-analysis-summary">
        <h3>Your Loan Analysis Summary (as of {globalNextMonthYear})</h3>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Loan Type</th>
                <th>Original Amount</th>
                <th>Outstanding Amount</th>
                <th>Tenure Pending</th>
                <th>Monthly EMI</th>
                <th>Total Interest</th>
                <th>Repayment Date</th>
                <th>Interest Rate</th>
                <th>Added/Updated On</th>
              </tr>
            </thead>
            <tbody>
              {analysis.loanSummaries.map((loan, index) => {
                // Format the timestamp for display
                const addedDate = loan.addedOn ? new Date(loan.addedOn).toLocaleDateString() : 'N/A';
                const addedTime = loan.addedOn ? new Date(loan.addedOn).toLocaleTimeString() : '';
                
                return (
                  <tr key={`${loan.loanType}-${index}`}>
                    <td>{loan.loanType}</td>
                    <td>₹{loan.originalLoanAmount.toFixed(2)}</td>
                    <td>₹{loan.amountOutstanding.toFixed(2)}</td>
                    <td>{loan.tenurePending} months</td>
                    <td>₹{loan.monthlyEMI.toFixed(2)}</td>
                    <td>₹{loan.totalInterest.toFixed(2)}</td>
                    <td>{loan.repaymentDate}</td>
                    <td>{loan.interestRate}%</td>
                    <td>{addedDate}<br/>{addedTime}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {calculationResults && calculationResults.length > 0 && (
          <div className="calculation-results">
            <h4>Loan Closure Analysis</h4>
            {calculationResults.map((result, index) => (
              <div key={index} className="result-item">
                <p>If you continue with the current EMI of ₹{result.monthlyPayment.toFixed(2)}</p>
                <p>Your loans will be fully paid off by: {result.finalClosureDate}</p>
                <p>Total interest paid: ₹{result.totalInterestPaid.toFixed(2)}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="repay-debts-container">
      <div className="repay-debts-description">
        <div className="repay-debts-description-header">
          <h2>Debt Repayment Planner</h2>
          <p className="repay-debts-description-subtitle">Optimize your debt repayment strategy</p>
        </div>
        <div className="repay-debts-description-features">
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon">📊</span>
            <span>Track multiple loans</span>
          </div>
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon">💡</span>
            <span>Smart payment strategies</span>
          </div>
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon">⚡</span>
            <span>Debt-free faster</span>
          </div>
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon"></span>
            <span>Interest savings</span>
          </div>
        </div>
      </div>
      <h3 className="repay-debts-loan-details-heading">Loan Details</h3>
      <div className="repay-debts-loan-input-section">
        <form onSubmit={handleSubmit}>
          <div className="repay-debts-loan-table-container">
            <table className="repay-debts-loan-details-table">
              <thead>
                <tr>
                  <th>Loan Type</th>
                  <th>Amount</th>
                  <th>Interest Rate (%)</th>
                  <th>Tenure (months)</th>
                  <th>EMI</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loans.map((loan, index) => (
                  <tr key={index}>
                    <td>
                      <select
                        value={loan.loanType}
                        onChange={(e) => handleLoanChange(index, 'loanType', e.target.value)}
                      >
                        <option value="">Select loan type</option>
                        {loanTypes.map((type) => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="number"
                        value={loan.amount}
                        onChange={(e) => handleLoanChange(index, 'amount', e.target.value)}
                        placeholder="Enter amount"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={loan.interestRate}
                        onChange={(e) => handleLoanChange(index, 'interestRate', e.target.value)}
                        placeholder="Enter rate"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        value={loan.tenure}
                        onChange={(e) => handleLoanChange(index, 'tenure', e.target.value)}
                        placeholder="Enter tenure"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={loan.emi}
                        onChange={(e) => handleLoanChange(index, 'emi', e.target.value)}
                        placeholder="Enter EMI"
                      />
                    </td>
                    <td>
                      <button 
                        type="button"
                        onClick={() => handleDeleteLoan(index)}
                        className="repay-debts-delete-button"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="repay-debts-button-container">
            <button
              type="button"
              onClick={handleAddLoan}
              className="repay-debts-add-button"
            >
              Add another loan
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="repay-debts-analyze-button"
            >
              Analyze my loans
            </button>
          </div>
        </form>
      </div>
      {isLoading && <p>Analyzing your loans...</p>}
      
      {/* Always show the loan analysis summary section */}
      <div className="repay-debts-analysis-results">
        <div className="repay-debts-analysis-summary">
          <h3>Loan Analysis Summary (as of {globalNextMonthYear})</h3>
          <div className="repay-debts-loan-summary-table-container">
            <table className="repay-debts-loan-summary-table">
              <thead>
                <tr>
                  <th>Loan Type</th>
                  <th>Total Repayment Amount</th>
                  <th>Outstanding Loan amount</th>
                  <th>Interest Rate (%)</th>
                  <th>Total Interest</th>
                  <th>Monthly EMI</th>
                  <th>Tenure Pending</th>
                  <th>Loan Repayment Date</th>
                  <th>Added/Updated On</th>
                </tr>
              </thead>
              <tbody>
                {analysis?.loanSummaries?.length > 0 ? (
                  analysis.loanSummaries.map((loan, index) => {
                    const addedDate = loan.addedOn ? new Date(loan.addedOn).toLocaleDateString() : 'N/A';
                    const addedTime = loan.addedOn ? new Date(loan.addedOn).toLocaleTimeString() : '';
                    
                    return (
                      <tr key={index}>
                        <td>{loan.loanType}</td>
                        <td>₹{((loan.monthlyEMI * loan.tenurePending) || 0).toFixed(2)}</td>
                        <td>₹{(loan.amountOutstanding || 0).toFixed(2)}</td>
                        <td>{(loan.interestRate || 0).toFixed(2)}</td>
                        <td>₹{(((loan.monthlyEMI * loan.tenurePending) - loan.amountOutstanding) || 0).toFixed(2)}</td>
                        <td>₹{(loan.monthlyEMI || 0).toFixed(2)}</td>
                        <td>{loan.tenurePending} months</td>
                        <td>{loan.repaymentDate}</td>
                        <td>{addedDate}<br/>{addedTime}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9" className="no-loans-message">
                      No loans added yet. Use the form above to add your loans.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {analysis?.loanSummaries?.length > 0 && (
            <button onClick={handleTakeAction} className="repay-debts-take-action-btn">
              Take Action
            </button>
          )}
        </div>

        {showRepaymentStrategy && (
          <div className="repay-debts-repayment-strategy">
            <h3>Repayment Strategy</h3>
            <table className="repay-debts-strategy-options-table">
              <thead>
                <tr>
                  <th colSpan="2">Which repayment strategy would you like to use?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Reduce debt faster (optimize for time)</td>
                  <td>
                    <input 
                      type="radio" 
                      name="strategy" 
                      value="time" 
                      checked={selectedStrategy === 'time'}
                      onChange={handleStrategyChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Reduce the number of loans faster (optimize for count)</td>
                  <td>
                    <input 
                      type="radio" 
                      name="strategy" 
                      value="count" 
                      checked={selectedStrategy === 'count'}
                      onChange={handleStrategyChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {selectedStrategy && (
              <div className="repay-debts-extra-payment-box">
                <h3>Extra Payment</h3>
                <p>To reduce your debt faster, how much extra would you like to pay each month?</p>
                <input
                  type="number"
                  value={extraPayment}
                  onChange={handleExtraPaymentChange}
                  placeholder="Enter extra payment amount"
                />
                <button 
                  onClick={handleRepaymentStrategySubmit} 
                  className="repay-debts-generate-strategy-btn"
                >
                  Generate repayment strategy
                </button>
              </div>
            )}
            {isCalculating ? (
              <div className="repay-debts-loading">
                <p>Generating repayment strategy...</p>
              </div>
            ) : (
              calculationResults?.length > 0 && renderRepaymentStrategy({
                loans: analysis.loanSummaries,
                repaymentStrategy: selectedStrategy,
                extraPayment: extraPayment
              })
            )}
          </div>
        )}
      </div>
      {strategyGenerated && (
        <div className="repay-debts-save-button-container">
          <button 
            onClick={handleSave} 
            className="repay-debts-save-button"
          >
            Save Details
          </button>
          {saveMessage && (
            <div className="save-message">
              {saveMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default RepayDebts;


