// Utility functions for month-year based storage

export const getStorageKey = () => {
  const date = new Date();
  return `${date.getMonth() + 1}-${date.getFullYear()}`;
};

// Initialize storage for a given month-year if it doesn't exist
const initializeMonthYearStorage = (monthYear) => {
  const existingData = localStorage.getItem(monthYear);
  if (!existingData) {
    localStorage.setItem(monthYear, JSON.stringify({}));
  }
};

// Add compression utility
const compressData = (data) => {
  try {
    return JSON.stringify(data);
  } catch (error) {
    console.warn('Failed to compress data:', error);
    return null;
  }
};

// Add decompression utility
const decompressData = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.warn('Failed to decompress data:', error);
    return {};
  }
};

export const updateLocalStorage = (pageName, data, monthYear = null) => {
  try {
    const storageKey = monthYear || getStorageKey();
    
    // Try to clean up old data first if we're about to exceed quota
    try {
      clearOldData(6); // Keep only last 6 months of data by default
    } catch (e) {
      console.warn('Failed to clear old data:', e);
    }

    // Initialize storage if it doesn't exist
    initializeMonthYearStorage(storageKey);
    
    // Get existing data
    const existingData = decompressData(localStorage.getItem(storageKey) || '{}');
    
    // Initialize page object if it doesn't exist
    if (!existingData[pageName]) {
      existingData[pageName] = {};
    }
    
    // If data is already a string, parse it first
    const dataToStore = typeof data === 'string' ? JSON.parse(data) : data;
    
    // Update the page data
    existingData[pageName] = {
      ...existingData[pageName],
      ...dataToStore
    };
    
    try {
      // Try to store the compressed data
      const compressedData = compressData(existingData);
      if (compressedData) {
        localStorage.setItem(storageKey, compressedData);
      } else {
        throw new Error('Failed to compress data');
      }
    } catch (storageError) {
      if (storageError.name === 'QuotaExceededError' || 
          storageError.code === 22 || 
          storageError.code === 1014) {
        // If we hit quota, try aggressive cleanup
        clearOldData(3); // Keep only last 3 months as emergency cleanup
        
        // Try storing again with more aggressive compression
        const compressedData = compressData(existingData);
        if (compressedData) {
          localStorage.setItem(storageKey, compressedData);
        } else {
          throw new Error('Failed to compress data even after cleanup');
        }
      } else {
        throw storageError; // Re-throw if it's not a quota error
      }
    }
  } catch (error) {
    console.error('Error in updateLocalStorage:', error);
    throw new Error(`Failed to update storage for ${pageName}: ${error.message}`);
  }
};

export const getFromLocalStorage = (pageName, key = null, monthYear = null) => {
  // Use provided monthYear if available, otherwise use current month-year
  const storageKey = monthYear || getStorageKey();
  
  // Initialize storage if it doesn't exist
  initializeMonthYearStorage(storageKey);
  
  const existingData = decompressData(localStorage.getItem(storageKey) || '{}');
  
  // Initialize page object if it doesn't exist
  if (!existingData[pageName]) {
    existingData[pageName] = {};
    const compressedData = compressData(existingData);
    if (compressedData) {
      localStorage.setItem(storageKey, compressedData);
    }
  }
  
  if (key) {
    return existingData[pageName]?.[key];
  }
  return existingData[pageName];
};

// Helper function to get data from previous months
export const getPreviousMonthData = (pageName, numberOfMonths = 1) => {
  const date = new Date();
  date.setMonth(date.getMonth() - numberOfMonths);
  const previousKey = `${date.getMonth() + 1}-${date.getFullYear()}`;
  
  // Initialize storage if it doesn't exist
  initializeMonthYearStorage(previousKey);
  
  const previousData = JSON.parse(localStorage.getItem(previousKey) || '{}');
  return previousData[pageName] || {};
};

// Helper function to clear old data (optional)
export const clearOldData = (monthsToKeep = 6) => {
  const keys = Object.keys(localStorage);
  const currentDate = new Date();
  const oldestDate = new Date();
  oldestDate.setMonth(currentDate.getMonth() - monthsToKeep);

  let clearedCount = 0;
  keys.forEach(key => {
    // Check if key matches our format (M-YYYY)
    if (/^\d{1,2}-\d{4}$/.test(key)) {
      const [month, year] = key.split('-').map(Number);
      const keyDate = new Date(year, month - 1);
      if (keyDate < oldestDate) {
        localStorage.removeItem(key);
        clearedCount++;
      }
    }
  });

  // If we still need more space, try clearing any non month-year keys
  if (clearedCount === 0) {
    keys.forEach(key => {
      // Preserve globalMonthYear and globalNextMonthYear
      if (!/^\d{1,2}-\d{4}$/.test(key) && 
          key !== 'globalMonthYear' && 
          key !== 'globalNextMonthYear') {
        localStorage.removeItem(key);
      }
    });
  }
}; 