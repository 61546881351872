import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/ProgressBar.css';
import '../css/CommonLayout.css'; // Add this import

const steps = [
  { name: 'Priorities', path: '/priority' },
  { name: 'Pyramid', path: '/pyramid' },
  { name: 'Spend Analyzer', path: '/spend-analyzer' },
  { name: 'Summary', path: '/spend-analysis-summary' }
];

function ProgressBar() {
  const location = useLocation();

  const getCurrentStepIndex = () => {
    return steps.findIndex(step => step.path === location.pathname);
  };

  const currentStep = getCurrentStepIndex();

  return (
    <div className="progress-bar">
      {steps.map((step, index) => (
        <Link
          key={step.path}
          to={step.path}
          className={`progress-step ${index <= currentStep ? 'active' : ''} ${index === currentStep ? 'current' : ''}`}
        >
          <div className="step-number">{index + 1}</div>
          <div className="step-name">{step.name}</div>
        </Link>
      ))}
    </div>
  );
}

export default ProgressBar;
