import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import '../css/Pyramid.css';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';

const PYRAMID_COLORS = [
  '#FF9AA2', // Base level - strongest/warmest
  '#FFB7B2',
  '#FFDAC1',
  '#E2F0CB',
  '#B5EAD7',
  '#C7CEEA',
  '#E0BBE4'  // Top level - softest/coolest
];

const PYRAMID_DESCRIPTION = {
  title: 'Understanding Your Financial Journey',
  intro: 'Your personalized Financial Priority Pyramid is a powerful roadmap to achieving financial freedom. Like building a house, financial stability requires a strong foundation before adding higher levels.',
  keyPoints: [
    'Each level is a <span class="pyramid-highlight">key step</span> in your financial journey, starting with the <span class="pyramid-highlight">basics at the foundation</span>.',
    'You can focus on <span class="pyramid-highlight">mastering one level at a time</span> or <span class="pyramid-highlight">progress through multiple levels</span> based on your goals and preferences.',
    'Your priorities are <span class="pyramid-highlight">uniquely ordered</span> based on your financial goals and current situation.'
  ]
};

function Pyramid() {
  const location = useLocation();
  const navigate = useNavigate();
  const [priorities, setPriorities] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Load priorities from storage
    const storedPriorities = getFromLocalStorage('pyramid', 'priorities');
    if (storedPriorities) {
      setPriorities(storedPriorities);
    }

    // Load progress data
    const progressData = getFromLocalStorage('pyramid', 'progress');
    if (progressData) {
      setProgress(progressData);
    }
  }, []);

  const savePyramidData = (data) => {
    updateLocalStorage('pyramid', data);
  };

  const handlePriorityUpdate = (priority, status) => {
    const updatedPriorities = priorities.map(p => 
      p.id === priority.id ? { ...p, status } : p
    );
    setPriorities(updatedPriorities);
    savePyramidData({ priorities: updatedPriorities });
  };

  const handleProgressUpdate = (newProgress) => {
    setProgress(newProgress);
    savePyramidData({ progress: newProgress });
  };

  const PyramidDescription = () => (
    <div className="pyramid-description">
      <h2 className="pyramid-description-title">
        {PYRAMID_DESCRIPTION.title}
      </h2>
      <p className="pyramid-description-text">
        {PYRAMID_DESCRIPTION.intro.split('financial freedom').map((part, i) => 
          i === 0 ? part + <span className="pyramid-highlight">financial freedom</span> : part
        )}
      </p>
      <div className="pyramid-key-points">
        {PYRAMID_DESCRIPTION.keyPoints.map((point, index) => (
          <div key={index} className="pyramid-key-point">
            <span className="pyramid-point-number">{index + 1}</span>
            <p className="pyramid-key-point-text" 
               dangerouslySetInnerHTML={{ __html: point }}>
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  const PyramidLevels = () => {
    if (!Array.isArray(priorities) || priorities.length === 0) {
      return (
        <div className="pyramid-container">
          <div className="pyramid-empty-state">
            Please set your priorities first
          </div>
        </div>
      );
    }

    const reversedPriorities = [...priorities].reverse();
    return (
      <div className="pyramid-container">
        {reversedPriorities.map((item, index) => (
          <div 
            key={item?.id || index} 
            className="pyramid-level"
            style={{
              width: `${20 + (index * 10)}%`,
              backgroundColor: PYRAMID_COLORS[priorities.length - index - 1]
            }}
          >
            <span className="priority-rank">{priorities.length - index}</span>
            <span className="priority-content">{item?.content || 'Unknown Priority'}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="progress-bar-container">
        <ProgressBar />
      </div>
      <div className="page-container">
        <div className="pyramid-content-container">
          <h1 className="pyramid-title">Your Financial Priority Pyramid</h1>
          <PyramidDescription />
          <PyramidLevels />
          <div className="pyramid-button-container">
            <button 
              type="submit" 
              className="pyramid-submit-button"
              onClick={() => navigate('/spend-analyzer')}
            >
              Confirm my pyramid
            </button>
            <button 
              type="button" 
              className="pyramid-secondary-button"
              onClick={() => navigate('/priority')}
            >
              Change my priorities
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pyramid;
