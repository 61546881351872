// frontend/src/api.js
import axios from 'axios';
import https from 'https';
import fs from 'fs';
import path from 'path';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Create an HTTPS agent that ignores certificate validation
const agent = new https.Agent({
  rejectUnauthorized: false, // Ignore self-signed certificate error
});

export const registerUser = async (requestData) => {
  try {
    console.log('API_BASE_URL:', API_BASE_URL);
    const response = await axios.post(`${API_BASE_URL}/api/users/register`, requestData, {
      httpsAgent: agent,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Registration Successful:', response.data);
    return response.data;
  } catch (error) {
    console.log('Error:', error);
    console.error('Registration Failed:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const authenticateUser = async (requestData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/users/authenticate`, requestData, {
      httpsAgent: agent,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Authentication Successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Authentication Failed:', error.response ? error.response.data : error.message);
    throw error;
  }
};
