import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/UserProfile.css';
import { getFromLocalStorage } from '../../utils/storageUtils';
import https from 'https-browserify';

// Add utility functions
const getStorageKey = () => {
  const date = new Date();
  return `${date.getMonth() + 1}-${date.getFullYear()}`;
};




function UserProfile() {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Hardcoded certificate contents
  const agent = new https.Agent({
    cert: `-----BEGIN CERTIFICATE-----
MIIE/DCCA+SgAwIBAgISBOglp2NKXn6rx/iXwoftGiefMA0GCSqGSIb3DQEBCwUA
MDMxCzAJBgNVBAYTAlVTMRYwFAYDVQQKEw1MZXQncyBFbmNyeXB0MQwwCgYDVQQD
EwNSMTEwHhcNMjQxMjI2MTMwNDA5WhcNMjUwMzI2MTMwNDA4WjAhMR8wHQYDVQQD
ExY4NDcuZjNjLm15dGVtcC53ZWJzaXRlMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8A
MIIBCgKCAQEA7i/i5emGnh7l+LhcvmB6+1JqaCh598VCLQ6QI0sEtN3x3gwK3P0Q
6NEZynNAIbtsGFMW/mpDdogOOtmXb/HEY/Ma3vw9ZdvUFSx2l5IjhaFlmoKCnxOb
eC9E14MCGU0KAPKuxpDTs1UvW9qoNj4sf/36CHRF2E2hvxDHVVYoc8oBFdHmqO25
Gj9/Zw1Gjx3IkN40IoqYMgDZX2aHP8aH0GVvIWWZ9SAsLe76Kfpzn16Kg4Qmhi6B
4uh4FOc/1NlollkzsBJc2savlvatT+SzXqMsODDi3dd3GCaqMMoj1MPRFv4Bfovf
bTlWn2j4tN1JsACWCkm/p8tEXV49rn+FywIDAQABo4ICGjCCAhYwDgYDVR0PAQH/
BAQDAgWgMB0GA1UdJQQWMBQGCCsGAQUFBwMBBggrBgEFBQcDAjAMBgNVHRMBAf8E
AjAAMB0GA1UdDgQWBBRpI7L7bU8a3SWlvVZvjcVq7vdihTAfBgNVHSMEGDAWgBTF
z0ak6vTDwHpslcQtsF6SLybjuTBXBggrBgEFBQcBAQRLMEkwIgYIKwYBBQUHMAGG
Fmh0dHA6Ly9yMTEuby5sZW5jci5vcmcwIwYIKwYBBQUHMAKGF2h0dHA6Ly9yMTEu
aS5sZW5jci5vcmcvMCEGA1UdEQQaMBiCFjg0Ny5mM2MubXl0ZW1wLndlYnNpdGUw
EwYDVR0gBAwwCjAIBgZngQwBAgEwggEEBgorBgEEAdZ5AgQCBIH1BIHyAPAAdQDM
+w9qhXEJZf6Vm1PO6bJ8IumFXA2XjbapflTA/kwNsAAAAZQDRs4YAAAEAwBGMEQC
IGL9Bc0XW4mxRO6PusAbVfw8rGvuEp1umiahA9uFRNH/AiB3rb2vP3a3m2N/fSQG
CdrRsb4xk7a6ZE/Chpoe5fU3ZAB3AKLjCuRF772tm3447Udnd1PXgluElNcrXhss
xLlQpEfnAAABlANGzgcAAAQDAEgwRgIhAPwPQl2w7V0JH0S1Bz1nf5BExoPvpi6/
Jho7UuAlkN/iAiEA4dgyUzhYbpMRyQ2Zc/DYpypSbxoKqW1PKti10oCvmfEwDQYJ
KoZIhvcNAQELBQADggEBAInz7zcLHE+isycHlguQ1epWg1pW5yO/AfUAaR97z5JI
ezPMHDpPGr2dR853Osvppc4EQ8IpPPXYA6EvYoPgbGuiMeM8nlKzehslChcc1egk
OEs5gg9Q7lImoopy8I6dGyCV+VUEZtl0/bfE2snbZHhN2Th64ZyEeo2TvKsi3X3Z
JCzNUZ0VgPFsidchfK0FfWNs+2ehQeauOrmftVA6NtGEpZ7UvuEt/WecRfRoO+F5
mKnj5QF6rGNA5IwQVd4aGqp1kouD5EEdU9BeEhvxE0yert974fo0YwrlHjQKTtPM
g67nsHJ5mz8WkliFPe+gR/JCJ1uBbz0IHll0VUEvD5c=
-----END CERTIFICATE-----
`,
    key: `-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEA7i/i5emGnh7l+LhcvmB6+1JqaCh598VCLQ6QI0sEtN3x3gwK
3P0Q6NEZynNAIbtsGFMW/mpDdogOOtmXb/HEY/Ma3vw9ZdvUFSx2l5IjhaFlmoKC
nxObeC9E14MCGU0KAPKuxpDTs1UvW9qoNj4sf/36CHRF2E2hvxDHVVYoc8oBFdHm
qO25Gj9/Zw1Gjx3IkN40IoqYMgDZX2aHP8aH0GVvIWWZ9SAsLe76Kfpzn16Kg4Qm
hi6B4uh4FOc/1NlollkzsBJc2savlvatT+SzXqMsODDi3dd3GCaqMMoj1MPRFv4B
fovfbTlWn2j4tN1JsACWCkm/p8tEXV49rn+FywIDAQABAoIBAQDcoRrJ4PDDUteu
9gdiiTghyIusA6JG8j+EQPMe3gR0DQpXpkwBu6CIJ/h94RfMpzFPrtejvobTKuAT
fy6ZkRdbhfqkBn5Q9bmUC2WmrMWeqIvW/YFFHQJr500bW8z75rKXgjSG1exjN0rx
CK+73weVX4rVfe6cl/x1YXNjBrXd2byDySv9lKvDk8F9fFZ2RS3y1R5MYUBngVaI
6w1BmUV9x0zP7IncYWILQK/QxW/xPFUDmlpTdWjld9ag8kL2KR4LiEspYgQO4gpn
Mupt8UW0PVb/Id1HGJZn99LfVYoYUDt8F+ixLN7eT/2FdaJ9c2qEE4MnlALRzOAL
SLSktohxAoGBAPcGesI2hfeF/UEDP7azUSC0RKv1Ojha43uFDD8BdFujAiKWsZEv
ZrilmoKq22VmioTEjJX/B4wPlK/id19GuqxxpYr5ckW//yNDMWZmnMb0n4rDyk1a
j2V5b4/eey0I99m9miXseb94fGkLoyx/UgricTBdGK7n8+0JT9OLghnPAoGBAPbX
NFPPQOC1VLORYaxa1dKY4uddxQxdaW77Q6T06I5nnQ/E3hRBdtQgIdDIOy4kZlG0
I+2dpaOJ7kKscNsdBuhiyKG25hH9YLkDpkzWOJ36p4rD1no3Tsr7a/w5SowHjzDk
xBiHExg1YbbKrHhnR7jdALq0Vr9NWYN4wiDn6Z9FAoGBAKOS+dUDD8DxS9syEI2l
vhH+7P5FO5E3ti0D1DEggHFxxqcqzFhMYCPjHSZrhy5lMVJraICmyNOZOaciOlOv
3Ur1Ul5tgZroYUVzaBHb3vLr532W2q5QSmYyfIpzE3Ol3aGkqJ92MMR2P0O7/ggo
Iyar/bG94+tmEyMiKGb3mWYHAoGAMxhtDoQyS9F9TQZlD4ZmCIf5QCda6KpVbtG4
OOcxVHkCPAdmaQ78CAD2vv8TsQN74qHFZiRh6y5cEZUcb17c1THNkhwIF+U5JlMp
E3KsmZcPI1xSg037k2zj93vsgHv4Ggjgxqr/Ot2h8nKVrP7EC/JDemJmKodaZiqT
FLis1XkCgYBwYE/NMQZF10NKPaJkp9W3jmUVw+KReVVuGS2Cb3X/VKWZBHrwtW8R
nh15zMYtueb8vG5hrXPBspmth/h4jQsRXeo+uPsO+pQiF4RPxI/KFeD+33+s9n9j
uwPxocxldo/y1L5u0VkA0otRg8rfRHg/qYbt1N7L65lelN4jA34JGw==
-----END RSA PRIVATE KEY-----
`,
    ca: `Bag Attributes
    localKeyID: 8D A0 C0 56 55 0D 24 D1 1E 2D 89 21 DD C2 3B 2B 1E F6 B9 18
    friendlyName: mitral-server
subject=C = US, ST = State, L = City, O = Lets Encrypt, OU = Organizational Unit, CN = 3.89.161.60
issuer=C = US, ST = State, L = City, O = Lets Encrypt, OU = Organizational Unit, CN = 3.89.161.60
-----BEGIN CERTIFICATE-----
MIIDnTCCAoWgAwIBAgIUXMJLdpKv4dIPwNyaAEut75IB9bMwDQYJKoZIhvcNAQEL
BQAwdzELMAkGA1UEBhMCVVMxDjAMBgNVBAgMBVN0YXRlMQ0wCwYDVQQHDARDaXR5
MRUwEwYDVQQKDAxMZXRzIEVuY3J5cHQxHDAaBgNVBAsME09yZ2FuaXphdGlvbmFs
IFVuaXQxFDASBgNVBAMMCzMuODkuMTYxLjYwMB4XDTI1MDExNTA1NTY0N1oXDTI2
MDExNTA1NTY0N1owdzELMAkGA1UEBhMCVVMxDjAMBgNVBAgMBVN0YXRlMQ0wCwYD
VQQHDARDaXR5MRUwEwYDVQQKDAxMZXRzIEVuY3J5cHQxHDAaBgNVBAsME09yZ2Fu
aXphdGlvbmFsIFVuaXQxFDASBgNVBAMMCzMuODkuMTYxLjYwMIIBIjANBgkqhkiG
9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn15lGZtEs8UIo/4j30dR2/9IoH1/EbntnxkF
cyLoUYn4iYQlZcFddfCpYoc99jg/KEpQU77YhrcH1U/MOvMV/ai/jRqZicS/T++K
GNwwf1UEUmfRm+Zi7GexqBteZveKItHtK14hgZJXVo7aQY1IHmSJvGRuDxg+FKcM
1g5+iJVXwTecHRo0s5PXPdJqdZ/dhEwv7fpjt//7YPv/hZBY1Pjsct00NrWn10vi
RInRSus8C0hjI2c524+QMAL91RBNavP3SdbO3zPQRGVasoGeQVuhAzVcaAHDmfFy
60QToLG4QZru31THn9NDwfSn/Z4l4AgQJWALerUu0TE72bfOUQIDAQABoyEwHzAd
BgNVHQ4EFgQUp8OoX2A5ysVolMmrLPhq7W0wjHcwDQYJKoZIhvcNAQELBQADggEB
AH1OPsUpvmfBRGsTobqRo/tv6zLpX5ojiiBC46vPRQfn8AZcHLHQrO7FaAhPLxNn
H6/X2qgCYr54CEywssAjYrj0U8bnYSpZVj8Tl8IoeUWAnjHS3K0uJseQL3vvZb+5
joRdbMvk5oPqStAPFSAOEt0kRhjT0J2onEWBJ4Mt4evaqJ1rDciDucPNNwQH3lM4
K16zqVEiPHiD8FAFbmjOrnCBgOUa/2I3fqMCxXjc4wQKd1rl6Oo3ffZbmQjpj8bj
IPhbr1cpybz6zLLqw69QGvySJZ+JeWecdVAwwFufhT8lH1H0SWjvf7yKkJhWrUg7
kuGTBZtWMNKBX4iNlyGkYWA=
-----END CERTIFICATE-----
`,
    rejectUnauthorized: true,
  });
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Get email from new storage format
        const userEmail = getFromLocalStorage('login', 'userEmail');
        if (!userEmail) {
          throw new Error('User email not found');
        }

        const response = await axios.get(
          `https://3.89.161.60:8443/api/users/email?email=${userEmail}`,
          {
            httpsAgent: agent
          }
        );
        setUserProfile(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  // Generate random avatar URL
  const getRandomAvatar = () => {
    const avatarStyles = ['adventurer', 'adventurer-neutral', 'avataaars', 'big-ears', 'big-ears-neutral', 'bottts'];
    const randomStyle = avatarStyles[Math.floor(Math.random() * avatarStyles.length)];
    return `https://api.dicebear.com/7.x/${randomStyle}/svg?seed=${Math.random()}`;
  };

  if (loading) {
    return (
      <div className="profile-container">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="profile-container">
        <div className="error">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile-card">
        <div className="profile-header">
          <img 
            src={getRandomAvatar()} 
            alt="Profile" 
            className="profile-avatar"
          />
          <h1>{userProfile?.firstName} {userProfile?.lastName}</h1>
        </div>
        
        <div className="profile-details">
          <div className="detail-item">
            <label>Email</label>
            <p>{userProfile?.email}</p>
          </div>
          
          <div className="detail-item">
            <label>Phone Number</label>
            <p>{userProfile?.phoneNumber || 'Not provided'}</p>
          </div>
          
          <div className="detail-item">
            <label>Username</label>
            <p>{userProfile?.username}</p>
          </div>
        </div>

        <div className="profile-actions">
          <button className="edit-button">Edit Profile</button>
          <button className="change-password-button">Change Password</button>
        </div>
      </div>
    </div>
  );
}

export default UserProfile; 