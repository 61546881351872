import React, { useEffect, useState } from 'react';
import '../css/BudgetSummary.css';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

function BudgetSummary({ budgetData = {}, onNetIncomeChange, monthYear, nextMonthYear }) {
  const [budgetSummary, setBudgetSummary] = useState({});

  // Add useEffect to handle month-year values
  useEffect(() => {
    // Set global variables
    if (monthYear && nextMonthYear) {
      globalMonthYear = monthYear;
      globalNextMonthYear = nextMonthYear;
      console.log('BudgetSummary - Month/Year values:', { globalMonthYear, globalNextMonthYear });
    }
  }, [monthYear, nextMonthYear]);

  // Add default values for budgetData
  const defaultBudgetData = {
    Earnings: 0,
    'Essentials': 0,
    'Discretionary': 0,
    'Emergency Fund': 0,
    'Short Term Savings': 0,
    'Long Term Savings': 0,
    'Debts': 0,
    'Insurance': 0,
    'Retirement': 0,
  };

  // This function maps category names
  const getCategoryDisplayName = (category) => {
    switch (category) {
      case 'Debts':
        return 'Debt Reduction';
      case 'Debt Reduction':
        return 'Debt Reduction';
      case 'Expense Reduction':
        return 'Expenses';
      case 'Short-term Savings':
      case 'Short Term Savings':
        return 'Short Term Savings';
      case 'Long-term Savings':
      case 'Long Term Savings':
        return 'Long Term Savings';
      case 'Retirement Fund':
      case 'Retirement':
        return 'Retirement';
      default:
        return category;
    }
  };

  // Use budgetSummary instead of budgetData in calculations
  const earningsCategories = ['Earnings'];
  const expensesCategories = [
    'Essentials',
    'Discretionary',
    'Emergency Fund',
    'Short Term Savings',
    'Long Term Savings',
    'Debts',
    'Insurance',
    'Retirement'
  ];

  // Handle budget data updates
  useEffect(() => {
    console.log('BudgetSummary - Processing budget data:', budgetData);
    
    const safeData = { ...defaultBudgetData };
    
    Object.entries(budgetData).forEach(([category, amount]) => {
      let normalizedCategory;
      switch(category) {
        case 'Debts':
        case 'Debt Reduction':
          normalizedCategory = 'Debts';
          break;
        case 'Short-term Savings':
        case 'Short Term Savings':
          normalizedCategory = 'Short Term Savings';
          break;
        case 'Long-term Savings':
        case 'Long Term Savings':
          normalizedCategory = 'Long Term Savings';
          const existingAmount = safeData[normalizedCategory] || 0;
          // If there's already a value, take the non-zero one
          amount = amount || existingAmount;
          break;
        case 'Retirement Fund':
        case 'Retirement':
          normalizedCategory = 'Retirement';
          break;
        default:
          normalizedCategory = category;
      }

      const newValue = Number(amount) || 0;
      if (newValue > 0 || normalizedCategory === 'Debts') {  // Always update Debts category
        safeData[normalizedCategory] = newValue;
        console.log(`BudgetSummary - Updated ${normalizedCategory}:`, newValue);
      }
    });

    console.log('BudgetSummary - Setting budget summary:', safeData);
    setBudgetSummary(safeData);
  }, [budgetData]);

  // Calculate totals directly from budgetData
  const totalEarnings = earningsCategories.reduce(
    (total, category) => total + Number(budgetData[category] || 0),
    0
  );

  const totalExpenses = expensesCategories.reduce(
    (total, category) => {
      const amount = Number(budgetData[category] || 0);
      console.log(`BudgetSummary - Expense calculation for ${category}:`, amount);
      return total + amount;
    },
    0
  );

  const netIncome = totalEarnings - totalExpenses;

  // Pass netIncome to parent component whenever it changes
  useEffect(() => {
    if (onNetIncomeChange) {
      onNetIncomeChange(netIncome);
    }
  }, [netIncome, onNetIncomeChange]);

  // Format budget data for Conclusions
  useEffect(() => {
    console.log('BudgetSummary - Formatting budget data for storage:', {
      budgetData,
      totalEarnings,
      totalExpenses,
      netIncome
    });

    const formattedBudgetData = {
      earnings: {
        categories: earningsCategories,
        amounts: earningsCategories.map(category => {
          const amount = Number(budgetData[category] || 0);
          console.log(`BudgetSummary - Formatting earnings for ${category}:`, amount);
          return {
            category: getCategoryDisplayName(category),
            amount
          };
        }),
        total: totalEarnings
      },
      expenses: {
        categories: expensesCategories,
        amounts: expensesCategories
          .filter(category => category !== 'Insurance' && category !== 'Expense Reduction')
          .map(category => {
            const amount = Number(budgetData[category] || 0);
            console.log(`BudgetSummary - Formatting expenses for ${category}:`, amount);
            return {
              category: getCategoryDisplayName(category),
              amount
            };
          }),
        total: totalExpenses
      },
      netIncome
    };

    // Save formatted data to storage
    if (globalNextMonthYear) {
      console.log('BudgetSummary - Saving formatted data to storage:', formattedBudgetData);
      updateLocalStorage('budget', { summary: formattedBudgetData }, globalNextMonthYear);
    }
  }, [budgetData, totalEarnings, totalExpenses, netIncome]);

  return (
    <div className="budget-summary">
      <h3>Budget Summary (for {globalNextMonthYear})</h3>
      <table className="budget-summary-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {/* Earnings Section */}
          <tr className="section-header">
            <td colSpan="2">Earnings</td>
          </tr>
          {earningsCategories.map((category) => (
            <tr key={category}>
              <td>{getCategoryDisplayName(category)}</td>
              <td>₹{Number(budgetData[category] || 0).toFixed(2)}</td>
            </tr>
          ))}
          
          {/* Expenses Section */}
          <tr className="section-header">
            <td colSpan="2">Expenses</td>
          </tr>
          {expensesCategories.map((category) => {
            if (category === 'Expense Reduction') {
              return (
                <React.Fragment key={category}>
                  <tr>
                    <td>Essentials</td>
                    <td>₹{Number(budgetData['Essentials'] || 0).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Discretionary</td>
                    <td>₹{Number(budgetData['Discretionary'] || 0).toFixed(2)}</td>
                  </tr>
                </React.Fragment>
              );
            }

            return (
              <tr key={category}>
                <td>{getCategoryDisplayName(category)}</td>
                <td>₹{Number(budgetData[category] || 0).toFixed(2)}</td>
              </tr>
            );
          })}
          
          {/* Net Income Section */}
          <tr className={`net-income-row ${netIncome < 0 ? 'net-income-negative' : 'net-income-positive'}`}>
            <td colSpan="2">
              <strong>Net Income: ₹{Number(netIncome).toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BudgetSummary;
