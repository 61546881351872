import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Add useNavigate here
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import '../css/SpendAnalysisSummary.css';
import ProgressBar from './ProgressBar';
import '../css/CommonLayout.css';
import Chatbot from '../js/Chatbot';
import { getFromLocalStorage, updateLocalStorage, getPreviousMonthData } from '../../utils/storageUtils';

ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

const categoryMapping = {
  Essentials: ['Rent', 'Groceries', 'Utilities', 'Transport'],
  Discretionary: ['Travel', 'Dining', 'Shopping', 'Subscriptions'],
  Debts: ['Personal Loan', 'Credit Card Payment', 'Car loan', 'Gold loan', 'Home loan'],
  'Short-term Savings': ['Short-term Savings', 'Emergency Fund'],
  'Long-term Savings': ['Long-term Savings', 'Investments', 'Pension'],
  'Emergency Fund': ['Emergency Fund'],
  Insurance: ['Term Insurance', 'Health Insurance', 'Car Insurance'],
  'Retirement Fund': ['Retirement Fund', 'Retirement Savings'],
  Others: [] // This will catch all categories not mapped above
};

// Add a constant for disabled goals
const DISABLED_GOALS = ['Insurance'];

function SpendAnalysisSummary() {
  const location = useLocation();
  const navigate = useNavigate(); // Add this line
  const { spendData, income, monthYear, nextMonthYear } = location.state;
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [currentMonthData, setCurrentMonthData] = useState(null);
  const [previousMonthData, setPreviousMonthData] = useState(null);
  const [spendBreakdown, setSpendBreakdown] = useState(null);

  useEffect(() => {
    // Set global variables
    globalMonthYear = monthYear;
    globalNextMonthYear = nextMonthYear;

    const storedPriorities = getFromLocalStorage('pyramid', 'priorities');
    console.log('Stored priorities:', storedPriorities);
    // Ensure priorities is always an array
    setPriorities(Array.isArray(storedPriorities) ? storedPriorities : []);

    const storedGoals = getFromLocalStorage('spendAnalysisSummary', 'selectedGoals', globalMonthYear);
    console.log('Stored goals:', storedGoals);
    // Ensure selectedGoals is always an array
    setSelectedGoals(Array.isArray(storedGoals) ? storedGoals : []);

    // Load current month's data
    const currentData = getFromLocalStorage('spendAnalyzer');
    if (currentData) {
      setCurrentMonthData(currentData);
    }

    // Load previous month's data for comparison
    const previousData = getPreviousMonthData('spendAnalyzer');
    if (previousData) {
      setPreviousMonthData(previousData);
    }

    // Load budget data
    const budgetData = getFromLocalStorage('budget', 'budgetData', globalMonthYear) || {};
    const spendBreakdown = getFromLocalStorage('spendAnalysisSummary', 'spendBreakdown', globalMonthYear);
    
    if (spendBreakdown) {
      setSpendBreakdown(spendBreakdown);
    }
  }, [monthYear, nextMonthYear]);

  const saveAnalysisData = (data) => {
    updateLocalStorage('spendAnalysisSummary', data, globalMonthYear);
  };

  const categorizedSpending = useMemo(() => {
    console.log('Received spendData:', spendData);
    const result = Object.keys(categoryMapping).reduce((acc, category) => {
      acc[category] = 0;
      return acc;
    }, {});

    spendData.forEach(item => {
      const selectedMapping = item.categoryMapping;
      if (selectedMapping) {
        result[selectedMapping] += item.amount;
      } else {
        result.Others += item.amount;
      }
    });

    // Store the calculated totals in localStorage
    console.log('Storing category totals:', result);
    updateLocalStorage('spendAnalysisSummary', {categoryTotals: result}, globalMonthYear);

    return result;
  }, [spendData]);

  const totalSpend = Object.values(categorizedSpending).reduce((sum, amount) => sum + amount, 0);

  const chartData = {
    labels: Object.keys(categorizedSpending),
    datasets: [
      {
        data: Object.values(categorizedSpending),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let percentage = ((value * 100) / totalSpend).toFixed(2) + "%";
          return percentage;
        },
        color: '#fff',
      },
    },
  };

  const sortedInsights = useMemo(() => {
    // Calculate total savings (Short-term + Long-term + Retirement Fund)
    const totalSavings = (categorizedSpending['Short-term Savings'] || 0) + 
                        (categorizedSpending['Long-term Savings'] || 0) +
                        (categorizedSpending['Retirement Fund'] || 0);
    
    // Get essential and discretionary expenses directly from categorized spending
    const essentialExpenses = categorizedSpending['Essentials'] || 0;
    const discretionarySpending = categorizedSpending['Discretionary'] || 0;
    
    const monthlyIncome = parseFloat(location.state.income);

    const debtPayments = categorizedSpending['Debts'] || 0;
    const retirementFund = categorizedSpending['Retirement Fund'] || 0;

    console.log('Debug values:', {
      totalSavings,
      essentialExpenses,
      discretionarySpending,
      monthlyIncome,
      rawIncome: location.state.income
    });

    return [
      {
        condition: totalSavings >= (monthlyIncome * 0.1),
        positive: `Great job! You're saving ${((totalSavings / monthlyIncome) * 100).toFixed(1)}% of your income. Continue to save at least 10% of your income`,
        negative: `Your savings rate is ${((totalSavings / monthlyIncome) * 100).toFixed(1)}%. Try to save at least 10% of your income`
      },
      {
        condition: essentialExpenses <= (monthlyIncome * 0.4),
        positive: `Your essential expenses are well managed at ${((essentialExpenses / monthlyIncome) * 100).toFixed(1)}% of your income. Continue to keep them below 40%`,
        negative: `Your essential expenses are ${((essentialExpenses / monthlyIncome) * 100).toFixed(1)}% of your income. Try to reduce them below 40%`
      },
      {
        condition: discretionarySpending <= (monthlyIncome * 0.3),
        positive: `Your discretionary spending is well controlled at ${((discretionarySpending / monthlyIncome) * 100).toFixed(1)}% of your income. Continue to keep it below 30%`,
        negative: `Your discretionary spending is ${((discretionarySpending / monthlyIncome) * 100).toFixed(1)}% of your income. Try to keep it below 30%`
      },
      {
        condition: debtPayments <= (monthlyIncome * 0.3),
        positive: `Your debt payments are well managed at ${((debtPayments / monthlyIncome) * 100).toFixed(1)}% of your income. Continue to maintain it below 30%`,
        negative: `Your debt payments are ${((debtPayments / monthlyIncome) * 100).toFixed(1)}% of your income. Try to reduce them below 30%`
      }
    ];
  }, [categorizedSpending, location.state.income]);

  const handleGoalSelection = (goal) => {
    // Don't allow selection of disabled goals
    if (DISABLED_GOALS.includes(goal)) {
      return;
    }

    setSelectedGoals(prev => {
      const newGoals = prev.includes(goal) 
        ? prev.filter(g => g !== goal) 
        : [...prev, goal];
      updateLocalStorage('spendAnalysisSummary', {selectedGoals: newGoals}, globalMonthYear);
      return newGoals;
    });
  };

  const handleTakeAction = () => {
    // Save the selected goals in the order they appear in priorities
    const orderedSelectedGoals = priorities
      .filter(item => selectedGoals.includes(item.content))
      .map(item => item.content);

    // Format and save the spend breakdown data
    const spendBreakdown = {
      categories: Object.keys(categorizedSpending),
      amounts: Object.entries(categorizedSpending).map(([category, amount]) => ({
        category,
        amount: Number(amount),
        percentage: ((amount / totalSpend) * 100).toFixed(2)
      })),
      total: totalSpend
    };

    // Save all necessary data using new storage utilities
    updateLocalStorage('spendAnalysisSummary', {selectedGoals: orderedSelectedGoals}, globalMonthYear);
    updateLocalStorage('spendAnalysisSummary', {spendBreakdown: spendBreakdown}, globalMonthYear);
    updateLocalStorage('spendAnalysisSummary', {totalSpend: totalSpend}, globalMonthYear);

    // Store month-year values in localStorage
    localStorage.setItem('globalMonthYear', globalMonthYear);
    localStorage.setItem('globalNextMonthYear', globalNextMonthYear);

    console.log('Saving spend breakdown:', spendBreakdown);
    console.log('Month-year values being passed:', { globalMonthYear, globalNextMonthYear }); 

    if (orderedSelectedGoals.length > 0) {
      // Initialize budgetData with spend breakdown values
      const initialBudgetData = {
        Earnings: Number(income),
        ...Object.fromEntries(
          Object.entries(categorizedSpending).map(([category, amount]) => [
            category,
            Number(amount)
          ])
        )
      };

      // Save initial budget data
      updateLocalStorage('spendAnalysisSummary', {budgetData: initialBudgetData}, globalMonthYear);
      
      navigate('/aggregator', {
        state: {
          monthYear: globalMonthYear,
          nextMonthYear: globalNextMonthYear
        }
      });
    } else {
      alert('Please select at least one goal before proceeding.');
    }
  };

  return (
    <>
      <div className="progress-bar-container">
        <ProgressBar />
      </div>
      <div className="page-container">
        <div className="content-container">
          <h2>Summary of Your Monthly Spending</h2>

          <div className="summary-content">
            <div className="pie-chart">
              <Pie data={chartData} options={chartOptions} />
            </div>
            <div className="spend-breakdown">
              <h3>Spend Breakdown</h3>
              <table className="spend-breakdown-table">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Amount</th>
                    <th>Budget</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(categorizedSpending).map(([category, amount]) => {
                    const budgetData = getFromLocalStorage('budget', 'budgetData', globalMonthYear) || {};
                    let budgetAmount = 0;
                    
                    if (category === 'Debts') {
                      budgetAmount = budgetData['Debts'] || 0;
                    } else if (category === 'Short-term Savings') {
                      budgetAmount = budgetData['Short Term Savings'] || budgetData['Short-term Savings'] || 0;
                    } else if (category === 'Retirement Fund') {
                      budgetAmount = budgetData['Retirement'] || budgetData['Retirement Fund'] || 0;
                    } else {
                      budgetAmount = budgetData[category] || 0;
                    }
                    
                    return (
                      <tr key={category}>
                        <td>{category}</td>
                        <td>₹{Number(amount).toFixed(2)}</td>
                        <td>₹{Number(budgetAmount).toFixed(2)}</td>
                        <td>{((amount / totalSpend) * 100).toFixed(2)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td><strong>₹{totalSpend.toFixed(2)}</strong></td>
                    <td><strong>₹{Object.entries(getFromLocalStorage('budget', 'budgetData', globalMonthYear) || {})
                      .reduce((sum, [category, val]) => {
                        // Skip the Earnings category in the total
                        if (category === 'Earnings') return sum;
                        return sum + (val || 0);
                      }, 0).toFixed(2)}</strong></td>
                    <td><strong>100%</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="insights">
            <h3>Monthly Spends Insights</h3>
            <ul>
              {sortedInsights.map((insight, index) => (
                <li key={index} className={insight.condition ? 'positive' : 'negative'}>
                  {insight.condition ? insight.positive : insight.negative}
                </li>
              ))}
            </ul>
          </div>
          <div className="financial-goals">
            <h3>Select which financial goals you'd like to work on. You can select multiple goals:</h3>
            <table className="summary-financial-goals-table">
              <thead>
                <tr>
                  <th>Priority</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {priorities.map((item, index) => {
                  const isDisabled = DISABLED_GOALS.includes(item.content);
                  return (
                    <tr key={index} className={isDisabled ? 'disabled-goal' : ''}>
                      <td>{item.content}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedGoals.includes(item.content)}
                          onChange={() => handleGoalSelection(item.content)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button onClick={handleTakeAction} className="take-action-button">Take Action</button>
          </div>
        </div>
        <Chatbot 
          spendData={spendData} 
          income={income} 
          categorizedSpending={categorizedSpending}
        />
      </div>
    </>
  );
}

export default SpendAnalysisSummary;
