import React, { useState, useEffect } from 'react';
import '../css/ShortTermSavings.css'; // Ensure this is included
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

const ShortTermSavings = ({ onShortTermSavingsUpdate, onBudgetDataChange, onSave, monthYear, nextMonthYear }) => {
  const [goals, setGoals] = useState([]);
  const [pausedGoals, setPausedGoals] = useState([]); // State for paused goals
  const [abandonedGoals, setAbandonedGoals] = useState([]); // State for abandoned goals
  const [completedGoals, setCompletedGoals] = useState([]); // State for completed goals
  const [newGoal, setNewGoal] = useState({ name: '', amount: '', timeframe: '', currentSavings: '', priority: 'Medium' });
  const [isEditing, setIsEditing] = useState(null); // Track which row is being edited
  const [totalContribution, setTotalContribution] = useState(0); // State to hold total contributions

  // State to manage which dropdown is open
  const [openDropdown, setOpenDropdown] = useState(null); // null means no dropdown is open

  const [successMessage, setSuccessMessage] = useState('');

  // Load goals, paused goals, abandoned goals, and completed goals from localStorage when the component mounts
  useEffect(() => {
    if (monthYear && nextMonthYear) {
      globalMonthYear = monthYear;
      globalNextMonthYear = nextMonthYear;

      console.log('globalMonthYear:', globalMonthYear);
      console.log('globalNextMonthYear:', globalNextMonthYear);
    } else {
      console.log('ShortTermSavings - monthYear or nextMonthYear not received in props');
    }
  }, [monthYear, nextMonthYear]);

  useEffect(() => {
    console.log('Initial mount - monthYear:', monthYear);
    console.log('Initial mount - nextMonthYear:', nextMonthYear);
    console.log('Initial mount - localStorage data:', getFromLocalStorage('shortTermSavings', null, globalNextMonthYear));

    // Set global variables
    if (monthYear && nextMonthYear) {
      globalMonthYear = monthYear;
      globalNextMonthYear = nextMonthYear;

      // Store in localStorage
      localStorage.setItem('monthYear', monthYear);
      localStorage.setItem('nextMonthYear', nextMonthYear);

      console.log('ShortTermSavings - Set global variables and localStorage:');
      console.log('globalMonthYear:', globalMonthYear);
      console.log('globalNextMonthYear:', globalNextMonthYear);
    } else {
      console.log('ShortTermSavings - monthYear or nextMonthYear not received in props');
      // If not in props, try to get from localStorage
      const storedMonthYear = localStorage.getItem('monthYear');
      const storedNextMonthYear = localStorage.getItem('nextMonthYear');
      
      if (storedMonthYear && storedNextMonthYear) {
        globalMonthYear = storedMonthYear;
        globalNextMonthYear = storedNextMonthYear;
        console.log('Retrieved from localStorage - globalMonthYear:', globalMonthYear);
        console.log('Retrieved from localStorage - globalNextMonthYear:', globalNextMonthYear);
      }
    }

    try {
      console.log('Attempting to load goals from storage...');
      // First try to load from next month
      const nextMonthGoals = getFromLocalStorage('shortTermSavings', 'shortTermGoals', globalNextMonthYear);
      console.log('Next month goals:', nextMonthGoals);

      // Check if next month data exists (using Array.isArray to properly check)
      if (Array.isArray(nextMonthGoals) && nextMonthGoals.length > 0) {
        console.log('Found goals in next month, using those');
        setGoals(nextMonthGoals);
        // Load other next month data...
        const nextMonthPausedGoals = getFromLocalStorage('shortTermSavings', 'pausedGoals', globalNextMonthYear);
        const nextMonthAbandonedGoals = getFromLocalStorage('shortTermSavings', 'abandonedGoals', globalNextMonthYear);
        const nextMonthCompletedGoals = getFromLocalStorage('shortTermSavings', 'completedGoals', globalNextMonthYear);
        const nextMonthNewGoal = getFromLocalStorage('shortTermSavings', 'newGoalForm', globalNextMonthYear);

        setPausedGoals(Array.isArray(nextMonthPausedGoals) ? nextMonthPausedGoals : []);
        setAbandonedGoals(Array.isArray(nextMonthAbandonedGoals) ? nextMonthAbandonedGoals : []);
        setCompletedGoals(Array.isArray(nextMonthCompletedGoals) ? nextMonthCompletedGoals : []);
        if (nextMonthNewGoal) {
          setNewGoal(nextMonthNewGoal);
        }
      } else {
        console.log('No goals found in next month, falling back to current month');
        // Fall back to current month if no next month data
        const storedGoals = getFromLocalStorage('shortTermSavings', 'shortTermGoals', globalMonthYear);
        console.log('Current month goals:', storedGoals);
        
        setGoals(Array.isArray(storedGoals) ? storedGoals : []);
        // Load other current month data...
        const storedPausedGoals = getFromLocalStorage('shortTermSavings', 'pausedGoals', globalMonthYear);
        const storedAbandonedGoals = getFromLocalStorage('shortTermSavings', 'abandonedGoals', globalMonthYear);
        const storedCompletedGoals = getFromLocalStorage('shortTermSavings', 'completedGoals', globalMonthYear);
        const storedNewGoal = getFromLocalStorage('shortTermSavings', 'newGoalForm', globalMonthYear);

        setPausedGoals(Array.isArray(storedPausedGoals) ? storedPausedGoals : []);
        setAbandonedGoals(Array.isArray(storedAbandonedGoals) ? storedAbandonedGoals : []);
        setCompletedGoals(Array.isArray(storedCompletedGoals) ? storedCompletedGoals : []);
        if (storedNewGoal) {
          setNewGoal(storedNewGoal);
        }
      }

      calculateTotalContributions();
    } catch (error) {
      console.error('Error loading goals from storage:', error);
      setGoals([]);
      setPausedGoals([]);
      setAbandonedGoals([]);
      setCompletedGoals([]);
    }
  }, []); // Empty dependency array to run on mount

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedNewGoal = { ...newGoal, [name]: value };
    setNewGoal(updatedNewGoal);
    updateLocalStorage('shortTermSavings', {'newGoalForm': updatedNewGoal}, globalNextMonthYear);
  };

  const handleContributionChange = (e, index) => {
    const updatedGoals = [...goals];
    updatedGoals[index].monthlyContribution = e.target.value;
    setGoals(updatedGoals);
    updateLocalStorage('shortTermSavings', {'shortTermGoals': updatedGoals}, globalNextMonthYear);
    calculateTotalContributions();
    handleSaveDetails();
  };

  const handlePriorityChange = (e, index) => {
    const updatedGoals = [...goals];
    updatedGoals[index].priority = e.target.value;
    setGoals(updatedGoals);
    updateLocalStorage('shortTermSavings', {'shortTermGoals': updatedGoals}, globalNextMonthYear);
    handleSaveDetails();
  };

  const addGoal = (e) => {
    e.preventDefault();
    if (newGoal.name && newGoal.amount && newGoal.timeframe) {
      const monthlyContribution = calculateMonthlyContribution(newGoal.amount, newGoal.currentSavings, newGoal.timeframe);
      const goalWithDate = {
        ...newGoal,
        monthlyContribution,
        paused: false,
        priority: 'Medium',
        targetDate: new Date(Date.now() + (parseInt(newGoal.timeframe) * 30 * 24 * 60 * 60 * 1000))
          .toLocaleDateString('en-IN', { year: 'numeric', month: 'short' })
      };
      
      const updatedGoals = [...goals, goalWithDate];
      setGoals(updatedGoals);
      
      // Save only to next month's storage
      updateLocalStorage('shortTermSavings', {'shortTermGoals': updatedGoals}, globalNextMonthYear);
      
      // Clear form and remove saved form data
      const emptyGoal = { name: '', amount: '', timeframe: '', currentSavings: '', priority: 'Medium' };
      setNewGoal(emptyGoal);
      updateLocalStorage('shortTermSavings', {'newGoalForm': emptyGoal}, globalNextMonthYear);
      
      calculateTotalContributions();
      handleSaveDetails();
    }
  };

  const calculateMonthlyContribution = (amount, currentSavings, timeframe) => {
    const totalGoal = parseFloat(amount) - parseFloat(currentSavings);
    return totalGoal > 0 && parseInt(timeframe) > 0 ? (totalGoal / parseInt(timeframe)).toFixed(2) : 0;
  };

  const calculateTotalContributions = () => {
    // If there are no goals, set total contribution to 0
    if (goals.length === 0) {
      setTotalContribution(0);
      onShortTermSavingsUpdate(0); // Notify the Aggregator component about the updated total
      return;
    }

    const total = goals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const pausedTotal = pausedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const abandonedTotal = abandonedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const finalTotal = total - pausedTotal - abandonedTotal; // Include paused and abandoned contributions
    setTotalContribution(finalTotal.toFixed(2)); // Set total contributions
    onShortTermSavingsUpdate(finalTotal); // Notify the Aggregator component about the updated total
  };

  const handleDeleteGoal = (index) => {
    const goalToAbandon = { ...goals[index], monthlyContribution: 0 }; // Set monthly contribution to 0
    const updatedGoals = goals.filter((_, i) => i !== index);
    const updatedAbandonedGoals = [...abandonedGoals, goalToAbandon];
    
    // Update state
    setGoals(updatedGoals);
    setAbandonedGoals(updatedAbandonedGoals);

    // Calculate the new total after deletion
    const newTotal = updatedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const pausedTotal = pausedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const abandonedTotal = abandonedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const finalTotal = newTotal - pausedTotal - abandonedTotal;

    // Update the totals in parent components
    onShortTermSavingsUpdate(finalTotal);
    onBudgetDataChange('Short-term Savings', finalTotal);

    // Save to localStorage - Fix: Don't stringify the values as updateLocalStorage handles that
    updateLocalStorage('shortTermSavings', {
      'shortTermGoals': updatedGoals,
      'abandonedGoals': updatedAbandonedGoals
    }, globalNextMonthYear);

    // Mark as saved
    if (onSave) {
      onSave();
    }
  };

  const handleSaveDetails = () => {
    console.log('Before save - localStorage data:', getFromLocalStorage('shortTermSavings', null, globalNextMonthYear));
    
    // Get existing data first
    const existingData = getFromLocalStorage('shortTermSavings', null, globalNextMonthYear) || {};
    
    // Always use all categories from existing data
    const updatedData = {
        ...existingData,
        shortTermGoals: existingData.shortTermGoals || [],
        pausedGoals: existingData.pausedGoals || [],
        abandonedGoals: existingData.abandonedGoals || [],
        completedGoals: existingData.completedGoals || []
    };

    // Save merged data
    updateLocalStorage('shortTermSavings', updatedData, globalNextMonthYear);
    
    // Update all goal states to stay in sync with localStorage
    setGoals(existingData.shortTermGoals || []);
    setPausedGoals(existingData.pausedGoals || []);
    setAbandonedGoals(existingData.abandonedGoals || []);
    setCompletedGoals(existingData.completedGoals || []);
    
    console.log('After save - localStorage data:', getFromLocalStorage('shortTermSavings', null, globalNextMonthYear));

    // Update budget data using shortTermGoals from localStorage
    const totalMonthlyContribution = (existingData.shortTermGoals || []).reduce((total, goal) => {
        return total + (parseFloat(goal.monthlyContribution) || 0);
    }, 0);

    const currentBudgetData = getFromLocalStorage('budget', globalMonthYear) || {};
    const updatedBudgetData = {
        ...currentBudgetData,
        'Short-term Savings': totalMonthlyContribution
    };
    updateLocalStorage('budget', updatedBudgetData, globalNextMonthYear);

    // Update parent components
    if (onBudgetDataChange) {
        onBudgetDataChange('Short-term Savings', totalMonthlyContribution);
    }
    if (onSave) {
        onSave();
    }
  };

  const handlePauseGoal = (index) => {
    const updatedGoals = [...goals];
    const goalToPause = updatedGoals[index];
    goalToPause.paused = true;
    goalToPause.monthlyContribution = 0;

    const updatedPausedGoals = [...pausedGoals, goalToPause];
    const remainingGoals = updatedGoals.filter((_, i) => i !== index);

    setPausedGoals(updatedPausedGoals);
    setGoals(remainingGoals);
   
    updateLocalStorage('shortTermSavings', {
      'shortTermGoals': remainingGoals,
      'pausedGoals': updatedPausedGoals
    }, globalNextMonthYear);

    calculateTotalContributions();
    handleSaveDetails();
  };

  const handleResumeGoal = (index) => {
    const updatedPausedGoals = [...pausedGoals];
    const goalToResume = updatedPausedGoals[index];
    goalToResume.paused = false;

    const updatedGoals = [...goals, goalToResume];
    const remainingPausedGoals = updatedPausedGoals.filter((_, i) => i !== index);

    setGoals(updatedGoals);
    setPausedGoals(remainingPausedGoals);

    updateLocalStorage('shortTermSavings', {
      'shortTermGoals': updatedGoals,
      'pausedGoals': remainingPausedGoals
    }, globalNextMonthYear);

    calculateTotalContributions();
    handleSaveDetails();
  };

  // Function to calculate progress percentage
  const calculateProgress = (goal) => {
    const totalAmount = parseFloat(goal.amount) || 0;
    const currentSavings = parseFloat(goal.currentSavings) || 0;
    return totalAmount > 0 ? (currentSavings / totalAmount) * 100 : 0;
  };

  // Move completed goals to the completed section
  const checkCompletedGoals = () => {
    const newCompletedGoals = goals.filter(goal => calculateProgress(goal) >= 100);
    const updatedGoals = goals.filter(goal => calculateProgress(goal) < 100);
    
    if (newCompletedGoals.length > 0) {
      setCompletedGoals(prev => [...prev, ...newCompletedGoals]);
      setGoals(updatedGoals);
      updateLocalStorage('shortTermSavings', {
        'completedGoals': [...completedGoals, ...newCompletedGoals],
        'shortTermGoals': updatedGoals
      }, globalNextMonthYear);

      // Set success message for each completed goal
      const messages = newCompletedGoals.map(goal => 
        `Congratulations! You've successfully achieved your goal: ${goal.name} (₹${goal.amount})`
      );
      setSuccessMessage(messages.join('\n'));

      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);

      handleSaveDetails();
    }
  };

  // Call checkCompletedGoals whenever goals change
  useEffect(() => {
    checkCompletedGoals();
  }, [goals]);

  // Function to toggle dropdowns
  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  useEffect(() => {
    // Get current month goals
    const currentMonthGoals = getFromLocalStorage('shortTermSavings', 'shortTermGoals', globalMonthYear) || [];
    console.log('Current month goals:', currentMonthGoals);

    // Get expenses from SpendAnalyzer
    const spendAnalyzerExpenses = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear) || [];

    // Filter expenses with categoryMapping 'Short-term Savings'
    const shortTermSavingsExpenses = spendAnalyzerExpenses.filter(expense => 
      expense.categoryMapping === 'Short-term Savings'
    );
    console.log('Short Term Savings expenses:', shortTermSavingsExpenses);

    // Create a map to store expense totals by category
    const expenseTotalsByCategory = new Map();
    shortTermSavingsExpenses.forEach(expense => {
      const category = expense.category;
      const amount = parseFloat(expense.amount || 0);
      expenseTotalsByCategory.set(
        category, 
        (expenseTotalsByCategory.get(category) || 0) + amount
      );
    });
    console.log('Expense totals by category:', expenseTotalsByCategory);

    // Create a map of goals with their original values
    const updatedGoals = currentMonthGoals.map(goal => {
      const expenseTotal = expenseTotalsByCategory.get(goal.name) || 0;
      
      // Get the stored current savings from localStorage for this goal
      const storedGoals = getFromLocalStorage('shortTermSavings', 'shortTermGoals', globalMonthYear) || [];
      console.log('Goal:', goal.name, 'Stored goals:', storedGoals);
      const storedGoal = storedGoals.find(g => g.name === goal.name);
      const storedSavings = storedGoal ? parseFloat(storedGoal.currentSavings || 0) : 0;
      
      console.log('Goal:', goal.name, 'Expense total:', expenseTotal, 'Stored savings:', storedSavings);

      // Add expense total to stored savings
      const updatedSavings = storedSavings + expenseTotal;
      console.log('Goal:', goal.name, 'Updated savings:', updatedSavings);

      return {
        ...goal,
        currentSavings: updatedSavings.toString()
      };
    });

    // Handle any unmatched expenses by creating new goals
    expenseTotalsByCategory.forEach((totalAmount, category) => {
      if (!currentMonthGoals.some(goal => goal.name === category)) {
        updatedGoals.push({
          name: category,
          amount: totalAmount.toString(),
          currentSavings: totalAmount.toString(),
          timeframe: '1',
          monthlyContribution: totalAmount.toString(),
          priority: 'Medium',
          targetDate: new Date().toLocaleDateString('en-IN', { year: 'numeric', month: 'short' })
        });
      }
    });

    // Separate completed and active goals
    const completedGoals = updatedGoals.filter(goal => {
      const totalAmount = parseFloat(goal.amount || 0);
      const currentSavings = parseFloat(goal.currentSavings || 0);
      return currentSavings >= totalAmount;
    });

    const activeGoals = updatedGoals.filter(goal => {
      const totalAmount = parseFloat(goal.amount || 0);
      const currentSavings = parseFloat(goal.currentSavings || 0);
      return currentSavings < totalAmount;
    });

    // Update state
    setGoals(activeGoals);
    setCompletedGoals(completedGoals);
    
    // Show success message for newly completed goals
    if (completedGoals.length > 0) {
      const messages = completedGoals.map(goal => 
        `Congratulations! You've successfully achieved your goal: ${goal.name} (₹${goal.amount})`
      );
      setSuccessMessage(messages.join('\n'));
      setTimeout(() => setSuccessMessage(''), 5000);
    }

    // Update total contributions
    calculateTotalContributions();
    handleSaveDetails();
  }, []);



  return (
    <div className="short-term-savings">
      <h2>Short-Term Savings Goals</h2>
      
      {/* Success Message */}
      {successMessage && (
        <div className="success-message" style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '15px',
          marginBottom: '20px',
          borderRadius: '5px',
          textAlign: 'center',
          whiteSpace: 'pre-line'
        }}>
          {successMessage}
        </div>
      )}

      {/* Completed Goals Section - Move to top */}
      <div className="goals-dropdown-section completed-goals">
        <div className={`goals-dropdown-content ${openDropdown === 'completed' ? 'open' : ''}`}>
          {completedGoals.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Goal Name</th>
                  <th>Amount Saved</th>
                  <th>Time Taken (months)</th>
                  <th>Completion Date</th>
                </tr>
              </thead>
              <tbody>
                {completedGoals.map((goal, index) => (
                  <tr key={index}>
                    <td>{goal.name}</td>
                    <td>₹{goal.amount}</td>
                    <td>{goal.timeframe}</td>
                    <td>{new Date().toLocaleDateString('en-IN', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="goals-empty-state">No completed goals yet</div>
          )}
        </div>
      </div>

      <form onSubmit={addGoal}>
        <div className="input-row">
          <input
            type="text"
            name="name"
            value={newGoal.name}
            onChange={handleInputChange}
            placeholder="Goal name"
            required
          />
          <input
            type="number"
            name="amount"
            value={newGoal.amount}
            onChange={handleInputChange}
            placeholder="Amount to save"
            required
          />
          <input
            type="number"
            name="timeframe"
            value={newGoal.timeframe}
            onChange={handleInputChange}
            placeholder="Timeframe (months)"
            required
          />
          <input
            type="number"
            name="currentSavings"
            value={newGoal.currentSavings}
            onChange={handleInputChange}
            placeholder="Current savings"
            required
          />
          <button type="submit">Add Goal</button>
        </div>
      </form>
      {/* Main Goals Table */}
      <table>
        <thead>
          <tr>
            <th>Goal Name</th>
            <th>Amount to Save</th>
            <th>Timeframe (months)</th>
            <th>Current Savings</th>
            <th>Progress</th>
            <th>Priority</th>
            <th>Monthly Contribution</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {goals.map((goal, index) => (
            <tr key={index}>
              <td>{goal.name}</td>
              <td>₹{goal.amount}</td>
              <td>{goal.timeframe}</td>
              <td>₹{goal.currentSavings}</td>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px', marginRight: '10px' }}>
                    <div 
                      style={{
                        width: `${calculateProgress(goal)}%`,
                        backgroundColor: '#76c7c0',
                        height: '10px',
                        borderRadius: '5px'
                      }}
                    />
                  </div>
                  <span>{calculateProgress(goal).toFixed(0)}%</span>
                </div>
              </td>
              <td>
                <select 
                  value={goal.priority} 
                  onChange={(e) => handlePriorityChange(e, index)} 
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </td>
              <td
                className="editable-cell"
                onClick={() => setIsEditing(index)}
                title="Click to edit"
              >
                {isEditing === index ? (
                  <input
                    type="number"
                    value={goal.monthlyContribution}
                    onChange={(e) => handleContributionChange(e, index)}
                    onBlur={() => setIsEditing(null)}
                    autoFocus
                  />
                ) : (
                  <>
                    <span>₹{goal.monthlyContribution}</span>
                    <i className="edit-icon" />
                  </>
                )}
              </td>
              <td>
                <span 
                  className="pause-icon" 
                  title={goal.paused ? "Resume" : "Pause"} 
                  onClick={() => handlePauseGoal(index)} 
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                >
                  {goal.paused ? '▶️' : '⏸️'}
                </span>
                <span 
                  className="delete-icon" 
                  title="Abandon" 
                  onClick={() => handleDeleteGoal(index)} 
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                >
                  ❌
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Button to save details and calculate total contributions */}
      <div className="save-details">
        <button onClick={handleSaveDetails}>Save Details</button>
      </div>
      {/* Dropdown for Completed Goals */}
      <div className="goals-dropdown-section completed-goals">
        <div className="goals-dropdown-header" onClick={() => toggleDropdown('completed')}>
          <h3 className="goals-dropdown-title">
            Completed Goals
            <span className={`dropdown-arrow ${openDropdown === 'completed' ? 'open' : ''}`}>
              {openDropdown === 'completed' ? '▲' : '▼'}
            </span>
          </h3>
        </div>
        <div className={`goals-dropdown-content ${openDropdown === 'completed' ? 'open' : ''}`}>
          {completedGoals.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Goal Name</th>
                  <th>Amount to Save</th>
                  <th>Timeframe (months)</th>
                  <th>Current Savings</th>
                </tr>
              </thead>
              <tbody>
                {completedGoals.map((goal, index) => (
                  <tr key={index}>
                    <td>{goal.name}</td>
                    <td>₹{goal.amount}</td>
                    <td>{goal.timeframe}</td>
                    <td>₹{goal.currentSavings}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="goals-empty-state">No completed goals yet</div>
          )}
        </div>
      </div>

      {/* Dropdown for Paused Goals */}
      <div className="goals-dropdown-section paused-goals">
        <div className="goals-dropdown-header" onClick={() => toggleDropdown('paused')}>
          <h3 className="goals-dropdown-title">
            Paused Goals
            <span className={`dropdown-arrow ${openDropdown === 'paused' ? 'open' : ''}`}>
              {openDropdown === 'paused' ? '▲' : '▼'}
            </span>
          </h3>
        </div>
        <div className={`goals-dropdown-content ${openDropdown === 'paused' ? 'open' : ''}`}>
          {pausedGoals.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Goal Name</th>
                  <th>Amount to Save</th>
                  <th>Timeframe (months)</th>
                  <th>Current Savings</th>
                  <th>Monthly Contribution</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {pausedGoals.map((goal, index) => (
                  <tr key={index}>
                    <td>{goal.name}</td>
                    <td>₹{goal.amount}</td>
                    <td>{goal.timeframe}</td>
                    <td>₹{goal.currentSavings}</td>
                    <td>₹{goal.monthlyContribution}</td>
                    <td>
                      <span 
                        className="resume-icon" 
                        title="Resume" 
                        onClick={() => handleResumeGoal(index)} 
                        style={{ cursor: 'pointer' }}
                      >
                        ▶️
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="goals-empty-state">No paused goals</div>
          )}
        </div>
      </div>

      {/* Dropdown for Abandoned Goals */}
      <div className="goals-dropdown-section abandoned-goals">
        <div className="goals-dropdown-header" onClick={() => toggleDropdown('abandoned')}>
          <h3 className="goals-dropdown-title">
            Abandoned Goals
            <span className={`dropdown-arrow ${openDropdown === 'abandoned' ? 'open' : ''}`}>
              {openDropdown === 'abandoned' ? '▲' : '▼'}
            </span>
          </h3>
        </div>
        <div className={`goals-dropdown-content ${openDropdown === 'abandoned' ? 'open' : ''}`}>
          {abandonedGoals.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Goal Name</th>
                  <th>Amount to Save</th>
                  <th>Timeframe (months)</th>
                  <th>Current Savings</th>
                </tr>
              </thead>
              <tbody>
                {abandonedGoals.map((goal, index) => (
                  <tr key={index}>
                    <td>{goal.name}</td>
                    <td>₹{goal.amount}</td>
                    <td>{goal.timeframe}</td>
                    <td>₹{goal.currentSavings}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="goals-empty-state">No abandoned goals</div>
          )}
        </div>
      </div>

      
    </div>
  );
};

// Function to get the background color based on priority
const getPriorityColor = (priority) => {
  switch (priority) {
    case 'High':
      return 'red'; // Red for high priority
    case 'Medium':
      return 'yellow'; // Yellow for medium priority
    case 'Low':
      return 'green'; // Green for low priority
    default:
      return 'white'; // Default color
  }
};

export default ShortTermSavings;