import React, { useState, useEffect } from 'react';
import ShortTermSavings from './ShortTermSavings';
import RepayDebts from './RepayDebts';
import EmergencyFund from './EmergencyFund';
import LongTermSavings from './LongTermSavings';
import Insurance from './Insurance';
import Retirement from './Retirement';
import BudgetSummary from './BudgetSummary';
import '../css/Aggregator.css';
import ExpenseTracker from './ExpenseTracker';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';
import Conclusions from './Conclusions';

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

const goalComponents = {
  'Short-term Savings': ShortTermSavings,
  'Debt Reduction': RepayDebts,
  'Emergency Fund': EmergencyFund,
  'Long-term Savings': LongTermSavings,
  'Insurance': Insurance,
  'Retirement Fund': Retirement,
  'Expense Reduction': ExpenseTracker,
};

function Aggregator() {
  const location = useLocation();
  const navigate = useNavigate();
  const { monthYear: locationMonthYear, nextMonthYear: locationNextMonthYear } = location.state || {};
  
  // Initialize month-year values from multiple sources
  useEffect(() => {
    try {
      // First try from location state
      if (locationMonthYear && locationNextMonthYear) {
        globalMonthYear = locationMonthYear;
        globalNextMonthYear = locationNextMonthYear;
        localStorage.setItem('globalMonthYear', globalMonthYear);
        localStorage.setItem('globalNextMonthYear', globalNextMonthYear);
      } else {
        // Try from localStorage
        const storedMonthYear = localStorage.getItem('globalMonthYear');
        const storedNextMonthYear = localStorage.getItem('globalNextMonthYear');
        
        if (storedMonthYear && storedNextMonthYear) {
          globalMonthYear = storedMonthYear;
          globalNextMonthYear = storedNextMonthYear;
        } else {
          // If no values found, redirect to SpendAnalyzer
          navigate('/spend-analyzer');
          return;
        }
      }

      console.log('Aggregator - Month-year values set:', {
        globalMonthYear,
        globalNextMonthYear
      });
    } catch (err) {
      setError('Failed to initialize month-year values: ' + err.message);
    }
  }, [locationMonthYear, locationNextMonthYear, navigate]);

  const [selectedGoals, setSelectedGoals] = useState([]);
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [budgetData, setBudgetData] = useState({
    Earnings: 0,
    Essentials: 0,
    Discretionary: 0,
    'Emergency Fund': 0,
    'Short Term Savings': 0,
    'Long Term Savings': 0,
    Debts: 0,
    Insurance: 0,
    Retirement: 0,
  });
  const [savedGoals, setSavedGoals] = useState([]);
  const [netIncome, setNetIncome] = useState(0);
  const [spendBreakdown, setSpendBreakdown] = useState(null);
  const [budgetSummary, setBudgetSummary] = useState(null);
  const [pyramidPriorities, setPyramidPriorities] = useState([]);

  const { section } = useParams();

  // Load selected goals and budget data from storage
  useEffect(() => {
    if (globalMonthYear) {
      // Load goals
      const savedGoalsData = getFromLocalStorage('spendAnalysisSummary', 'selectedGoals', globalMonthYear);
      if (Array.isArray(savedGoalsData) && savedGoalsData.length > 0) {
        setSelectedGoals(savedGoalsData);
        console.log('Loaded selected goals:', savedGoalsData);
        
        // If URL has a section parameter, set the corresponding goal index
        if (section) {
          const goalIndex = savedGoalsData.findIndex(goal => 
            goal.toLowerCase().replace(/\s+/g, '-') === section
          );
          if (goalIndex !== -1) {
            setCurrentGoalIndex(goalIndex);
          }
        }
      } else {
        // If no goals found, redirect to SpendAnalyzer
        navigate('/spend-analyzer');
        return;
      }

      // Load spend breakdown data
      const savedSpendBreakdown = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear);
      setSpendBreakdown(savedSpendBreakdown || null);

      // Initialize and load budget data
      const defaultBudgetData = {
        Earnings: 0,
        'Essentials': 0,
        'Discretionary': 0,
        'Emergency Fund': 0,
        'Short Term Savings': 0,
        'Long Term Savings': 0,
        Debts: 0,
        Insurance: 0,
        Retirement: 0,
      };

      // Load budget data from both storage locations and merge
      const savedBudgetData = getFromLocalStorage('spendAnalysisSummary', 'budgetData', globalMonthYear) || {};
      const savedBudgetSummary = getFromLocalStorage('budget', 'budgetData', globalNextMonthYear) || {};

      // Merge data, preferring the most recent values
      const mergedBudgetData = {
        ...defaultBudgetData,
        ...savedBudgetData,
        ...savedBudgetSummary,
      };

      // Ensure Discretionary and Essentials are properly loaded
      if (savedBudgetSummary.Discretionary || savedBudgetSummary.Essentials) {
        mergedBudgetData.Discretionary = savedBudgetSummary.Discretionary;
        mergedBudgetData.Essentials = savedBudgetSummary.Essentials;
      }

      setBudgetData(mergedBudgetData);

      // Load saved goals progress
      const savedGoalsProgress = getFromLocalStorage('spendAnalysisSummary', 'selectedGoals', globalMonthYear) || [];
      setSavedGoals(Array.isArray(savedGoalsProgress) ? savedGoalsProgress : []);

      // Get and set monthly income
      const monthlyIncome = getFromLocalStorage('spendAnalyzer', 'monthlyIncome', globalMonthYear);
      if (monthlyIncome) {
        setBudgetData(prevData => ({ ...prevData, Earnings: parseFloat(monthlyIncome) }));
      }

      console.log('Loaded budget data:', mergedBudgetData);
    }
  }, [globalMonthYear, globalNextMonthYear, section, navigate]);

  useEffect(() => {
    const newProgress = ((currentGoalIndex + 1) / selectedGoals.length) * 100;
    setProgress(newProgress);
  }, [currentGoalIndex, selectedGoals.length]);

  const handleNext = () => {
    if (currentGoalIndex < selectedGoals.length - 1) {
      setCurrentGoalIndex(currentGoalIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentGoalIndex > 0) {
      setCurrentGoalIndex(currentGoalIndex - 1);
    }
  };

  const normalizeCategory = (category) => {
    switch(category) {
      case 'Long-term Savings':
      case 'Long Term Savings':
        return 'Long Term Savings';
      case 'Short-term Savings':
      case 'Short Term Savings':
        return 'Short Term Savings';
      case 'Retirement Fund':
      case 'Retirement':
        return 'Retirement';
      default:
        return category;
    }
  };

  const handleBudgetDataChange = (category, value) => {
    console.log('Aggregator - Before Update:', {
        currentBudgetData: budgetData,
        newCategory: category,
        newValue: value
    });
    
    const normalizedCategory = normalizeCategory(category);
    const numericValue = parseFloat(value) || 0;
    const updatedBudgetData = { ...budgetData, [normalizedCategory]: numericValue };
    
    console.log('Aggregator - After Update:', {
        updatedBudgetData,
        category: normalizedCategory,
        numericValue
    });
    
    setBudgetData(updatedBudgetData);

    const storageData = {
      budgetData: updatedBudgetData,
      summary: updatedBudgetData
    };

    try {
      updateLocalStorage('budget', storageData, globalNextMonthYear);
      console.log('Successfully updated budget data:', { category: normalizedCategory, value: numericValue });
    } catch (error) {
      console.error('Failed to update budget data:', error);
    }
  };

  // New function to handle multiple budget updates
  const handleBudgetDataBatchChange = (updates) => {
    console.log('Budget data batch update:', updates);
    const updatedBudgetData = { ...budgetData };
    
    // Apply all updates with normalized categories
    Object.entries(updates).forEach(([category, value]) => {
      const normalizedCategory = normalizeCategory(category);
      updatedBudgetData[normalizedCategory] = parseFloat(value) || 0;
    });

    // Update state once
    setBudgetData(updatedBudgetData);

    // Save to storage once
    const storageData = {
      budgetData: updatedBudgetData,
      summary: updatedBudgetData
    };

    try {
      updateLocalStorage('budget', storageData, globalNextMonthYear);
      console.log('Successfully updated budget data:', updatedBudgetData);
    } catch (error) {
      console.error('Failed to update budget data:', error);
    }
  };

  const handleSaveGoal = () => {
    const updatedSavedGoals = [...(Array.isArray(savedGoals) ? savedGoals : [])];
    if (!updatedSavedGoals.includes(selectedGoals[currentGoalIndex])) {
      updatedSavedGoals.push(selectedGoals[currentGoalIndex]);
    }
    setSavedGoals(updatedSavedGoals);
  };

  const handleEditGoal = () => {
    const updatedSavedGoals = (Array.isArray(savedGoals) ? savedGoals : [])
      .filter(goal => goal !== selectedGoals[currentGoalIndex]);
    setSavedGoals(updatedSavedGoals);
  };

  const isCurrentGoalSaved = savedGoals.includes(selectedGoals[currentGoalIndex]);

  const CurrentGoalComponent = goalComponents[selectedGoals[currentGoalIndex]];

  const calculateTotalExpenses = () => {
    return Object.entries(budgetData)
      .filter(([key]) => key !== 'Earnings')
      .reduce((total, [_, value]) => total + value, 0);
  };

  const calculateBalance = () => {
    return budgetData.Earnings - calculateTotalExpenses();
  };

  const balance = calculateBalance();
  const isNegativeBalance = balance < 0;

  const handleShortTermSavingsUpdate = (total) => {
    setBudgetData((prevData) => ({
      ...prevData,
      'Short Term Savings': total, // Update the Short Term Savings value
    }));
  };

  const handleNetIncomeChange = (value) => {
    setNetIncome(value);
  };

  const handleFinish = () => {
    // Get the formatted budget data and spend breakdown
    const budgetSummary = getFromLocalStorage('budget', 'summary', globalNextMonthYear);
    const spendBreakdown = getFromLocalStorage('spendAnalysisSummary', 'spendBreakdown', globalMonthYear);
    
    if (!budgetSummary) {
      console.error('No budget summary data available');
      return;
    }

    console.log('Sending budget summary to Conclusions:', budgetSummary);
    console.log('Sending spend breakdown to Conclusions:', spendBreakdown);

    // Navigate to conclusions with both sets of data
    navigate('/conclusions', { 
      state: { 
        budgetSummary,
        spendBreakdown,
        globalMonthYear,
        globalNextMonthYear
      }
    });
  };

  useEffect(() => {
    const aggregatorContainer = document.querySelector('.aggregator-container');
    const mainContent = document.querySelector('.aggregator-main-content');
    const pageContainer = document.querySelector('.aggregator-page-container');
    const contentContainer = document.querySelector('.aggregator-content-container');
    const goalContent = document.querySelector('.goal-content');

    console.log('Container Widths:');
    console.log('- aggregator-container:', aggregatorContainer?.offsetWidth);
    console.log('- main-content:', mainContent?.offsetWidth);
    console.log('- page-container:', pageContainer?.offsetWidth);
    console.log('- content-container:', contentContainer?.offsetWidth);
    console.log('- goal-content:', goalContent?.offsetWidth);
  }, [currentGoalIndex]); // Log whenever the goal changes

  // Update URL when changing goals
  const handleGoalChange = (index) => {
    if (selectedGoals.length === 0) return;
    
    setCurrentGoalIndex(index);
    const goalSlug = selectedGoals[index]
      .toLowerCase()
      .replace(/\s+/g, '-');
    navigate(`/aggregator/${goalSlug}`, { 
      replace: true,
      state: { 
        monthYear: globalMonthYear,
        nextMonthYear: globalNextMonthYear
      }
    });
  };

  // Update your existing click handler
  const handleOptionClick = (index) => {
    handleGoalChange(index);
  };

  useEffect(() => {
    // Load budget data
    const budgetData = getFromLocalStorage('budget', 'budgetData', globalNextMonthYear);
    if (budgetData) {
      setBudgetSummary(budgetData);
    }

    // Load spend breakdown
    const spendData = getFromLocalStorage('spendAnalyzer', 'breakdown', globalMonthYear);
    if (spendData) {
      setSpendBreakdown(spendData);
    }

    // Load pyramid priorities
    const pyramidData = getFromLocalStorage('pyramid', 'priorities', globalMonthYear);
    if (pyramidData) {
      setPyramidPriorities(pyramidData.priorities || []);
    }
  }, []);

  // Early return if no goals are loaded yet
  if (selectedGoals.length === 0) {
    return (
      <div className="loading-container" style={{ 
        padding: '20px', 
        textAlign: 'center',
        color: '#004085',
        backgroundColor: '#cce5ff',
        border: '1px solid #b8daff',
        borderRadius: '4px',
        margin: '20px'
      }}>
        <h2>Loading Goals...</h2>
        <p>Please wait while we fetch your data.</p>
      </div>
    );
  }

  // Early return if there's an error
  if (error) {
    return (
      <div className="error-container" style={{ 
        padding: '20px', 
        textAlign: 'center',
        color: '#721c24',
        backgroundColor: '#f8d7da',
        border: '1px solid #f5c6cb',
        borderRadius: '4px',
        margin: '20px'
      }}>
        <h2>Error Loading Component</h2>
        <p>{error}</p>
        <button 
          onClick={() => {
            setError(null);
            window.location.reload();
          }}
          style={{
            padding: '10px 20px',
            marginTop: '10px',
            backgroundColor: '#dc3545',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="aggregator-container">
      <div className="aggregator-main-content">
        <h2>Your Financial Journey</h2>
        <div className="progress-section">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
          <div className="progress-text">
            {selectedGoals[currentGoalIndex] ? 
              `Goal ${currentGoalIndex + 1} of ${selectedGoals.length}: ${selectedGoals[currentGoalIndex]}` :
              'Loading goals...'
            }
          </div>
        </div>
        <div className="goal-navigation">
          {selectedGoals.map((goal, index) => (
            <button
              key={index}
              className={`goal-nav-button ${index === currentGoalIndex ? 'active' : ''}`}
              onClick={() => handleGoalChange(index)}
            >
              {goal}
            </button>
          ))}
        </div>
        <div className="aggregator-page-container">
          <div className="aggregator-content-container">
            <div className="goal-content">
              {selectedGoals.length > 0 && CurrentGoalComponent && (
                <div className="goal-component">
                  <CurrentGoalComponent
                    budgetData={budgetData || {}}
                    onBudgetDataChange={handleBudgetDataChange}
                    onBudgetDataBatchChange={handleBudgetDataBatchChange}
                    onSave={handleSaveGoal}
                    onEdit={handleEditGoal}
                    isSaved={isCurrentGoalSaved}
                    onShortTermSavingsUpdate={handleShortTermSavingsUpdate}
                    monthYear={globalMonthYear}
                    nextMonthYear={globalNextMonthYear}
                    planningData={{
                      planningMonths: [],
                      currentMonth: '',
                      targetMonth: ''
                    }}
                  />
                </div>
              )}
            </div>

            {/* Error message display */}
            {error && (
              <div className="error-message" style={{
                padding: '10px',
                margin: '10px',
                backgroundColor: '#ffebee',
                color: '#c62828',
                borderRadius: '4px'
              }}>
                <p>Error loading component: {error}</p>
                <button onClick={() => {
                  setError(null);
                  window.location.reload();
                }}>
                  Retry
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="navigation-buttons">
          <button onClick={handlePrevious} disabled={currentGoalIndex === 0}>Previous</button>
          <button onClick={handleNext} disabled={currentGoalIndex === selectedGoals.length - 1 || !savedGoals.includes(selectedGoals[currentGoalIndex])}>Next</button>
        </div>
      </div>
      <div className="budget-summary-container">
        <BudgetSummary 
          budgetData={budgetData} 
          onNetIncomeChange={handleNetIncomeChange}
          spendBreakdown={spendBreakdown}
          monthYear={globalMonthYear}
          nextMonthYear={globalNextMonthYear}
        />
        <div className={`sticky-note ${netIncome === 0 ? 'balanced' : 'unbalanced'}`}>
          <p className="sticky-note-text">
            {netIncome === 0 
              ? "Perfect! Your budget is balanced." 
              : netIncome > 0 
                ? `You have ₹${netIncome.toFixed(2)} unallocated. Consider adding it to your goals.`
                : `Warning: You're over budget by ₹${Math.abs(netIncome).toFixed(2)}, Make Adjustments to your goals`
            }
          </p>
        </div>
        <button 
          className="finish-button"
          onClick={handleFinish}
        >
          Finish
        </button>
      </div>
    </div>
  );
}

export default Aggregator;
