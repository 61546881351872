import React from 'react';
import { HashRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Header from './components/js/Header';
import Login from './components/js/Login';
import ForgotPassword from './components/js/ForgotPassword';
import Signup from './components/js/Signup';
import Priority from './components/js/Priority';
import Pyramid from './components/js/Pyramid';
import SpendAnalyzer from './components/js/SpendAnalyzer';
import SpendAnalysisSummary from './components/js/SpendAnalysisSummary';
import RepayDebts from './components/js/RepayDebts';
import ShortTermSavings from './components/js/ShortTermSavings';
import Aggregator from './components/js/Aggregator';
import FundPlan from './components/js/FundPlan';
import designerImage from './assets/Designer.jpeg';
import './App.css';
import Conclusions from './components/js/Conclusions';
import Dashboard from './components/js/Dashboard';
import UserProfile from './components/js/UserProfile';
import LongTermSavings from './components/js/LongTermSavings';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={
              <div className="home-content">
                <img src={designerImage} alt="Designer" className="designer-image" />
                <h2>Personalized Financial Advisor for Everyone</h2>
                <p>Powered by AI</p>
                <Link to="/login" className="cta-button">
                  Get Started
                </Link>
              </div>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/priority" element={<Priority />} />
            <Route path="/pyramid" element={<Pyramid />} />
            <Route path="/spend-analyzer" element={<SpendAnalyzer />} />
            <Route path="/spend-analysis-summary" element={<SpendAnalysisSummary />} />
            <Route path="/repay-debts" element={<RepayDebts />} />
            <Route path="/short-term-savings" element={<ShortTermSavings />} />
            <Route path="/long-term-savings" element={<LongTermSavings />} />
            <Route path="/aggregator" element={<Aggregator />} />
            <Route path="/aggregator/:section" element={<Aggregator />} />
            <Route path="/fundplan" element={<FundPlan />} />
            <Route path="/conclusions" element={
              <Conclusions 
                globalMonthYear={window.globalMonthYear} 
                globalNextMonthYear={window.globalNextMonthYear} 
              />
            } />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<UserProfile />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
