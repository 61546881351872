import React, { useState, useEffect } from 'react';
import FundPlan from './FundPlan'; // Import the FundPlan component
import BudgetSummary from './BudgetSummary'; // Import the BudgetSummary component
import '../css/EmergencyFund.css';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

function EmergencyFund({ onBudgetDataChange, onSave, monthYear, nextMonthYear }) { // Accept onBudgetDataChange as a prop
  const [categories, setCategories] = useState(['MonthlySpends']);
  const [amounts, setAmounts] = useState(['']);
  const [salary, setSalary] = useState('');
  const [existingFund, setExistingFund] = useState(0); // Default value set to 0
  const [error, setError] = useState('');
  const [isCalculated, setIsCalculated] = useState(false);
  const [selectedFund, setSelectedFund] = useState(null);
  const [congratulationsMessage, setCongratulationsMessage] = useState(false); // To track if the user has met all goals
  const [showFundPlan, setShowFundPlan] = useState(false); // State to control FundPlan visibility
  const [selectedOption, setSelectedOption] = useState(null); // State to hold selected option for FundPlan
  const [summaryData, setSummaryData] = useState(null);

  // Add useEffect to handle month-year values and update emergency fund data
  useEffect(() => {
    if (monthYear && nextMonthYear) {
      globalMonthYear = monthYear;
      globalNextMonthYear = nextMonthYear;

      // Update emergency fund amount from spend analyzer
      const expenses = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear) || [];
      const emergencyFundExpenses = expenses.filter(expense => expense.categoryMapping === 'Emergency Fund');
      const totalEmergencyFundAmount = emergencyFundExpenses.reduce((sum, expense) => sum + parseFloat(expense.amount || 0), 0);
      const existingFundFromStorage = parseFloat(getFromLocalStorage('emergencyFund', 'currentEmergencyFundAmount', globalMonthYear)) || 0;
      const calculatedFundAmount = existingFundFromStorage + totalEmergencyFundAmount;
      
      // Get existing emergency fund data
      const emergencyFundData = getFromLocalStorage('emergencyFund', null, globalMonthYear) || {};
      
      // Update emergency fund data for next month
      const updatedData = {
        ...emergencyFundData,
        currentEmergencyFundAmount: calculatedFundAmount,
        emergencyFundData: {
          ...emergencyFundData.emergencyFundData,
          timeToReachGoal: Math.max(0, (emergencyFundData.emergencyFundData?.timeToReachGoal || 0) - 1)
        }
      };
      
      // Save updated data
      updateLocalStorage('emergencyFund', updatedData, globalNextMonthYear);
      setExistingFund(calculatedFundAmount);

      console.log('globalMonthYear:', globalMonthYear);
      console.log('globalNextMonthYear:', globalNextMonthYear);
    } else {
      console.log('EmergencyFund - monthYear or nextMonthYear not received in props');
    }
  }, [monthYear, nextMonthYear]);

  useEffect(() => {
    const totalSpend = getFromLocalStorage('spendAnalysisSummary', 'totalSpend', globalMonthYear);
    if (totalSpend) {
      setAmounts([totalSpend.toString()]);
    }
  }, []);

  useEffect(() => {
    const savedIncome = getFromLocalStorage('spendAnalyzer', 'monthlyIncome', globalMonthYear);
    if (savedIncome) {
      setSalary(savedIncome);
    }
  }, []);

  useEffect(() => {
    const expenses = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear) || [];
    const emergencyFundExpenses = expenses.filter(expense => expense.categoryMapping === 'Emergency Fund');
    const totalEmergencyFundAmount = emergencyFundExpenses.reduce((sum, expense) => sum + parseFloat(expense.amount || 0), 0);
    const existingFundFromStorage = parseFloat(getFromLocalStorage('emergencyFund', 'currentEmergencyFundAmount', globalMonthYear)) || 0;
    const calculatedFundAmount = existingFundFromStorage + totalEmergencyFundAmount;
    updateLocalStorage('emergencyFund', {'currentEmergencyFundAmount': calculatedFundAmount}, globalNextMonthYear);
    setExistingFund(calculatedFundAmount);
  }, []);

  // Add useEffect to load summary data
  useEffect(() => {
    const loadSummaryData = () => {
      // Try to get data from nextMonthYear first
      let data = getFromLocalStorage('emergencyFund', null, globalNextMonthYear);
      
      // If no data in nextMonthYear, try currentMonthYear
      if (!data) {
        data = getFromLocalStorage('emergencyFund', null, globalMonthYear);
      }

      if (data) {
        setSummaryData({
          currentAmount: data.currentEmergencyFundAmount || 0,
          monthlyContribution: data.monthlyContribution || 0,
          targetAmount: data.targetAmount || 0,          // Access from root level
          timeToReachGoal: data.timeToReachGoal || 0    // Access from root level
        });
      }
    };

    loadSummaryData();
  }, [globalMonthYear, globalNextMonthYear]);

  const handleCategoryChange = (index, value) => {
    const newCategories = [...categories];
    newCategories[index] = value;
    setCategories(newCategories);
    validateCategories(newCategories);
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = value;
    setAmounts(newAmounts);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  const handleExistingFundChange = (e) => {
    setExistingFund(parseFloat(e.target.value) || 0); // Update existing emergency fund
  };

  const handleAddCategory = () => {
    if (categories.length < 10) {
      const newCategories = [...categories, ''];
      const newAmounts = [...amounts, ''];
      setCategories(newCategories);
      setAmounts(newAmounts);
    }
  };

  const validateCategories = (categoriesList) => {
    const uniqueCategories = new Set(categoriesList);
    if (uniqueCategories.size !== categoriesList.length) {
      setError('Categories must be unique');
    } else {
      setError('');
    }
  };

  const handleCalculateClick = () => {
    if (salary === '' || categories.some(cat => cat === '') || amounts.some(amount => amount === '')) {
      setError('Please fill out all fields');
    } else {
      setIsCalculated(true);
      setError('');
    }
  };

  const totalExpenses = amounts.reduce((acc, val) => acc + parseFloat(val) || 0, 0);
  const threeTimesIncome = Math.max(3 * salary - existingFund, 0); // Consider existing fund
  const sixTimesIncome = Math.max(6 * salary - existingFund, 0); // Consider existing fund
  const threeTimesExpenses = Math.max(3 * totalExpenses - existingFund, 0); // Consider existing fund
  const sixTimesExpenses = Math.max(6 * totalExpenses - existingFund, 0); // Consider existing fund

  let emergencyFundOptions = [
    { title: '3x Income', totalValue: 3 * salary, value: threeTimesIncome, description: 'Three months of your monthly income.' },
    { title: '3x Expenses', totalValue: 3 * totalExpenses, value: threeTimesExpenses, description: 'Three months of your total expenses.' },
    { title: '6x Income', totalValue: 6 * salary, value: sixTimesIncome, description: 'Six months of your monthly income.' },
    { title: '6x Expenses', totalValue: 6 * totalExpenses, value: sixTimesExpenses, description: 'Six months of your total expenses.' }
  ];

  emergencyFundOptions.sort((a, b) => a.totalValue - b.totalValue); // Sort options based on totalValue

  // Find the highest goal
  const highestGoal = emergencyFundOptions[emergencyFundOptions.length - 1].totalValue;

  useEffect(() => {
    if (existingFund >= highestGoal) {
      setCongratulationsMessage(true); // Set the congratulatory message if the highest goal is met or exceeded
      setSelectedFund(null); // No need to select a fund
    } else {
      setCongratulationsMessage(false); // Reset the congratulatory message
      setSelectedFund(0); // Default to the first option in the list
    }
  }, [isCalculated, highestGoal]);

  const handleFundSelection = (index) => {
    if (index !== selectedFund) {
        setSelectedFund(index);
        setShowFundPlan(false); // Collapse the FundPlan
        // Optionally, you can reset the existing fund or any other state if needed
        setExistingFund(existingFund); // Keep the existing fund as is
    }
  };

  const handleSaveDetails = () => {
    const selectedOption = {
      title: emergencyFundOptions[selectedFund].title,
      value: emergencyFundOptions[selectedFund].value,
      totalValue: emergencyFundOptions[selectedFund].totalValue
    };

    // Save emergency fund data
    const emergencyFundData = {
      existingFund: parseFloat(existingFund) || 0,
      targetAmount: selectedOption.totalValue,
      monthlyContribution: 0, // This will be updated in FundPlan
      timeToReachGoal: 0 // This will be updated in FundPlan
    };

    // Save to localStorage
    updateLocalStorage('emergencyFund', {'emergencyFundData': emergencyFundData}, globalNextMonthYear);
    
    // Set the selected option for FundPlan
    setSelectedOption(selectedOption);
    setShowFundPlan(true);
  };
  
  useEffect(() => {
    const emergencyFundPage = document.querySelector('.emergency-fund-page');
    const emContentContainer = document.querySelector('.em-content-container');
    
    console.log('Emergency Fund Component Widths:');
    console.log('- emergency-fund-page:', emergencyFundPage?.offsetWidth);
    console.log('- em-content-container:', emContentContainer?.offsetWidth);
  }, []); // Run once on mount

  const handleSave = () => {
    // Only save if a fund is selected and calculation is done
    if (selectedFund !== null && isCalculated) {
        const selectedOption = emergencyFundOptions[selectedFund];
        
        const emergencyFundData = {
            existingFund: parseFloat(existingFund) || 0,
            targetAmount: selectedOption.totalValue || 0,
            monthlyContribution: parseFloat(getFromLocalStorage('emergencyFund', 'monthlyContribution', globalMonthYear)) || 0,
            timeToReachGoal: Math.ceil(
                (selectedOption.value) / 
                (parseFloat(getFromLocalStorage('emergencyFund', 'monthlyContribution', globalMonthYear)) || 1)
            )
        };

        console.log('Saving emergency fund data:', emergencyFundData);

        // Save to localStorage
        updateLocalStorage('emergencyFund', {'emergencyFundData': emergencyFundData}, globalNextMonthYear);
        updateLocalStorage('emergencyFund', {'monthlyContribution': emergencyFundData.monthlyContribution}, globalNextMonthYear);

        // Update budget data
        onBudgetDataChange('Emergency Fund', emergencyFundData.monthlyContribution);
        onSave();
    }
  };

  useEffect(() => {
    updateLocalStorage('emergencyFund', { 'currentEmergencyFundAmount': existingFund }, globalNextMonthYear);
  }, [existingFund]);

  return (
    <div className="em-page-container">
      <div className="emergency-description">
        <div className="emergency-description-header">
          <h2>Emergency Fund Calculator</h2>
          <p className="emergency-description-subtitle">Build your financial safety net</p>
        </div>
        <div className="emergency-description-features">
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">🛡️</span>
            <span>Optimal Fund</span>
          </div>
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">📊</span>
            <span>Multiple options</span>
          </div>
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">💰</span>
            <span>Track your progress</span>
          </div>
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">📈</span>
            <span>Personalized plan</span>
          </div>
        </div>
      </div>

      {summaryData && (
        <div className="emergency-fund-summary">
          <h3>Emergency Fund Summary (as of {globalNextMonthYear})</h3>
          <table className="summary-table">
            <thead>
              <tr>
                <th>Current Emergency Fund</th>
                <th>Monthly Contribution</th>
                <th>Target Amount</th>
                <th>Time Duration (Months)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>₹{summaryData.currentAmount.toLocaleString('en-IN')}</td>
                <td>₹{summaryData.monthlyContribution.toLocaleString('en-IN')}</td>
                <td>₹{summaryData.targetAmount.toLocaleString('en-IN')}</td>
                <td>{summaryData.timeToReachGoal}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="em-content-container">
        <div className="existing-fund-section">
          <h2 className="section-description">
            To calculate how much you need in your emergency fund, please enter your current emergency fund amount.
          </h2>
          <div className="input-group">
            <label>Current Emergency Fund Amount:</label>
            <input
              type="number"
              value={existingFund}
              // onChange={(e) => setExistingFund(e.target.value)}
              onChange={(e) => getFromLocalStorage('emergencyFund', 'currentEmergencyFundAmount', globalMonthYear)}
              placeholder="Enter amount"
            />
          </div>
        </div>

        <div className="emergency-form">
          <form className="emergency-fund-form" onSubmit={(e) => e.preventDefault()}> {/* Prevent default form submission */}
            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td>
                      <select
                        value={category}
                        onChange={(e) => handleCategoryChange(index, e.target.value)}
                        className="input-select"
                      >
                        <option value="">Select a category</option>
                        <option value="MonthlySpends">MonthlySpends</option>
                        <option value="Rent/Mortgage">Rent/Mortgage</option>
                        <option value="Utilities">Utilities</option>
                        <option value="Groceries">Groceries</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Others">Others</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="number"
                        value={amounts[index]}
                        onChange={(e) => handleAmountChange(index, e.target.value)}
                        min="0"
                        className="input-number"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button type="button" onClick={handleAddCategory} className="add-category-button">
              Add More
            </button>
            <div className="salary-input-section">
              <label className="salary-label">
                Monthly Income:
              </label>
              <input
                type="number"
                className="salary-input"
                value={salary}
                onChange={handleSalaryChange}
                placeholder="Enter your monthly income"
              />
            </div>
            <button type="button" onClick={handleCalculateClick} className="calculate-button">
              Calculate Emergency Fund
            </button>
          </form>
          
          {error && <p className="error-message">{error}</p>}
          {isCalculated && (
            <div className="calculation-results">
              <h3>Your Emergency Fund Options</h3>
              {congratulationsMessage && <p className="congratulations-message">Congratulations! You've met all emergency fund goals.</p>}
              <div className="options-section">
                {emergencyFundOptions.map((option, index) => (
                  <div
                    key={index}
                    className={`fund-card ${index === selectedFund ? 'selected' : ''}`}
                    onClick={() => handleFundSelection(index)}
                  >
                    <input
                      type="radio"
                      id={`option-${index}`}
                      name="emergency-fund"
                      checked={index === selectedFund}
                      onChange={() => handleFundSelection(index)}
                    />
                    <div className="option-content">
                      <p className="option-title">{option.title}</p>
                      <p className="option-value"><strong>₹{option.value.toLocaleString('en-IN')}</strong></p>
                      <p className="option-description">{option.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              {!congratulationsMessage && (
                <button type="button" onClick={handleSaveDetails} className="get-started-button">
                  Save Details
                </button>
              )}
            </div>
          )}
        </div>

        {/* Conditionally render the FundPlan component */}
        {showFundPlan && (
            <FundPlan 
                existingFund={existingFund} 
                selectedOption={selectedOption} 
                onBudgetDataChange={onBudgetDataChange} 
                onSave={onSave} 
                globalMonthYear={globalMonthYear} 
                globalNextMonthYear={globalNextMonthYear} 
            />
        )}
      </div>
    </div>
  );
}

export default EmergencyFund;
