import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Dashboard.css';
import axios from 'axios';
import { getFromLocalStorage, updateLocalStorage, getPreviousMonthData } from '../../utils/storageUtils';

const Dashboard = () => {
    const navigate = useNavigate();
    const [chatInput, setChatInput] = useState('');
    const [chatResponse, setChatResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // State for chat suggestions
    const [chatSuggestions] = useState([
        "Add ₹5,000 spent on dining yesterday using UPI",
        "How can I save ₹20,000 this month?",
        "When will I reach ₹1L in my emergency fund?",
        "Add a short-term goal for 'Vacation' for ₹1,50,000 by Dec 2024."
    ]);

    // State for dynamic suggestions
    const [dynamicSuggestions] = useState([
        "You just updated your loan payments. Want to review your debt timeline?",
        "You're halfway through the month. Add this week's grocery expenses.",
        "How can I optimize my savings rate?",
        "What are the benefits of term-life insurance?"
    ]);

    // States for different sections
    const [debtData, setDebtData] = useState(null);
    const [savingsData, setSavingsData] = useState(null);
    const [emergencyFund, setEmergencyFund] = useState(null);
    const [retirementData, setRetirementData] = useState(null);
    const [expenseData, setExpenseData] = useState(null);
    const [pyramidProgress, setPyramidProgress] = useState(null);

    // Add state for success message
    const [addExpenseSuccess, setAddExpenseSuccess] = useState(false);

    useEffect(() => {
        // Load all saved data when component mounts
        loadDashboardData();
    }, []);

    // Add effect to reload data when component becomes active
    useEffect(() => {
        const handleFocus = () => {
            loadDashboardData();
        };

        window.addEventListener('focus', handleFocus);
        return () => window.removeEventListener('focus', handleFocus);
    }, []);

    const loadDashboardData = () => {
        try {
            // Load Debt Data
            const loanDetails = getFromLocalStorage('repayDebts', 'loanDetails') || [];
            const debtRepayment = getFromLocalStorage('repayDebts') || {};
            
            console.log('Debt Repayment Data:', debtRepayment);

            setDebtData({
                activeLoans: loanDetails.length,
                closedLoans: (loanDetails.filter(loan => loan.status === 'closed')).length,
                debtFreeDate: debtRepayment.projectedCompletionDate || '15-Dec-2025',
                monthsSaved: debtRepayment.monthsSaved || 0,
                totalDebt: loanDetails.reduce((sum, loan) => sum + parseFloat(loan.amount || 0), 0),
                calculationResults: debtRepayment.calculationResults || []
            });

            console.log('Debt Data Set:', debtData);

            // Load Savings Goals
            const savedGoals = getFromLocalStorage('shortTermSavings', 'goals') || [];
            setSavingsData({
                activeGoals: savedGoals.filter(goal => goal.status === 'active').length,
                pausedGoals: savedGoals.filter(goal => goal.status === 'paused').length,
                finishedGoals: savedGoals.filter(goal => goal.status === 'completed').length,
                goals: savedGoals
            });

            // Load Short-term Savings Goals
            const shortTermGoals = getFromLocalStorage('shortTermSavings', 'goals') || [];
            setSavingsData({
                activeGoals: shortTermGoals.filter(goal => !goal.paused && calculateSavingsProgress(goal) < 100).length,
                pausedGoals: shortTermGoals.filter(goal => goal.paused).length,
                finishedGoals: shortTermGoals.filter(goal => calculateSavingsProgress(goal) >= 100).length,
                goals: shortTermGoals.map(goal => ({
                    name: goal.name,
                    targetAmount: goal.amount,
                    targetDate: goal.targetDate || `${goal.timeframe} months`,
                    currentAmount: goal.currentSavings || 0,
                    progress: calculateSavingsProgress(goal)
                }))
            });

            // Load Emergency Fund Data
            const emergencyFundData = getFromLocalStorage('emergencyFund') || {};
            console.log('Loading Emergency Fund Data:', emergencyFundData);

            setEmergencyFund({
                current: emergencyFundData.existingFund || 0,
                target: emergencyFundData.targetAmount || 0,
                monthlyContribution: emergencyFundData.monthlyContribution || 0,
                timeToReachGoal: emergencyFundData.timeToReachGoal || 0
            });

            // Load Retirement Data
            const retirementData = getFromLocalStorage('retirement') || {};
            console.log('Loading Retirement Fund Data:', retirementData);

            setRetirementData({
                current: retirementData.currentSavings || 0,
                target: retirementData.targetCorpus || 0,
                monthlyContribution: retirementData.monthlyContribution || 0,
                planType: retirementData.planType || 'Fixed Savings'
            });

            // Load Expense Data
            const expenseTrackerData = getFromLocalStorage('expenseTracker') || {};
            const budgetData = getFromLocalStorage('budget') || {};
            
            setExpenseData({
                currentMonth: expenseTrackerData.breakdown?.total || 0,
                budget: parseFloat(budgetData.totalBudget) || 0,
                categories: budgetData.categories || []
            });

            // Load Pyramid Progress
            const pyramidPriorities = getFromLocalStorage('pyramid', 'priorities') || [];
            setPyramidProgress({
                debtReduction: calculatePyramidProgress('Debt Reduction', pyramidPriorities),
                emergencyFund: calculatePyramidProgress('Emergency Fund', pyramidPriorities),
                totalSteps: pyramidPriorities.length,
                completedSteps: pyramidPriorities.filter(step => step.status === 'completed').length,
                priorities: pyramidPriorities
            });
        } catch (error) {
            console.error('Error loading dashboard data:', error);
        }
    };

    const calculatePyramidProgress = (stepName, priorities) => {
        const step = priorities.find(p => p.name === stepName);
        return step ? step.progress : 0;
    };

    // Add function to parse expense from chat input
    const parseExpenseFromChat = (text) => {
        try {
            // Regular expressions to match both formats
            const regexWithSymbol = /Add ₹([\d,]+) spent on (\w+).*using (\w+)/i;
            const regexWithoutSymbol = /Add ([\d,]+) spent on (\w+).*using (\w+)/i;
            
            // Try both patterns
            const match = text.match(regexWithSymbol) || text.match(regexWithoutSymbol);

            if (match) {
                const amount = match[1].replace(/,/g, '');
                const category = match[2].charAt(0).toUpperCase() + match[2].slice(1).toLowerCase();
                const paymentMethod = match[3].toUpperCase();

                // Get yesterday's date
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const dateStr = yesterday.toISOString().split('T')[0];

                return {
                    id: Date.now(),
                    date: dateStr,
                    name: `${category} expense`,
                    amount: amount,
                    category: category,
                    expenseType: category === 'Dining' ? 'Discretionary' : 'Others', // Default mapping
                    modeOfPayment: paymentMethod,
                    frequency: 'One time'
                };
            }
            return null;
        } catch (error) {
            console.error('Error parsing expense from chat:', error);
            return null;
        }
    };

    // Add function to add expense from chat
    const addExpenseFromChat = (expense) => {
        try {
            // Extract MonthYear from the expense date
            const [year, month] = expense.date.split('-');
            const monthYear = `${month}-${year}`;

            // Get existing expenses for the MonthYear
            const existingMonthYearExpenses = getFromLocalStorage('expenseTracker', 'expenses', monthYear) || [];

            // Add new expense to the beginning
            const updatedMonthYearExpenses = [expense, ...existingMonthYearExpenses];

            // Save to storage
            updateLocalStorage('expenseTracker', { expenses : updatedMonthYearExpenses });

            // Show success message
            setAddExpenseSuccess(true);
            setTimeout(() => setAddExpenseSuccess(false), 3000);

            return true;
        } catch (error) {
            console.error('Error adding expense from chat:', error);
            return false;
        }
    };

    // Modify handleChatSubmit to check for both formats
    const handleChatSubmit = async (e) => {
        e.preventDefault();
        if (!chatInput.trim()) return;

        setIsLoading(true);
        try {
            // Check if it's an expense addition request (both formats)
            if (chatInput.toLowerCase().startsWith('add ₹') || chatInput.toLowerCase().startsWith('add ')) {
                const expense = parseExpenseFromChat(chatInput);
                if (expense) {
                    const success = addExpenseFromChat(expense);
                    if (success) {
                        setChatResponse(`Successfully added ${expense.category} expense of ₹${expense.amount} paid via ${expense.modeOfPayment} to your expense tracker.`);
                    } else {
                        setChatResponse('Sorry, there was an error adding the expense. Please try again or use the quick expense entry form.');
                    }
                } else {
                    setChatResponse('Sorry, I couldn\'t understand the expense details. Please use the format: "Add [amount] spent on [category] using [payment method]"');
                }
            } else {
                // Existing chat API call for non-expense queries
                const response = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    {
                        model: "gpt-4o",
                        messages: [
                            {
                                role: "system",
                                content: "You are a helpful financial advisor. Provide concise, practical advice based on the user's financial questions."
                            },
                            {
                                role: "user",
                                content: chatInput
                            }
                        ],
                        temperature: 0.7,
                        max_tokens: 500
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                setChatResponse(response.data.choices[0].message.content);
            }
        } catch (error) {
            console.error('Error in chat:', error);
            setChatResponse('Sorry, I encountered an error. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setChatInput(suggestion);
    };

    const renderChatResponse = (response) => {
        // Check if response contains numbers/amounts
        const hasAmount = /₹\s*\d+|[0-9,]+/.test(response);
        
        // Split response into paragraphs
        const paragraphs = response.split('\n\n');
        
        return (
            <div className="dashboard-chat-response-content">
                {paragraphs.map((paragraph, index) => {
                    // Check if paragraph is a numbered list
                    if (paragraph.match(/^\d+\./m)) {
                        // Split into lines and preserve original numbers
                        const items = paragraph.split('\n').filter(line => line.trim());
                        return (
                            <ol key={index}>
                                {items.map((item, i) => {
                                    // Extract the number and content
                                    const [, number, content] = item.match(/^(\d+)\.\s*(.*)$/) || ['', '', item];
                                    return (
                                        <li key={i} value={number} dangerouslySetInnerHTML={{
                                            __html: content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                                        }} />
                                    );
                                })}
                            </ol>
                        );
                    }
                    
                    // Check if paragraph is a bullet list
                    if (paragraph.match(/^[•\-]/m)) {
                        const items = paragraph.split(/\n[•\-]\s*/).filter(Boolean);
                        return (
                            <ul key={index}>
                                {items.map((item, i) => (
                                    <li key={i} dangerouslySetInnerHTML={{
                                        __html: item.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                                    }} />
                                ))}
                            </ul>
                        );
                    }
                    
                    // Regular paragraph
                    return (
                        <p key={index} dangerouslySetInnerHTML={{
                            __html: paragraph.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                        }} />
                    );
                })}
                {hasAmount && <div className="dashboard-chat-response-highlight" />}
            </div>
        );
    };

    // Add clearChatResponse function
    const clearChatResponse = () => {
        setChatResponse('');
        setChatInput('');
    };

    // Add function to handle expense addition
    const handleAddExpense = () => {
        try {
            // Get existing expenses
            const existingExpenses = getFromLocalStorage('expenseTracker', 'expenses') || [];
            
            // Create new expense object
            const newExpense = {
                id: Date.now(),
                date: document.querySelector('.dashboard-expense-form-row input[type="date"]').value,
                name: document.querySelector('.dashboard-expense-form-row input[type="text"]').value,
                amount: document.querySelector('.dashboard-expense-form-row input[type="number"]').value,
                category: document.querySelector('.dashboard-expense-form-row select:nth-of-type(1)').value,
                expenseType: document.querySelector('.dashboard-expense-form-row select:nth-of-type(2)').value,
                modeOfPayment: document.querySelector('.dashboard-expense-form-row select:nth-of-type(3)').value,
                frequency: document.querySelector('.dashboard-expense-form-row select:nth-of-type(4)').value
            };

            // Validate required fields
            if (!newExpense.date || !newExpense.name || !newExpense.amount || !newExpense.category) {
                alert('Please fill in all required fields');
                return;
            }

            // Extract MonthYear from the expense date
            const [year, month] = newExpense.date.split('-');
            const monthYear = `${month}-${year}`;

            // Get existing expenses for the MonthYear
            const existingMonthYearExpenses = getFromLocalStorage('expenseTracker','expenses', monthYear) || [];

            // Add new expense to the beginning
            const updatedMonthYearExpenses = [newExpense, ...existingMonthYearExpenses];

            // Save to storage
            updateLocalStorage('expenseTracker', { 'expenses': updatedMonthYearExpenses });

            // Clear form fields
            document.querySelector('.dashboard-expense-form-row input[type="date"]').value = new Date().toISOString().split('T')[0];
            document.querySelector('.dashboard-expense-form-row input[type="text"]').value = '';
            document.querySelector('.dashboard-expense-form-row input[type="number"]').value = '';
            document.querySelector('.dashboard-expense-form-row select:nth-of-type(1)').value = '';
            document.querySelector('.dashboard-expense-form-row select:nth-of-type(2)').value = '';
            document.querySelector('.dashboard-expense-form-row select:nth-of-type(3)').value = '';
            document.querySelector('.dashboard-expense-form-row select:nth-of-type(4)').value = '';

            // Show success message
            setAddExpenseSuccess(true);
            setTimeout(() => setAddExpenseSuccess(false), 3000);

        } catch (error) {
            console.error('Error adding expense:', error);
            alert('Error adding expense. Please try again.');
        }
    };

    const calculateSavingsProgress = (goal) => {
        const totalAmount = parseFloat(goal.amount) || 0;
        const currentSavings = parseFloat(goal.currentSavings) || 0;
        return totalAmount > 0 ? (currentSavings / totalAmount) * 100 : 0;
    };

    return (
        <div className="dashboard-container">
            {/* Chat Section */}
            <section className="dashboard-chat-section">
                <h2>What's on your mind?</h2>
                <form onSubmit={handleChatSubmit} className="dashboard-chat-form">
                    <textarea
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        placeholder="Ask me anything about your finances..."
                        className="dashboard-chat-input"
                    />
                    <button type="submit" className="dashboard-chat-submit-button" disabled={isLoading}>
                        {isLoading ? (
                            <div className="dashboard-loading-spinner"></div>
                        ) : (
                            'Ask'
                        )}
                    </button>
                </form>

                {isLoading && (
                    <div className="dashboard-chat-loading">
                        <div className="dashboard-loading-spinner"></div>
                        <p>Thinking...</p>
                    </div>
                )}

                {chatResponse && (
                    <div className="dashboard-chat-response">
                        <div className="dashboard-chat-response-header">
                            <div className="dashboard-chat-response-header-left">
                                <span className="dashboard-response-icon">💡</span>
                                <span className="dashboard-response-title">Financial Advice</span>
                            </div>
                            <button 
                                className="dashboard-clear-response"
                                onClick={clearChatResponse}
                                title="Clear response"
                            >
                                Clear
                            </button>
                        </div>
                        {renderChatResponse(chatResponse)}
                    </div>
                )}

                <div className="dashboard-suggestions-container">
                    <div className="dashboard-preset-suggestions">
                        {chatSuggestions.map((suggestion, index) => (
                            <div 
                                key={index}
                                className="dashboard-suggestion-chip"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion}
                            </div>
                        ))}
                    </div>
                    <div className="dashboard-dynamic-suggestions">
                        {dynamicSuggestions.map((suggestion, index) => (
                            <div 
                                key={index} 
                                className="dashboard-suggestion-chip dynamic"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                {suggestion}
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Expenses Section - Now full width */}
            <section className="dashboard-section dashboard-expenses-section full-width">
                <h3>Expenses</h3>
                <div className="dashboard-quick-expense-entry">
                    <div className="dashboard-expense-form-row">
                        <input type="date" defaultValue={new Date().toISOString().split('T')[0]} />
                        <input type="text" placeholder="Expense Name" />
                        <select defaultValue="">
                            <option value="">Category</option>
                            <option value="Rent">Rent</option>
                            <option value="Groceries">Groceries</option>
                            <option value="Utilities">Utilities</option>
                            <option value="Transport">Transport</option>
                            <option value="Travel">Travel</option>
                            <option value="Dining">Dining</option>
                            <option value="Shopping">Shopping</option>
                            <option value="Subscriptions">Subscriptions</option>
                        </select>
                        <select defaultValue="">
                            <option value="">Expense Type</option>
                            <option value="Essentials">Essentials</option>
                            <option value="Discretionary">Discretionary</option>
                            <option value="Others">Others</option>
                        </select>
                        <select defaultValue="">
                            <option value="">Mode of Payment</option>
                            <option value="Credit Card">Credit Card</option>
                            <option value="UPI">UPI</option>
                            <option value="Debit Card">Debit Card</option>
                            <option value="Wallet">Wallet</option>
                            <option value="Cash">Cash</option>
                            <option value="Net Banking">Net Banking</option>
                        </select>
                        <select defaultValue="">
                            <option value="">Frequency</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="One time">One time</option>
                            <option value="Adhoc">Adhoc</option>
                        </select>
                        <input type="number" placeholder="Amount" />
                        <button 
                            className="dashboard-action-button"
                            onClick={handleAddExpense}
                        >
                            Add
                        </button>
                    </div>
                </div>

                <div className="dashboard-expenses-overview">
                    <div className="dashboard-expenses-overview-left">
                        <h4>Current Month Spends:</h4>
                        <div className="dashboard-current-expenses">
                            <p>Current Month: ₹{expenseData?.currentMonth?.toLocaleString() || '0'}</p>
                            <p>Budget: ₹{expenseData?.budget?.toLocaleString() || '0'}</p>
                        </div>
                    </div>
                    
                    <div className="dashboard-expenses-overview-right">
                        <h4>Spend Insights:</h4>
                        <div className="dashboard-expenses-insights">
                            <p>• Projected: ₹{(expenseData?.currentMonth * 1.1)?.toLocaleString() || '0'} 
                               ({expenseData?.currentMonth > expenseData?.budget ? '₹' + 
                               ((expenseData?.currentMonth - expenseData?.budget)?.toLocaleString() || '0') + ' over' : 
                               '₹' + ((expenseData?.budget - expenseData?.currentMonth)?.toLocaleString() || '0') + ' under'} budget)</p>
                        </div>
                    </div>
                </div>
                <button 
                    className="dashboard-action-button secondary dashboard-details-button"
                    onClick={() => navigate('/aggregator/expense-reduction')}
                >
                    See Details
                </button>
            </section>

            {/* Two Column Grid for other sections */}
            <div className="dashboard-grid">
                {/* Financial Pyramid Section */}
                <section className="dashboard-section dashboard-pyramid-section">
                    <h3>Your Financial Pyramid</h3>
                    <div className="dashboard-pyramid-container">
                        {[...(pyramidProgress?.priorities || [])].reverse().map((priority, index) => {
                            const isSelected = getFromLocalStorage('shortTermSavings', 'goals') || []
                                .includes(priority.content);
                            return (
                                <div 
                                    key={priority.id} 
                                    className={`dashboard-pyramid-level ${isSelected ? 'selected' : 'unselected'}`}
                                >
                                    {priority.content}
                                </div>
                            );
                        })}
                    </div>
                </section>

                {/* Rest of the sections... */}
                {/* Debt Section */}
                <section className="dashboard-section">
                    <h3>Debt Overview</h3>
                    <div className="dashboard-stats-container">
                        <div className="dashboard-stat-item">
                            <span className="dashboard-stat-label">Active Loans</span>
                            <span className="dashboard-stat-value">{debtData?.activeLoans || 0}</span>
                        </div>
                        <div className="dashboard-stat-item">
                            <span className="dashboard-stat-label">Closed Loans</span>
                            <span className="dashboard-stat-value">{debtData?.closedLoans || 0}</span>
                        </div>
                        <div className="dashboard-stat-item">
                            <span className="dashboard-stat-label">Debt Free By</span>
                            <span className="dashboard-stat-value">{debtData?.debtFreeDate || 'N/A'}</span>
                            {debtData?.monthsSaved > 0 && (
                                <span className="dashboard-stat-subtitle">
                                    {debtData.monthsSaved} months earlier!
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="dashboard-expense-buttons">
                        <button className="dashboard-action-button" onClick={() => navigate('/aggregator/debt-reduction')}>
                            See Details
                        </button>
                    </div>
                </section>

                {/* Savings Goals Section */}
                <section className="dashboard-section">
                    <h3>Savings Goals</h3>
                    <div className="dashboard-goals-stats">
                        <div className="dashboard-goal-stat-item active">
                            <span className="dashboard-stat-label">Active Goals</span>
                            <span className="dashboard-stat-value">{savingsData?.activeGoals || 0}</span>
                        </div>
                        <div className="dashboard-goal-stat-item paused">
                            <span className="dashboard-stat-label">Paused</span>
                            <span className="dashboard-stat-value">{savingsData?.pausedGoals || 0}</span>
                        </div>
                        <div className="dashboard-goal-stat-item completed">
                            <span className="dashboard-stat-label">Finished</span>
                            <span className="dashboard-stat-value">{savingsData?.finishedGoals || 0}</span>
                        </div>
                    </div>
                    <div className="dashboard-goals-progress">
                        {savingsData?.goals?.map((goal, index) => (
                            <div key={index} className="dashboard-goal-item">
                                <div className="dashboard-goal-name">{goal.name}</div>
                                <div className="dashboard-goal-details">
                                    Target: ₹{goal.targetAmount} by {goal.targetDate}
                                </div>
                                <div className="dashboard-progress-bar">
                                    <div
                                        className="dashboard-progress"
                                        style={{ width: `${goal.progress}%` }}
                                    ></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="dashboard-expense-buttons">
                        <button className="dashboard-action-button" onClick={() => navigate('/aggregator/short-term-savings')}>
                            See Details
                        </button>
                    </div>
                </section>

                {/* Emergency Fund Section */}
                <section className="dashboard-section">
                    <h3>Emergency Fund</h3>
                    <div className="dashboard-fund-progress">
                        <div 
                            className="dashboard-progress-circle" 
                            style={{
                                '--progress': `${Math.min((emergencyFund?.current / emergencyFund?.target) * 100, 100)}`
                            }}
                            data-progress={Math.round((emergencyFund?.current / emergencyFund?.target) * 100) || 0}
                        ></div>
                        <div className="dashboard-fund-stats">
                            <p>Current: ₹{emergencyFund?.current?.toLocaleString() || 0}</p>
                            <p>Target: ₹{emergencyFund?.target?.toLocaleString() || 0}</p>
                            <p>Monthly: ₹{emergencyFund?.monthlyContribution?.toLocaleString() || 0}</p>
                            <p>Time to Goal: {emergencyFund?.timeToReachGoal || 0} months</p>
                        </div>
                    </div>
                    <div className="dashboard-expense-buttons">
                        <button className="dashboard-action-button" onClick={() => navigate('/aggregator/emergency-fund')}>
                            See Details
                        </button>
                    </div>
                </section>

                {/* Retirement Section */}
                <section className="dashboard-section">
                    <h3>Retirement Planning</h3>
                    <div className="dashboard-retirement-progress">
                        <div 
                            className="dashboard-progress-circle" 
                            style={{
                                '--progress': `${Math.min((retirementData?.current / retirementData?.target) * 100, 100)}`
                            }}
                            data-progress={Math.round((retirementData?.current / retirementData?.target) * 100) || 0}
                        ></div>
                        <div className="dashboard-retirement-stats">
                            <p>Current: ₹{retirementData?.current?.toLocaleString() || 0}</p>
                            <p>Target: ₹{retirementData?.target?.toLocaleString() || 0}</p>
                            <p>Monthly: ₹{retirementData?.monthlyContribution?.toLocaleString() || 0}</p>
                        </div>
                    </div>
                    <div className="dashboard-retirement-plan">
                        <p>Plan Type: {retirementData?.planType || 'Not Set'}</p>
                    </div>
                    <div className="dashboard-expense-buttons">
                        <button className="dashboard-action-button" onClick={() => navigate('/aggregator/retirement-fund')}>
                            See Details
                        </button>
                    </div>
                </section>
            </div>

            {/* Add success message display */}
            {addExpenseSuccess && (
                <div className="dashboard-success-message">
                    Expense added successfully!
                </div>
            )}
        </div>
    );
};

export default Dashboard; 