import React, { useState, useEffect } from 'react';
import '../css/ExpenseTracker.css';
import OpenAI from 'openai';
import { getFromLocalStorage, updateLocalStorage, getPreviousMonthData } from '../../utils/storageUtils';

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

// Initialize OpenAI client
const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

// Simplified CategoryMap
const CategoryMap = {
    Essentials: ['Rent', 'Groceries', 'Utilities', 'Transport'],
    Discretionary: ['Travel', 'Dining', 'Shopping', 'Subscriptions'],
    Others: [] // This will catch all categories not mapped above
};

// Add a separate constant for all available categories
const ALL_CATEGORIES = [
    // Living Expenses
    'Rent', 'Groceries', 'Utilities', 'Transport',
    // Lifestyle
    'Travel', 'Dining', 'Shopping', 'Subscriptions'
];

// Helper function to find the expense type for a given category
const findExpenseTypeForCategory = (category) => {
    for (const [expenseType, categories] of Object.entries(CategoryMap)) {
        if (categories.includes(category)) {
            return expenseType;
        }
    }
    return 'Others'; // Default if no mapping found
};

const ExpenseTracker = ({ onBudgetDataChange, onBudgetDataBatchChange, onSave, monthYear, nextMonthYear }) => {
    const [expenses, setExpenses] = useState([]);
    const [customCategories, setCustomCategories] = useState({});
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [adjustedSpends, setAdjustedSpends] = useState(() => {
        return getFromLocalStorage('expenseTracker', 'adjustedSpends', globalMonthYear) || {};
    });
    const [updateMessage, setUpdateMessage] = useState(() => {
        return getFromLocalStorage('expenseTracker', 'updateMessage', globalMonthYear) || '';
    });
    const [aiInsights, setAiInsights] = useState('');
    const [showInsights, setShowInsights] = useState(false);
    const [showOptimizationTable, setShowOptimizationTable] = useState(false);
    const [showAdjustmentButton, setShowAdjustmentButton] = useState(false);
   
    // Add useEffect to handle month-year values
    useEffect(() => {
        if (monthYear && nextMonthYear) {
            globalMonthYear = monthYear;
            globalNextMonthYear = nextMonthYear;

            console.log('globalMonthYear:', globalMonthYear);
            console.log('globalNextMonthYear:', globalNextMonthYear);
        } else {
            console.log('ExpenseTracker - monthYear or nextMonthYear not received in props');
        }
    }, [monthYear, nextMonthYear]);

    // Load expenses from storage when component mounts
    useEffect(() => {
        const loadData = () => {
            try {
                // Load expenses
                const storedExpenses = getFromLocalStorage('expenseTracker', 'expenses', globalMonthYear) || [];
                setExpenses(storedExpenses);

                // Load custom categories
                const storedCustomCategories = getFromLocalStorage('expenseTracker', 'customCategories', globalMonthYear) || {};
                setCustomCategories(storedCustomCategories);

                // Load analysis state
                const storedAnalysis = getFromLocalStorage('expenseTracker', 'analysis', globalMonthYear) || {
                    showAnalysis: false,
                };
                setShowAnalysis(storedAnalysis.showAnalysis);

                // Check if optimization table exists for nextMonthYear
                const existingOptimizationTable = getFromLocalStorage('expenseTracker', 'optimizationTable', globalNextMonthYear);
                
                // If no optimization table exists, initialize it with SpendAnalyzer data
                if (!existingOptimizationTable || existingOptimizationTable.length === 0) {
                    // Get expenses from SpendAnalyzer
                    const spendAnalyzerExpenses = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear) || [];
                    console.log('SpendAnalyzer expenses:', spendAnalyzerExpenses);
                    
                    if (spendAnalyzerExpenses && spendAnalyzerExpenses.length > 0) {
                        // Filter and group Essentials and Discretionary expenses by category
                        const groupedExpenses = spendAnalyzerExpenses.reduce((acc, expense) => {
                            if (expense.categoryMapping === 'Essentials' || expense.categoryMapping === 'Discretionary') {
                                if (!acc[expense.category]) {
                                    acc[expense.category] = {
                                        category: expense.category,
                                        amount: 0,
                                        expenseType: expense.categoryMapping
                                    };
                                }
                                acc[expense.category].amount += parseFloat(expense.amount) || 0;
                            }
                            return acc;
                        }, {});

                        // Convert to array and save to optimization table
                        const initialOptimizationTable = Object.values(groupedExpenses);
                        
                        if (initialOptimizationTable.length > 0) {
                            // Calculate totals
                            const { discretionaryTotal, essentialsTotal } = initialOptimizationTable.reduce(
                                (totals, entry) => {
                                    if (entry.expenseType === 'Discretionary') {
                                        totals.discretionaryTotal += entry.amount;
                                    } else if (entry.expenseType === 'Essentials') {
                                        totals.essentialsTotal += entry.amount;
                                    }
                                    return totals;
                                },
                                { discretionaryTotal: 0, essentialsTotal: 0 }
                            );

                            // Save all data to storage
                            updateLocalStorage('expenseTracker', {
                                optimizationTable: initialOptimizationTable,
                                discretionaryTotal: discretionaryTotal.toString(),
                                essentialsTotal: essentialsTotal.toString()
                            }, globalNextMonthYear);

                            console.log('Initialized optimization table with data:', {
                                table: initialOptimizationTable,
                                discretionaryTotal,
                                essentialsTotal
                            });
                        }
                    }
                }
             
            } catch (error) {
                console.error('Error loading data from storage:', error);
            }
        };

        loadData();
        window.addEventListener('focus', loadData);
        
        return () => {
            window.removeEventListener('focus', loadData);
        };
    }, []);

    // Add a new row with default empty values
    const addExpense = () => {
        const defaultCategory = 'Rent';
        const defaultExpenseType = findExpenseTypeForCategory(defaultCategory);
        
        const newExpense = {
            id: Date.now(),
            date: '',
            name: '',
            amount: '',
            category: defaultCategory,
            expenseType: defaultExpenseType,
            modeOfPayment: 'Credit Card',
            frequency: 'Monthly'
        };
        
        const updatedExpenses = [newExpense, ...expenses];
        setExpenses(updatedExpenses);
        saveToLocalStorage({ expenses: updatedExpenses });
    };

    // Handle edits to table fields
    const handleEditChange = (index, field, value) => {
        try {
            const updatedExpenses = [...expenses];
            
            // Special handling for category field
            if (field === 'category') {
                if (value === 'Other') {
                    const updatedCustomCategories = {
                        ...customCategories,
                        [index]: customCategories[index] || ''
                    };
                    setCustomCategories(updatedCustomCategories);
                    updatedExpenses[index].expenseType = 'Others';
                    
                    saveToLocalStorage({ 
                        expenses: updatedExpenses,
                        customCategories: updatedCustomCategories
                    });
                } else {
                    const updatedCustomCategories = { ...customCategories };
                    delete updatedCustomCategories[index];
                    setCustomCategories(updatedCustomCategories);
                    
                    const expenseType = findExpenseTypeForCategory(value);
                    updatedExpenses[index].expenseType = expenseType;
                    
                    saveToLocalStorage({ 
                        expenses: updatedExpenses,
                        customCategories: updatedCustomCategories
                    });
                }
            }
            
            updatedExpenses[index][field] = value;
            setExpenses(updatedExpenses);
            saveToLocalStorage({ expenses: updatedExpenses });
            
        } catch (error) {
            console.error('Error saving edit:', error);
        }
    };

    // Add a handler for custom category input
    const handleCustomCategoryChange = (index, value) => {
        try {
            const updatedExpenses = [...expenses];
            
            if (!value.trim()) {
                // If the custom input is empty, revert back to dropdown
                updatedExpenses[index].category = '';
            } else {
                updatedExpenses[index].category = 'Other';
                updatedExpenses[index].expenseType = 'Others';
            }
            
            setExpenses(updatedExpenses);
            
            const updatedCustomCategories = {
                ...customCategories,
                [index]: value
            };
            setCustomCategories(updatedCustomCategories);
            
            updateLocalStorage('expenseTracker', {
                expenses: updatedExpenses,
                customCategories: updatedCustomCategories
            }, globalNextMonthYear);
        } catch (error) {
            console.error('Error saving custom category:', error);
        }
    };

    // Handle explicit save with sorting
    const handleSaveChanges = () => {
        try {
            // Sort and clean expenses
            const sortedExpenses = expenses
                .filter(expense => expense.amount && expense.category) // Only store valid expenses
                .sort((a, b) => {
                    const dateA = a.date ? new Date(a.date) : new Date(0);
                    const dateB = b.date ? new Date(b.date) : new Date(0);
                    return dateB - dateA;
                })
                .map(expense => ({
                    id: expense.id,
                    date: expense.date,
                    name: expense.name,
                    amount: Number(parseFloat(expense.amount).toFixed(2)),
                    category: expense.category,
                    expenseType: expense.expenseType,
                    modeOfPayment: expense.modeOfPayment,
                    frequency: expense.frequency
                }));

            const totalAmount = sortedExpenses.reduce((total, expense) => 
                total + (expense.amount || 0), 0);

            // Update state and storage in one operation
            setExpenses(sortedExpenses);
            updateLocalStorage('expenseTracker', {
                expenses: sortedExpenses,
                totalAmount: Number(totalAmount.toFixed(2))
            }, globalNextMonthYear);

            // Update budget data
            onBudgetDataChange('Expense Reduction', totalAmount);

            if (onSave) {
                onSave();
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            alert('Error saving changes');
        }
    };

    // Add delete handler function
    const handleDelete = (index) => {
        try {
            const updatedExpenses = expenses.filter((_, idx) => idx !== index);
            const updatedCustomCategories = { ...customCategories };
            delete updatedCustomCategories[index];
            
            setExpenses(updatedExpenses);
            setCustomCategories(updatedCustomCategories);
            
            saveToLocalStorage({ 
                expenses: updatedExpenses,
                customCategories: updatedCustomCategories
            });
            
        } catch (error) {
            console.error('Error deleting expense:', error);
        }
    };

    // Add function to calculate spend breakdown
    const calculateSpendBreakdown = () => {
        const breakdown = {};
        let totalSpend = 0;
        
        // Get category totals from SpendAnalyzer
        const categoryTotals = getFromLocalStorage('spendAnalyzer', 'categoryTotals', globalMonthYear) || {};

        // Initialize breakdown with all categories
        ALL_CATEGORIES.forEach(category => {
            breakdown[category] = {
                amount: 0,
                percentage: 0,
                budget: categoryTotals[category] || 0,
                variance: 0
            };
        });

        // Calculate totals by category
        expenses.forEach(expense => {
            const amount = parseFloat(expense.amount) || 0;
            const category = expense.category;
            
            if (category && category !== 'Other' && amount > 0) {
                if (!breakdown[category]) {
                    breakdown[category] = {
                        amount: 0,
                        percentage: 0,
                        budget: categoryTotals[category] || 0,
                        variance: 0
                    };
                }
                breakdown[category].amount += amount;
                totalSpend += amount;
            }
        });

        // Calculate percentages and variances
        Object.keys(breakdown).forEach(category => {
            const data = breakdown[category];
            data.percentage = totalSpend > 0 ? ((data.amount / totalSpend) * 100).toFixed(2) : '0.00';
            data.variance = data.budget - data.amount;
        });

        // Filter out categories with no budget and no spending
        const filteredBreakdown = Object.entries(breakdown)
            .filter(([_, data]) => data.budget > 0 || data.amount > 0)
            .sort(([a], [b]) => a.localeCompare(b))
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        return { breakdown: filteredBreakdown, totalSpend };
    };

    // Add this new function to get AI insights
    const getAIInsights = async (expenses) => {
        try {
            // First, create valid expense data
            const validExpenses = expenses
                .filter(expense => expense.amount && expense.category) // Filter out invalid entries
                .map(expense => ({
                    name: expense.name,
                    amount: parseFloat(expense.amount) || 0,
                    category: expense.category,
                    frequency: expense.frequency,
                    expenseType: expense.expenseType
                }));

            // Group and sum expenses by category
            const groupedExpenses = validExpenses.reduce((acc, expense) => {
                const key = expense.category;
                if (!acc[key]) {
                    acc[key] = {
                        category: expense.category,
                        amount: 0,
                        frequency: new Set(),
                        expenseType: expense.expenseType
                    };
                }
                acc[key].amount += expense.amount;
                acc[key].frequency.add(expense.frequency);
                return acc;
            }, {});

            // Convert grouped data to array format and convert frequency Set to Array
            const groupedExpenseArray = Object.values(groupedExpenses).map(expense => ({
                category: expense.category,
                amount: expense.amount,
                frequency: Array.from(expense.frequency).join('/'), // Combine multiple frequencies
                expenseType: expense.expenseType
            }));

            // Filter out essential categories
            const nonEssentialExpenses = groupedExpenseArray.filter(
                expense => !CategoryMap.Essentials.includes(expense.category)
            );

            // If no non-essential expenses, return early
            if (nonEssentialExpenses.length === 0) {
                return "No non-essential expenses found to analyze.";
            }

            const prompt = `As a financial advisor, analyze these grouped non-essential expenses and provide insights based on the total spending in each category:
            ${JSON.stringify(nonEssentialExpenses, null, 2)}

            Provide insights ONLY for categories present in the data above. 
            Each insight should be a single, complete sentence including specific amounts.
            
            Consider these aspects (but only include if relevant data exists):
            - Categories with highest total spending
            - Categories with multiple frequency types (daily/monthly/adhoc)
            - Potential for consolidation or reduction in each category

            Format each insight as:
            1. "Consider reducing [category] spending from ₹[amount] by [specific action] to save ₹[amount] monthly."

            Important:
            - Only suggest optimizations for categories present in the data
            - Number of insights should match the available patterns (can be less than 4)
            - Each insight must reference the total spending amount for each category
            - Focus on categories with highest spending first`;

            const completion = await openai.chat.completions.create({
                messages: [{ role: "user", content: prompt }],
                model: "gpt-4o",
            });

            return completion.choices[0].message.content;
        } catch (error) {
            console.error('Error getting AI insights:', error);
            return null;
        }
    };

    // Update the handleAnalyzeExpenses function
    const handleAnalyzeExpenses = async () => {
        setShowAnalysis(true);
        setShowInsights(true);
        setShowOptimizationTable(true);
        setShowAdjustmentButton(true);
        saveToLocalStorage({ analysis: { showAnalysis: true } });

        // Get AI insights
        const insights = await getAIInsights(expenses);
        if (insights) {
            setAiInsights(insights);
        }
    };

    // Add a function to calculate budget utilization percentage
    const calculateBudgetUtilization = (spent, budget) => {
        if (budget <= 0) return 0;
        return ((budget - spent) / budget) * 100;
    };

    // Helper function to save to localStorage
    const saveToLocalStorage = (data) => {
        try {
            // Clean up expenses data before storing
            if (data.expenses) {
                data.expenses = data.expenses.map(expense => ({
                    id: expense.id,
                    date: expense.date,
                    name: expense.name,
                    amount: Number(parseFloat(expense.amount).toFixed(2)), // Reduce precision
                    category: expense.category,
                    expenseType: expense.expenseType,
                    modeOfPayment: expense.modeOfPayment,
                    frequency: expense.frequency
                }));
            }

            // Only store custom categories that are actually used
            if (data.customCategories) {
                const usedCustomCategories = {};
                Object.entries(data.customCategories).forEach(([key, value]) => {
                    if (value && value.trim()) {
                        usedCustomCategories[key] = value;
                    }
                });
                data.customCategories = usedCustomCategories;
            }

            // updateLocalStorage('expenseTracker', data, globalNextMonthYear);
        } catch (error) {
            console.error('Error saving to localStorage:', error);
        }
    };

    // Add these handler functions before the return statement
    const handlePercentageReduction = (percentage) => {
        try {
            const updatedExpenses = expenses.map(expense => {
                // Only reduce discretionary spending categories
                if (['Travel', 'Dining', 'Shopping', 'Subscriptions'].includes(expense.category)) {
                    const amount = parseFloat(expense.amount);
                    if (!isNaN(amount)) {
                        return {
                            ...expense,
                            amount: (amount * (1 - percentage / 100)).toFixed(2)
                        };
                    }
                }
                return expense;
            });

            setExpenses(updatedExpenses);
            saveToLocalStorage({ expenses: updatedExpenses });
            
        } catch (error) {
            console.error('Error applying percentage reduction:', error);
        }
    };

    // Update the generateOptimizationTable function
    const generateOptimizationTable = () => {
        // First try to get stored optimization table data
        const storedTable = getFromLocalStorage('expenseTracker', 'optimizationTable', globalNextMonthYear);
        let tableData = [];
    
        if (storedTable && storedTable.length > 0) {
            tableData = storedTable;
        } else {
            // If no stored data, then generate from current expenses
            const categoryTotals = expenses.reduce((acc, expense) => {
                const category = expense.category;
                const amount = parseFloat(expense.amount) || 0;
                if (category && category !== 'Other') {
                    if (!acc[category]) {
                        acc[category] = {
                            category,
                            currentAmount: 0, // Use currentAmount for original spend
                            expenseType: expense.expenseType
                        };
                    }
                    acc[category].currentAmount += amount;
                }
                return acc;
            }, {});
    
            // Convert to array format and ensure all categories are included
            tableData = ALL_CATEGORIES.map(category => ({
                category,
                currentAmount: (categoryTotals[category]?.currentAmount || 0),
                adjustedAmount: adjustedSpends[category] ?? (categoryTotals[category]?.currentAmount || 0),
                expenseType: categoryTotals[category]?.expenseType
            })).filter(entry => entry.currentAmount > 0);
    
            // Sort by currentAmount in descending order
            tableData.sort((a, b) => b.currentAmount - a.currentAmount);
        }
    
        return tableData;
    };

    // Update the handler for adjusted spends
    const handleAdjustedSpendChange = (category, value) => {
        // Allow empty string for clearing the input
        const numValue = value === '' ? '' : parseFloat(value);
        setAdjustedSpends(prev => {
            const updatedSpends = {
                ...prev,
                [category]: numValue
            };
            // Save updated spends to local storage
            updateLocalStorage('expenseTracker', { adjustedSpends: updatedSpends }, globalNextMonthYear);
            return updatedSpends;
        });
    };

    // Add this handler function
    const handleAddAdjustmentsToBudget = () => {
        try {
            // Get the optimization table data
            const optimizationTable = generateOptimizationTable();
            
            // Calculate totals from the optimization table and adjusted spends
            const { discretionaryTotal, essentialsTotal } = optimizationTable.reduce((totals, { category, amount }) => {
                // Get the adjusted amount for this category, fallback to original amount if not adjusted
                const adjustedAmount = adjustedSpends[category] ?? amount;

                // Check if category is in Essentials or Discretionary
                if (CategoryMap.Essentials.includes(category)) {
                    totals.essentialsTotal += adjustedAmount;
                } else if (CategoryMap.Discretionary.includes(category)) {
                    totals.discretionaryTotal += adjustedAmount;
                }
                
                return totals;
            }, { discretionaryTotal: 0, essentialsTotal: 0 });

            console.log('Calculated totals from adjusted spends:', {
                discretionaryTotal,
                essentialsTotal,
                adjustedSpends,
                optimizationTable
            });

            // Save the totals to localStorage
            updateLocalStorage('expenseTracker', { 
                discretionaryTotal: discretionaryTotal.toString(),
                essentialsTotal: essentialsTotal.toString()
            }, globalNextMonthYear);

            // Update both budget values in a single call
            onBudgetDataBatchChange({
                Discretionary: discretionaryTotal,
                Essentials: essentialsTotal
            });

            // Update message with both totals
            const message = `Budget successfully updated! New Discretionary spends: ₹${discretionaryTotal.toFixed(2)}, Essentials spends: ₹${essentialsTotal.toFixed(2)}`;
            setUpdateMessage(message);

            // Enable the Next button by calling onSave
            if (onSave) {
                onSave();
            }
        } catch (error) {
            console.error('Error updating budget with adjustments:', error);
            setUpdateMessage('Failed to update budget with adjustments.');
        }
    };

    // Update the handleApplyOptimizations function
    const handleApplyOptimizations = () => {
        try {
            // Parse existing adjusted spends
            const updatedAdjustedSpends = { ...adjustedSpends };
    
            // Log the AI insights for debugging
            console.log('AI Insights:', aiInsights);
    
            // Split insights into lines and filter empty ones
            const insights = aiInsights.split('\n').filter((insight) => insight.trim());
            console.log('Processed Insights:', insights);
    
            // Process each insight
            insights.forEach((insight) => {
                console.log('Processing insight:', insight);
    
                // Try to match the AI-generated insight pattern
                let matches = insight.match(/reducing (\w+) spending from ₹(\d+(?:\.\d+)?).+save ₹(\d+(?:\.\d+)?)/i);
    
                // If the first pattern doesn't match, try another
                if (!matches) {
                    matches = insight.match(/(\w+).+₹(\d+(?:\.\d+)?).+save ₹(\d+(?:\.\d+)?)/i);
                }
    
                if (matches) {
                    const [, category, currentSpendStr, savingsStr] = matches;
                    const currentSpend = parseFloat(currentSpendStr);
                    const suggestedSavings = parseFloat(savingsStr);
    
                    // Find the matching category in the optimization table
                    const tableEntry = generateOptimizationTable().find(
                        (item) => item.category.toLowerCase() === category.toLowerCase()
                    );
    
                    if (tableEntry && !CategoryMap.Essentials.includes(tableEntry.category)) {
                        const adjustedAmount = updatedAdjustedSpends[tableEntry.category] ?? tableEntry.amount;
    
                        // Calculate the new adjusted spend
                        const newAdjustedSpend = Math.max(adjustedAmount - suggestedSavings, 0);
    
                        // Update the adjusted spends
                        updatedAdjustedSpends[tableEntry.category] = newAdjustedSpend;
    
                        console.log('Updated category:', {
                            category: tableEntry.category,
                            currentSpend: adjustedAmount,
                            suggestedSavings,
                            newAdjustedSpend,
                        });
                    }
                } else {
                    console.log('No match found for insight:', insight);
                }
            });
    
            // Log the final adjusted spends
            console.log('Final adjusted spends:', updatedAdjustedSpends);
    
            // Update the adjusted spends state and storage
            setAdjustedSpends(updatedAdjustedSpends);
            updateLocalStorage('expenseTracker', { adjustedSpends: updatedAdjustedSpends }, globalNextMonthYear);
    
            // Show a success message
            const message = 'AI-suggested optimizations applied successfully!';
            setUpdateMessage(message);
            updateLocalStorage('expenseTracker', { updateMessage: message }, globalNextMonthYear);
        } catch (error) {
            console.error('Error in handleApplyOptimizations:', error);
            const errorMessage = 'Failed to apply optimizations. Check console for details.';
            setUpdateMessage(errorMessage);
            updateLocalStorage('expenseTracker', { updateMessage: errorMessage }, globalNextMonthYear);
        }
    };
    

    useEffect(() => {
        // Only update storage if there are actual changes
        const storageData = {};
        
        // Only store if values exist and have changed
        if (Object.keys(adjustedSpends).length > 0) {
            storageData.adjustedSpends = adjustedSpends;
            
            // Update the optimization table with adjusted spends
            const tableData = generateOptimizationTable();
            storageData.optimizationTable = tableData.map(({ category, amount, expenseType }) => ({
                category,
                amount: Number(amount.toFixed(2)),
                expenseType
            }));
        }
        
        if (updateMessage) {
            storageData.updateMessage = updateMessage;
        }

        // Only update storage if we have data to store
        if (Object.keys(storageData).length > 0) {
            try {
                updateLocalStorage('expenseTracker', storageData, globalNextMonthYear);
            } catch (error) {
                console.error('Storage update failed:', error);
            }
        }
    }, [adjustedSpends, updateMessage, showOptimizationTable, expenses]);

    useEffect(() => {
        // Load adjusted spends from local storage when component mounts
        const storedAdjustedSpends = getFromLocalStorage('expenseTracker', 'adjustedSpends', globalNextMonthYear);
        if (storedAdjustedSpends) {
            setAdjustedSpends(storedAdjustedSpends);
        }
    }, [globalMonthYear]);

    return (
        <div className="expense-tracker-expensetracker">
            <div className="expense-description">
                <div className="expense-description-header">
                    <h2>Expense Tracker</h2>
                    <p className="expense-description-subtitle">Track, analyze, and optimize your spending habits</p>
                </div>
                <div className="expense-description-features">
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">📊</span>
                        <span>Track daily expenses</span>
                    </div>
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">���</span>
                        <span>AI-powered insights</span>
                    </div>
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">💡</span>
                        <span>Smart recommendations</span>
                    </div>
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">📈</span>
                        <span>Budget analysis</span>
                    </div>
                </div>
            </div>

            {/* Optimization Table - Always visible */}
            <div className="optimization-table-container">
                <h3>Spending Optimization Table (for {globalNextMonthYear})</h3>
                <table className="optimization-table">
                    <thead>
                        <tr>
                            <th>Expense Category</th>
                            <th>Current Spend</th>
                            <th>Adjusted Spends</th>
                            <th>Potential Savings</th>
                        </tr>
                    </thead>
                    <tbody>
                        {generateOptimizationTable().map(({ category, amount }) => {
                            const adjustedAmount = adjustedSpends[category] ?? amount;
                            const actualSavings = amount - adjustedAmount;

                            return (
                                <tr key={category}>
                                    <td>{category}</td>
                                    <td>₹{(amount || 0).toFixed(0)}</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={(adjustedAmount ?? 0).toString()}
                                            onChange={(e) => handleAdjustedSpendChange(category, e.target.value)}
                                            className="adjusted-spend-input"
                                            onBlur={(e) => {
                                                if (e.target.value === '' || isNaN(parseFloat(e.target.value))) {
                                                    handleAdjustedSpendChange(category, amount);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td>₹{actualSavings.toFixed(0)}</td>
                                </tr>
                            );
                        })}
                        <tr className="total-row">
                            <td><strong>Total</strong></td>
                            <td>
                                <strong>₹{generateOptimizationTable()
                                    .reduce((total, { amount }) => total + (amount || 0), 0)
                                    .toFixed(0)}
                                </strong>
                            </td>
                            <td>
                                <strong>₹{generateOptimizationTable()
                                    .reduce((total, { category, amount }) => {
                                        const adjustedAmount = adjustedSpends[category] ?? amount;
                                        return total + (adjustedAmount || 0);
                                    }, 0)
                                    .toFixed(0)}
                                </strong>
                            </td>
                            <td>
                                <strong>₹{generateOptimizationTable()
                                    .reduce((total, { category, amount }) => {
                                        const adjustedAmount = adjustedSpends[category] ?? amount;
                                        return total + ((amount || 0) - (adjustedAmount || 0));
                                    }, 0)
                                    .toFixed(0)}
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Add Adjustments Button - Always visible */}
            <div className="adjustment-button-container">
                <button 
                    className="button-add-adjustments"
                    onClick={handleAddAdjustmentsToBudget}
                >
                    Add Adjustments to Budget
                </button>
                {updateMessage && (
                    <div className="update-message">
                        {updateMessage}
                    </div>
                )}
            </div>

            {/* Expense Tracker Table */}
            <div className="expense-tracker-table-container">
                <h3>Expense Tracker (for {globalMonthYear})</h3>
                <table className="table-expensetracker">
                    <thead>
                        <tr>
                            <th className="th-expensetracker">Date</th>
                            <th className="th-expensetracker">Expense Name</th>
                            <th className="th-expensetracker">Amount</th>
                            <th className="th-expensetracker">Expense Category</th>
                            <th className="th-expensetracker">Expense Type</th>
                            <th className="th-expensetracker">Mode of Payment</th>
                            <th className="th-expensetracker">Frequency</th>
                            <th className="th-expensetracker">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenses.map((expense, index) => (
                            <tr key={expense.id || index} className="tr-expensetracker">
                                <td className="td-expensetracker">
                                    <input
                                        type="date"
                                        value={expense.date}
                                        onChange={(e) => handleEditChange(index, 'date', e.target.value)}
                                        className="input-expensetracker"
                                    />
                                </td>
                                <td className="td-expensetracker">
                                    <input
                                        type="text"
                                        value={expense.name}
                                        onChange={(e) => handleEditChange(index, 'name', e.target.value)}
                                        placeholder="Expense Name"
                                        className="input-expensetracker"
                                    />
                                </td>
                                <td className="td-expensetracker">
                                    <input
                                        type="number"
                                        value={expense.amount}
                                        onChange={(e) => handleEditChange(index, 'amount', e.target.value)}
                                        placeholder="Amount"
                                        className="input-expensetracker"
                                        min="0"
                                        step="0.01"
                                    />
                                </td>
                                <td className="td-expensetracker">
                                    {expense.category === 'Other' && customCategories[index]?.trim() ? (
                                        <input
                                            type="text"
                                            value={customCategories[index] || ''}
                                            onChange={(e) => handleCustomCategoryChange(index, e.target.value)}
                                            placeholder="Enter custom category"
                                            className="input-expensetracker"
                                        />
                                    ) : (
                                        <select
                                            value={expense.category}
                                            onChange={(e) => handleEditChange(index, 'category', e.target.value)}
                                            className="input-expensetracker"
                                        >
                                            <option value="">Select Category</option>
                                            {ALL_CATEGORIES.map(category => (
                                                <option key={category} value={category}>{category}</option>
                                            ))}
                                            <option value="Other">Other (Custom)</option>
                                        </select>
                                    )}
                                </td>
                                <td className="td-expensetracker">
                                    <select
                                        value={expense.expenseType}
                                        onChange={(e) => handleEditChange(index, 'expenseType', e.target.value)}
                                        className="input-expensetracker"
                                    >
                                        <option value="Essentials">Essentials</option>
                                        <option value="Discretionary">Discretionary</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </td>
                                <td className="td-expensetracker">
                                    <select
                                        value={expense.modeOfPayment}
                                        onChange={(e) => handleEditChange(index, 'modeOfPayment', e.target.value)}
                                        className="input-expensetracker"
                                    >
                                        <option value="Credit Card">Credit Card</option>
                                        <option value="UPI">UPI</option>
                                        <option value="Debit Card">Debit Card</option>
                                        <option value="Wallet">Wallet</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Net Banking">Net Banking</option>
                                    </select>
                                </td>
                                <td className="td-expensetracker">
                                    <select
                                        value={expense.frequency}
                                        onChange={(e) => handleEditChange(index, 'frequency', e.target.value)}
                                        className="input-expensetracker"
                                    >
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Yearly">Yearly</option>
                                        <option value="One time">One time</option>
                                        <option value="Adhoc">Adhoc</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </td>
                                <td className="td-expensetracker">
                                    <button
                                        onClick={() => handleDelete(index)}
                                        className="delete-button-expensetracker"
                                        title="Delete"
                                    >
                                        ❌
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Buttons */}
            <div className="button-container-expensetracker">
                <button className="button-add-expense-expensetracker" onClick={addExpense}>
                    Add Expense
                </button>
                <button 
                    className="button-save-changes-expensetracker" 
                    onClick={handleSaveChanges}
                >
                    Save Changes
                </button>
                <button 
                    className="button-analyze-expenses-expensetracker" 
                    onClick={handleAnalyzeExpenses}
                >
                    Analyze
                </button>
            </div>

            {/* Analysis Section */}
            {showAnalysis && (
                <>
                    {showInsights && (
                        <div className="insights-container">
                            {Object.entries(calculateSpendBreakdown().breakdown).map(([category, data]) => {
                                const variance = data.variance;
                                const budget = data.budget;
                                const spent = data.amount;
                                let insightClass = '';
                                let message = '';

                                if (variance < 0) {
                                    // Exceeded budget
                                    insightClass = 'exceeded-budget';
                                    message = `⚠️ ${category}: Exceeded budget by ${Math.abs(variance).toFixed(2)} 
                                             (Budget: ₹${budget.toFixed(2)}, Spent: ₹${spent.toFixed(2)})`;
                                } else if (variance > 0 && spent > 0) {
                                    // Under budget with spending
                                    insightClass = 'within-budget';
                                    message = `✅ ${category}: Within budget by ₹${variance.toFixed(2)} 
                                             (Budget: ₹${budget.toFixed(2)}, Spent: ₹${spent.toFixed(2)})`;
                                } else if (budget > 0 && spent === 0) {
                                    // Has budget but no spending
                                    insightClass = 'excess-budget';
                                    message = `⚠️ ${category}: Unused budget of ₹${budget.toFixed(2)} 
                                             (No spending recorded)`;
                                }

                                return message ? (
                                    <div key={category} className={`insight-item ${insightClass}`}>
                                        {message}
                                    </div>
                                ) : null;
                            })}

                            {/* AI Insights Section with Apply button */}
                            {aiInsights && (
                                <div className="ai-insights-section">
                                    <h3>AI-Powered Savings Recommendations</h3>
                                    <div className="ai-insights-content">
                                        {aiInsights.split('\n').map((insight, index) => (
                                            insight.trim() && (
                                                <div key={index} className="ai-insight-item">
                                                    {insight}
                                                </div>
                                            )
                                        ))}
                                    </div>
                                    {/* Commenting out Apply Optimizations button
                                    <button 
                                        className="button-apply-optimizations"
                                        onClick={handleApplyOptimizations}
                                    >
                                        Apply Optimizations
                                    </button>
                                    */}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ExpenseTracker;
