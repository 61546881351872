import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import '../css/Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = () => {
    setIsMenuOpen(false);
    if (location.pathname === '/') {
      navigate('/dashboard');
    }
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="app-name">
          <Link to="/dashboard" className="logo" onClick={handleOptionClick}>
            ProsperNow
          </Link>
        </div>
        <div className="header-actions">
          <Link to="/profile" className="profile-icon">
            <FaUser />
          </Link>
          <div className="menu-container" ref={menuRef}>
            <button className="menu-button" onClick={toggleMenu}>
              <span className="menu-icon">&#9776;</span>
            </button>
            {isMenuOpen && (
              <nav className="dropdown-menu">
                <Link 
                  to="/dashboard" 
                  className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} 
                  onClick={handleOptionClick}
                >
                  Home
                </Link>
                <Link to="/login" className="nav-link" onClick={handleOptionClick}>Login</Link>
                <Link to="/spend-analyzer" className="nav-link" onClick={handleOptionClick}>Spend Analyzer</Link>
                <Link to="/emergency-fund" className="nav-link" onClick={handleOptionClick}>Emergency Fund</Link>
                <Link to="/profile" className="nav-link" onClick={handleOptionClick}>Profile</Link>
              </nav>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
