import React, { useState, useEffect } from 'react';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';

function Insurance({ onBudgetDataChange }) {
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [premiums, setPremiums] = useState({});
  const [totalPremium, setTotalPremium] = useState(0);

  useEffect(() => {
    // Load insurance data
    const insuranceData = getFromLocalStorage('insurance');
    if (insuranceData) {
      setSelectedPolicies(insuranceData.policies || []);
      setPremiums(insuranceData.premiums || {});
      setTotalPremium(insuranceData.totalPremium || 0);
    }
  }, []);

  const saveInsuranceData = (data) => {
    updateLocalStorage('insurance', data);
  };

  const handleSaveDetails = () => {
    const insuranceData = {
      policies: selectedPolicies,
      premiums: premiums,
      totalPremium: totalPremium
    };

    saveInsuranceData(insuranceData);

    // Update budget data
    const currentBudgetData = getFromLocalStorage('budget') || {};
    const updatedBudgetData = {
      ...currentBudgetData,
      'Insurance': totalPremium
    };
    updateLocalStorage('budget', updatedBudgetData);

    // Update parent component if needed
    if (onBudgetDataChange) {
      onBudgetDataChange('Insurance', totalPremium);
    }
  };

  const handlePolicyChange = (policy, isSelected) => {
    const updatedPolicies = isSelected
      ? [...selectedPolicies, policy]
      : selectedPolicies.filter(p => p !== policy);
    
    setSelectedPolicies(updatedPolicies);
    calculateTotalPremium(updatedPolicies, premiums);
  };

  const handlePremiumChange = (policy, amount) => {
    const updatedPremiums = {
      ...premiums,
      [policy]: parseFloat(amount) || 0
    };
    
    setPremiums(updatedPremiums);
    calculateTotalPremium(selectedPolicies, updatedPremiums);
  };

  const calculateTotalPremium = (policies, premiumsData) => {
    const total = policies.reduce((sum, policy) => sum + (premiumsData[policy] || 0), 0);
    setTotalPremium(total);
  };

  return (
    <div className="insurance-container">
      <h3>Insurance Planning</h3>
      {/* Add your insurance planning content here */}
      <p>Here you can plan for your insurance and investments.</p>
    </div>
  );
}

export default Insurance;