import React, { useState, useEffect, useRef } from 'react';
import OpenAI from "openai";
import '../css/Chatbot.css';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

function Chatbot({ spendData, income, categorizedSpending }) {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);

    useEffect(() => {
        const contextMessage = {
            role: 'system',
content: `You are an AI financial advisor. You have access to the following user financial data:

Total Income: $${income}

Categorized Spending:
${Object.entries(categorizedSpending).map(([category, amount]) => `${category}: $${amount.toFixed(2)}`).join('\n')}

Detailed Spending:
${spendData.map(item => `${item.category}: $${parseFloat(item.amount).toFixed(2)}`).join('\n')}

Instructions:
1. Always base your advice on the provided financial data.
2. When answering questions, first summarize the relevant part of the user's financial situation by quoting specific amounts from the data.
3. Provide personalized financial advice tailored to the user's spending patterns and income.
4. If asked about a specific category or expense, always refer to the exact figures in the data.
5. Offer practical, actionable suggestions for improving the user's financial health.
6. Be concise yet informative in your responses.
7. If a question cannot be answered using the provided data, politely say so and offer to assist with something related to the available information.

Remember, your goal is to help the user understand their financial situation and make informed decisions based on their specific data.`
        };
        setMessages([contextMessage]);
    }, [spendData, income, categorizedSpending]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    const sendMessageToChatGPT = async (message) => {
        try {
            const response = await openai.chat.completions.create({
                model: "gpt-4o",
                messages: [...messages, { role: "user", content: message }],
            });
            return response.choices[0].message.content;
        } catch (error) {
            console.error('Error calling OpenAI API:', error);
            throw error;
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!inputMessage.trim() || isLoading) return;

        const userMessage = { role: 'user', content: inputMessage };
        setMessages(prev => [...prev, userMessage]);
        setInputMessage('');
        setIsLoading(true);

        try {
            const aiResponse = await sendMessageToChatGPT(inputMessage);
            const aiMessage = { role: 'assistant', content: aiResponse };
            setMessages(prev => [...prev, aiMessage]);
        } catch (error) {
            const errorMessage = { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={`chatbot ${isOpen ? 'open' : 'closed'}`}>
            <button className="chatbot-toggle" onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? 'Close Chat' : 'Open Chat'}
            </button>
            {isOpen && (
                <div className="chatbot-content">
                    <div className="messages" ref={messagesContainerRef}>
                        {messages.slice(1).map((message, index) => (
                            <div key={index} className={`message ${message.role}`}>
                                {message.content}
                            </div>
                        ))}
                        {isLoading && <div className="message assistant loading">Thinking...</div>}
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            placeholder="Ask about your finances..."
                            disabled={isLoading}
                        />
                        <button type="submit" disabled={isLoading}>Send</button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Chatbot;
