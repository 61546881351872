import React, { useState, useEffect } from 'react';
import '../css/LongTermSavings.css';
import { getFromLocalStorage, updateLocalStorage } from '../../utils/storageUtils';

// Global variables for month-year tracking
let globalMonthYear;
let globalNextMonthYear;

const calculateMonthlyPayment = (goalAmount, currentSavings, rateOfReturn, timeframe) => {
  console.log('Input values:', {
    goalAmount,
    currentSavings,
    rateOfReturn,
    timeframe
  });

  // Convert percentage to decimal
  const rate = parseFloat(rateOfReturn) / 100;
  console.log('Rate in decimal:', rate);
  
  // Convert all inputs to numbers
  const goal = parseFloat(goalAmount);
  const current = parseFloat(currentSavings);
  const years = parseFloat(timeframe);
  
  console.log('Converted numbers:', {
    goal,
    current,
    years
  });

  try {
    // Calculate Projected Current Savings
    const projectedSavings = current * Math.pow((1 + rate), years);
    console.log('Projected Current Savings:', projectedSavings);
    
    // Calculate Net Savings Required
    const netSavingsRequired = goal - projectedSavings;
    console.log('Net Savings Required:', netSavingsRequired);
    
    // Calculate Monthly Payment Required
    const powerTerm = Math.pow(1 + rate, years);
    console.log('Power term (1 + r)^t:', powerTerm);
    
    const numerator = netSavingsRequired;
    const denominator = (powerTerm - 1) / rate;
    console.log('Denominator ((1 + r)^t - 1)/r:', denominator);
    
    const monthlyPayment = (numerator / denominator) / 12;
    console.log('Final Monthly Payment:', monthlyPayment);
    
    if (isNaN(monthlyPayment) || !isFinite(monthlyPayment)) {
      console.log('Monthly payment is NaN or Infinite');
      return 0;
    }
    
    return monthlyPayment;
  } catch (error) {
    console.error('Error in calculation:', error);
    return 0;
  }
};

const calculateMonthsRemaining = (timestamp, totalYears) => {
  if (!timestamp) return 0;
  
  const startDate = new Date(timestamp);
  const currentDate = new Date();
  const totalMonths = totalYears * 12;
  
  // Calculate months elapsed since start date
  const monthsElapsed = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + 
    (currentDate.getMonth() - startDate.getMonth());
  
  // Calculate remaining months and reduce by 1
  const monthsRemaining = totalMonths - monthsElapsed - 1;
  
  return Math.max(0, monthsRemaining);
};

function LongTermSavings({ onBudgetDataChange, monthYear, nextMonthYear }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Add useEffect to handle month-year values
  useEffect(() => {
    setError(null); // Reset error state
    // First try to get from props
    if (monthYear && nextMonthYear) {
      globalMonthYear = monthYear;
      globalNextMonthYear = nextMonthYear;
      console.log('Set from props - globalMonthYear:', globalMonthYear);
      console.log('Set from props - globalNextMonthYear:', globalNextMonthYear);
    } else {
      // If not in props, try to get from localStorage
      const storedMonthYear = localStorage.getItem('monthYear');
      const storedNextMonthYear = localStorage.getItem('nextMonthYear');
      
      if (storedMonthYear && storedNextMonthYear) {
        globalMonthYear = storedMonthYear;
        globalNextMonthYear = storedNextMonthYear;
        console.log('Set from localStorage - globalMonthYear:', globalMonthYear);
        console.log('Set from localStorage - globalNextMonthYear:', globalNextMonthYear);
      } else {
        const error = 'Could not get month-year values from props or localStorage';
        console.error('LongTermSavings -', error);
        setError(error);
      }
    }
  }, [monthYear, nextMonthYear]);

  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState({
    name: '',
    amount: '',
    timeframe: '',
    rateOfReturn: '',
    currentSavings: '',
    priority: 'Medium',
    timestamp: null
  });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [expensesProcessed, setExpensesProcessed] = useState(false);

  useEffect(() => {
    const loadGoals = async () => {
      if (!globalMonthYear) {
        console.log('Waiting for globalMonthYear to be set...');
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        // Load existing goals first - these contain all the correct original values
        const prevMonthGoals = getFromLocalStorage('longTermSavings', 'goals', globalMonthYear) || [];
        console.log('Loaded previous goals:', prevMonthGoals);
        
        // Only process expenses if goals haven't been processed yet
        if (!expensesProcessed) {
          const expenses = getFromLocalStorage('spendAnalyzer', 'expenses', globalMonthYear) || [];
          const longTermExpenses = expenses.filter(expense => expense.categoryMapping === 'Long-term Savings');
          console.log('Long term expenses:', longTermExpenses);

          // Only update currentSavings for existing goals - no new goal creation
          const updatedGoals = prevMonthGoals.map(goal => {
            const matchingExpense = longTermExpenses.find(expense => expense.category === goal.name);
            if (matchingExpense) {
              console.log('Found matching expense for goal:', goal.name, matchingExpense);
              return {
                ...goal,
                currentSavings: (parseFloat(goal.currentSavings || 0) + parseFloat(matchingExpense.amount || 0)).toFixed(0)
              };
            }
            return goal;
          });

          console.log('Updated goals after processing expenses:', updatedGoals);
          setGoals(updatedGoals);
          updateLocalStorage('longTermSavings', { goals: updatedGoals }, globalNextMonthYear);
          setExpensesProcessed(true);
        } else {
          setGoals(prevMonthGoals);
        }
      } catch (error) {
        console.error('Error loading goals:', error);
        setError('Failed to load goals. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    loadGoals();
  }, [globalMonthYear, expensesProcessed]);

  // Save goals whenever they change
  useEffect(() => {
    if (goals.length > 0 && globalMonthYear) {
      updateLocalStorage('longTermSavings', { goals }, globalNextMonthYear);
      console.log('Saved goals for month:', globalMonthYear, goals);
    }
  }, [goals, globalMonthYear]);

  useEffect(() => {
    // Check if month has changed at midnight of each day
    const checkMonthChange = () => {
      const now = new Date();
      // Set timeout to next midnight
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      
      const timeUntilMidnight = tomorrow - now;
      
      setTimeout(() => {
        // Check if month has changed
        const currentMonth = now.getMonth();
        const newMonth = new Date().getMonth();
        
        if (currentMonth !== newMonth) {
          // Force re-render only when month changes
          setUpdateTrigger(prev => prev + 1);
        }
        
        // Set up next check
        checkMonthChange();
      }, timeUntilMidnight);
    };

    // Start the check
    checkMonthChange();

    // Cleanup
    return () => {
      // No need to cleanup setTimeout as it will be cleared when component unmounts
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoal(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submission values:', newGoal);
    
    if (newGoal.name && newGoal.amount && newGoal.timeframe) {
      const monthlyPayment = calculateMonthlyPayment(
        newGoal.amount,
        newGoal.currentSavings,
        newGoal.rateOfReturn,
        newGoal.timeframe
      );

      console.log('Calculated monthly payment:', monthlyPayment);

      const goalWithMonthlyPayment = {
        ...newGoal,
        monthlyPayment,
        timestamp: new Date().toISOString()
      };

      const updatedGoals = [...goals, goalWithMonthlyPayment];
      setGoals(updatedGoals);
      updateLocalStorage('longTermSavings', { goals: updatedGoals }, globalNextMonthYear);
      
      // Reset form
      setNewGoal({
        name: '',
        amount: '',
        timeframe: '',
        rateOfReturn: '',
        currentSavings: '',
        priority: 'Medium',
        timestamp: null
      });
    }
  };

  const handleDelete = (index) => {
    const updatedGoals = goals.filter((_, i) => i !== index);
    setGoals(updatedGoals);
    updateLocalStorage('longTermSavings', { goals: updatedGoals }, globalNextMonthYear);
    
    // Update budget data
    const totalAmount = updatedGoals.reduce((sum, goal) => sum + Number(goal.amount), 0);
    if (onBudgetDataChange) {
      onBudgetDataChange('Long-term Savings', totalAmount);
    }
  };

  const handleSaveToBudget = () => {
    const totalMonthlyPayment = goals.reduce((sum, goal) => sum + Number(goal.monthlyPayment), 0);
    
    if (onBudgetDataChange) {
      onBudgetDataChange('Long-term Savings', totalMonthlyPayment);
      setSaveSuccess(true);
      
      // Reset success message after 3 seconds
      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    }
  };

  // Add error and loading states to the render
  if (error) {
    return (
      <div className="error-container" style={{ 
        padding: '20px', 
        textAlign: 'center',
        color: '#721c24',
        backgroundColor: '#f8d7da',
        border: '1px solid #f5c6cb',
        borderRadius: '4px',
        margin: '20px'
      }}>
        <h2>Error Loading Long Term Savings</h2>
        <p>{error}</p>
        <button 
          onClick={() => window.location.reload()} 
          style={{
            padding: '10px 20px',
            marginTop: '10px',
            backgroundColor: '#dc3545',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Retry
        </button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="loading-container" style={{ 
        padding: '20px', 
        textAlign: 'center',
        color: '#004085',
        backgroundColor: '#cce5ff',
        border: '1px solid #b8daff',
        borderRadius: '4px',
        margin: '20px'
      }}>
        <h2>Loading Long Term Savings...</h2>
        <p>Please wait while we fetch your data.</p>
      </div>
    );
  }

  return (
    <div className="long-term-savings">
      <h2>Long Term Savings Goals</h2>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Goal Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newGoal.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="amount">Goal Amount:</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={newGoal.amount}
              onChange={handleInputChange}
              min="0"
              step="100"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="timeframe">Timeframe (in years):</label>
            <input
              type="number"
              id="timeframe"
              name="timeframe"
              value={newGoal.timeframe}
              onChange={handleInputChange}
              min="1"
              step="1"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="rateOfReturn">Rate of Return on Investments (%):</label>
            <input
              type="number"
              id="rateOfReturn"
              name="rateOfReturn"
              value={newGoal.rateOfReturn}
              onChange={handleInputChange}
              min="0"
              max="100"
              step="0.1"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="currentSavings">Current Savings:</label>
            <input
              type="number"
              id="currentSavings"
              name="currentSavings"
              value={newGoal.currentSavings}
              onChange={handleInputChange}
              min="0"
              step="100"
              required
            />
          </div>

          <button type="submit" className="submit-button">Add Goal</button>
        </form>
      </div>

      <div className="long-term-savings-table">
        <h3>Your Long Term Goals (as of {globalNextMonthYear})</h3>
        <table>
          <thead>
            <tr>
              <th>Goal Name</th>
              <th>Target Amount</th>
              <th>Months Remaining</th>
              <th>Rate of Return</th>
              <th>Current Savings</th>
              <th>Monthly Payment</th>
              <th>Added On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {goals.map((goal, index) => (
              <tr key={index}>
                <td>{goal.name}</td>
                <td>₹{Number(goal.amount).toFixed(0).toLocaleString()}</td>
                <td>{calculateMonthsRemaining(goal.timestamp, goal.timeframe)} months</td>
                <td>{goal.rateOfReturn}%</td>
                <td>₹{Number(goal.currentSavings).toFixed(0).toLocaleString()}</td>
                <td>₹{Number(goal.monthlyPayment).toFixed(0).toLocaleString()}</td>
                <td>{new Date(goal.timestamp).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => handleDelete(index)} className="delete-button">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="save-details-container">
          <button 
            onClick={handleSaveToBudget}
            className="save-details-button"
          >
            Save Details
          </button>
          {saveSuccess && <span className="save-success">Saved successfully!</span>}
        </div>
      </div>
    </div>
  );
}

export default LongTermSavings;
